import { ActivityParentEntityId } from "@sp-crm/core";
import { ActivityFeed } from "components/activity-feed/activity-feed";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentSection2Up } from "components/layout";
import { ActivityType, useGetActivityFeedQuery } from "generated/graphql";
import React from "react";
import { EntityActivityClientStatus } from "./entity-activity-client-status";
import { EntityActivityEntityCreate } from "./entity-activity-entity";
import { EntityActivityEntryFileUpload } from "./entity-activity-file";
import { EntityActivityMessageSent } from "./entity-activity-message";
import {
    EntityActivityEntryNoteAdd,
    EntityActivityEntryNoteDelete,
} from "./entity-activity-note";
import {
    EntityActivityOrganizationMemberAdded,
    EntityActivityOrganizationMemberRemoved,
} from "./entity-activity-organization";
import {
    EntityActivityTaskComplete,
    EntityActivityTaskCreate,
    EntityActivityTaskDelete,
    EntityActivityTaskReassign,
    EntityActivityTaskUncomplete,
    EntityActivityTaskUpdate,
} from "./entity-activity-task";
import { ActivityEntityType, EntityActivityEntry } from "./types";

interface ActivityProps {
    entityId: ActivityParentEntityId;
    entityType: ActivityEntityType;
}

export const Activity: React.FC<ActivityProps> = props => {
    const { entityId, entityType } = props;
    const query = useGetActivityFeedQuery({ entityId });
    return (
        <ContentSection2Up>
            <div className="flex-1">
                <ContentFull>
                    <QueryRenderer name="EntityActivity" query={query}>
                        {data => (
                            <ActivityFeed>
                                {data.getActivityFeed.activities.map(e =>
                                    entityActivityEntry(entityId, entityType, e),
                                )}
                            </ActivityFeed>
                        )}
                    </QueryRenderer>
                </ContentFull>
            </div>
            <div className="flex-1"></div>
        </ContentSection2Up>
    );
};

const entityActivityEntry = (
    entityId: ActivityParentEntityId,
    entityType: ActivityEntityType,
    activityFeedEvent: EntityActivityEntry,
): JSX.Element | null => {
    const activityType = activityFeedEvent.activity.activityType;
    if (activityType === ActivityType.NoteCreate) {
        return (
            <EntityActivityEntryNoteAdd
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.NoteUpdate) {
        return null;
    }
    if (activityType === ActivityType.NoteDelete) {
        return (
            <EntityActivityEntryNoteDelete
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.TaskCreate) {
        return (
            <EntityActivityTaskCreate
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.EntityFieldChange) {
        if (
            entityType === "Client" &&
            activityFeedEvent.activity.metadata?.events?.some(
                e =>
                    e.entityFieldChange?.field === "status" ||
                    e.entityFieldChange?.field === "statusListItemId",
            )
        ) {
            return (
                <EntityActivityClientStatus
                    key={activityFeedEvent.activity.id}
                    entityId={entityId}
                    activity={activityFeedEvent}
                />
            );
        }
        return null;
    }
    if (activityType === ActivityType.EntityCreate) {
        return (
            <EntityActivityEntityCreate
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                entityType={entityType}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.TaskUpdate) {
        return (
            <EntityActivityTaskUpdate
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.TaskDelete) {
        return (
            <EntityActivityTaskDelete
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.TaskComplete) {
        return (
            <EntityActivityTaskComplete
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.TaskUncomplete) {
        return (
            <EntityActivityTaskUncomplete
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.TaskReassign) {
        return (
            <EntityActivityTaskReassign
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.FileUpload) {
        return (
            <EntityActivityEntryFileUpload
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }
    if (activityType === ActivityType.MessageSent) {
        return (
            <EntityActivityMessageSent
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }

    if (activityType === ActivityType.OrganizationMemberAdded) {
        return (
            <EntityActivityOrganizationMemberAdded
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }

    if (activityType === ActivityType.OrganizationMemberRemoved) {
        return (
            <EntityActivityOrganizationMemberRemoved
                key={activityFeedEvent.activity.id}
                entityId={entityId}
                activity={activityFeedEvent}
            />
        );
    }

    const exhaustiveCheck: never = activityType;
    throw new Error(`Unhandled activity type: ${exhaustiveCheck}`);
};
