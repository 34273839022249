import { InternalId } from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import { Alert } from "components/ui/dialog/alert";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import {
    makeRequestFailAcknowledgeAction,
    makeRequestUnauthorizedAcknowledgeAction,
} from "../../store/actions/types";
import { RequestDescription } from "../../types/request-description";
import { GlobalSuccess } from "./global-success";

interface AlertsPropsFromState {
    failures: RequestDescription[];
    unauthorized: RequestDescription[];
}

interface AlertsPropsFromDispatch {
    clearAlert(eventId: InternalId): void;
}

type AlertsProps = AlertsPropsFromState & AlertsPropsFromDispatch;

class Alerts extends React.Component<AlertsProps, undefined> {
    renderAlertForFailure(f: RequestDescription): JSX.Element {
        const subject = f.correlationId || "UnknownError";
        return (
            <Alert
                title="Sorry, something went wrong"
                onDismiss={() => this.props.clearAlert(f.requestId)}>
                <div>
                    <p>We&apos;re sorry, something went wrong.</p>
                    <br />
                    <p>
                        If you were in the middle of updating data, please try entering it
                        again. If you see this error again, please contact us using the
                        information below.
                    </p>
                    <br />
                    <p>
                        Email <SupportEmailLink subject={`Error report: ${subject}`} />,
                        and please include the information below:
                    </p>
                    <br />
                    <div className="space-y-1">
                        <div className="font-bold">Diagnostic</div>
                        <div>
                            <code>{f.requestId}</code>
                        </div>
                        <div>
                            <code>{f.correlationId}</code>
                        </div>
                    </div>
                </div>
            </Alert>
        );
    }

    renderAlertForUnauthorized(f: RequestDescription): JSX.Element {
        const subject = f.correlationId || "UnknownError";
        return (
            <Alert
                onDismiss={() => this.props.clearAlert(f.requestId)}
                title="Sorry, access denied">
                <div>
                    <p>
                        We&apos;re sorry, doesn&apos;t look like you have access to that.
                    </p>
                    <br />
                    <p>
                        If you believe this to be in error, please contact us using the
                        information below.
                    </p>
                    <br />
                    <p>
                        Email <SupportEmailLink subject={`Error report: ${subject}`} />,
                        and please include the information below:
                    </p>
                    <br />
                    <div className="space-y-1">
                        <div className="font-bold">Diagnostic</div>
                        <div>
                            <code>{f.requestId}</code>
                        </div>
                        <div>
                            <code>{f.correlationId}</code>
                        </div>
                    </div>
                </div>
            </Alert>
        );
    }

    render() {
        if (this.props.failures.length !== 0) {
            return this.renderAlertForFailure(this.props.failures[0]);
        }
        if (this.props.unauthorized.length !== 0) {
            return this.renderAlertForUnauthorized(this.props.unauthorized[0]);
        }
        return <GlobalSuccess />;
    }
}

function mapStateToProps(state: ApplicationState): AlertsPropsFromState {
    return {
        failures: Object.values(state.globalNotifications.faildRequests),
        unauthorized: Object.values(state.globalNotifications.unauthorizedRequests),
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): AlertsPropsFromDispatch {
    return {
        clearAlert: (eventId: InternalId) => {
            dispatch(makeRequestFailAcknowledgeAction(eventId));
            dispatch(makeRequestUnauthorizedAcknowledgeAction(eventId));
        },
    };
}

const component = connect(mapStateToProps, mapDispatchToProps)(Alerts);
export { component as Alerts };
