import { ISearchPrice } from "components/community-search/search-bar/util";
import { Input } from "components/ui/input";
import * as React from "react";

interface SliderSegmentProps {
    min: number;
    max: number;
    values: ISearchPrice;
    onChange: (params: [number, number]) => void;
}

interface SliderSegmentState {
    values: [number, number];
}

export class SliderSegment extends React.Component<
    SliderSegmentProps,
    SliderSegmentState
> {
    constructor(props: SliderSegmentProps) {
        super(props);
        this.state = {
            values: this.props.values
                ? [this.props.values.min, this.props.values.max]
                : [this.props.min, this.props.max],
        };
    }

    onValuesUpdated({ values }: { values: [number, number] }) {
        const newState = { ...this.state, values };
        this.setState(newState);
        this.props.onChange(newState.values);
    }

    render(): JSX.Element {
        return (
            <div className="flex space-x-2">
                <Input
                    label="At least"
                    type="number"
                    value={this.state.values[0]}
                    onChange={e =>
                        this.onValuesUpdated({
                            values: [parseInt(e.target.value), this.state.values[1]],
                        })
                    }
                />
                <Input
                    label="At most"
                    type="number"
                    value={this.state.values[1]}
                    onChange={e =>
                        this.onValuesUpdated({
                            values: [this.state.values[0], parseInt(e.target.value)],
                        })
                    }
                />
            </div>
        );
    }
}
