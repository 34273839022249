import { Checkbox } from "components/ui/checkbox";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { ApplicationState } from "store/state";
import { login, navigate } from "../../store/actions";
import { Col, Row } from "../shared/grid";

interface LoginPropsFromDispatch {
    // eslint-disable-next-line @typescript-eslint/ban-types -- eslintintroduction
    onSubmit: Function;
    navigate: (path: string) => void;
}

interface LoginPropsFromState {
    inProgress: boolean;
    isFailed: boolean;
}

interface LoginState {
    email: string;
    password: string;
    longTermLogin: boolean;
}

type LoginProps = LoginPropsFromState & LoginPropsFromDispatch;

class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = { email: "", password: "", longTermLogin: false };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    handleSubmit(e: any) {
        if (e) {
            e.preventDefault();
        }
        this.props.onSubmit(this.state);
    }

    changeEmail(email: string): void {
        const newState: LoginState = { ...this.state, email: email };
        this.setState(newState);
    }

    changePassword(password: string): void {
        const newState: LoginState = { ...this.state, password: password };
        this.setState(newState);
    }

    onKeepLoggedInCheckbox(newVal: boolean): void {
        const newState: LoginState = { ...this.state, longTermLogin: newVal };
        this.setState(newState);
    }

    signInError(): JSX.Element {
        if (this.props.isFailed) {
            return (
                <InlineBanner type="error">Sorry! Wrong email or password.</InlineBanner>
            );
        }
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    resetPassword(e: any) {
        e.preventDefault();
        this.props.navigate("/account/reset-password");
    }

    shouldUseAnotherBrowser(): boolean {
        const userAgent = ((navigator && navigator.userAgent) || "").toLowerCase();
        const vendor = ((navigator && navigator.vendor) || "").toLowerCase();
        const isFirefox = /(?:firefox|fxios)\/(\d+)/.test(userAgent);
        const isChrome = /google inc/.test(vendor)
            ? /(?:chrome|crios)\/(\d+)/.test(userAgent)
            : false;
        const isMobile =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                userAgent,
            );
        const supportsTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;

        return !isMobile && !isFirefox && !isChrome && !supportsTouch;
    }

    browserGreenlight(): JSX.Element {
        if (this.shouldUseAnotherBrowser()) {
            return (
                <Row>
                    <Col>
                        <div className="greenlight__wrapper">
                            <h3 className="greenlight__header">
                                We recommend a different browser
                            </h3>
                            <p className="greenlight__text">
                                For the best experience, we strongly recommend you use
                                either Google Chrome or Mozilla Firefox.
                            </p>
                            <p className="greenlight__text">
                                To download Google Chrome:{" "}
                                <a
                                    href="https://www.google.com/chrome/browser"
                                    target="_blank"
                                    rel="noreferrer">
                                    {"https://www.google.com/chrome/browser"}
                                </a>
                            </p>
                            <p className="greenlight__text">
                                To download Mozilla Firefox:{" "}
                                <a
                                    href="https://www.mozilla.org/download"
                                    target="_blank"
                                    rel="noreferrer">
                                    {"https://www.mozilla.org/download"}
                                </a>
                            </p>
                            <p className="greenlight__text">
                                If you would like to proceed with your current browser you
                                can still login below.
                            </p>
                        </div>
                    </Col>
                </Row>
            );
        }
        return null;
    }

    render() {
        return (
            <div>
                <div className="page-header row">
                    <div className="page-title col">
                        <h2>Sign in</h2>
                    </div>
                    <div className="toolbar col" />
                </div>
                <div className="row main-body">
                    <div className="login-control">
                        {this.browserGreenlight()}
                        <Row>
                            <Col>{this.signInError()}</Col>
                        </Row>
                        <form onSubmit={e => this.handleSubmit(e)}>
                            <div className="space-y-2">
                                <Input
                                    type="email"
                                    id="email"
                                    value={this.state.email}
                                    onChange={e => this.changeEmail(e.target.value)}
                                    label="Email address"
                                />
                                <Input
                                    type="password"
                                    id="password"
                                    value={this.state.password}
                                    onChange={e => this.changePassword(e.target.value)}
                                    label="Password"
                                />
                                <Checkbox
                                    checked={this.state.longTermLogin}
                                    onChange={v =>
                                        this.onKeepLoggedInCheckbox(v.target.checked)
                                    }
                                    label="Keep me logged in on this computer"
                                />
                                <div className="flex-row flex-space-between">
                                    <PrimaryButton
                                        id="signin"
                                        onClick={() => this.handleSubmit(null)}>
                                        Sign in
                                    </PrimaryButton>
                                    <a href="#" onClick={this.resetPassword.bind(this)}>
                                        Forgot your password?
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): LoginPropsFromState {
    return {
        inProgress: state.login.loginInProgress,
        isFailed: state.login.loginFailure,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): LoginPropsFromDispatch {
    return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        onSubmit: (creds: any) => {
            login(creds, dispatch);
        },
        navigate: (path: string) => navigate(path),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
