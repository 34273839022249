import { CheckIcon } from "@heroicons/react/20/solid";
import {
    ActionType,
    defaultSignatureEmailBody,
    defaultSignatureEmailSubject,
    FileId,
    genEntityId,
    SignatureTemplateFieldId,
    SignatureTemplateId,
} from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentSection2Up, SectionHeader, Stage } from "components/layout";
import { ReadOnlyAttachments } from "components/manage/templates/read-only-attachments";
import { DeleteButton } from "components/ui/action-button";
import { fancyAlert } from "components/ui/fancy-alert";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import {
    AdvancedSearchEntityType,
    GetSignatureTemplateQuery,
    SignatureCreatePermission,
    SignatureFieldType,
    SignatureTemplateFieldInput,
    SignatureTemplateInput,
    useCanCreateSignatureTemplateQuery,
    useDeleteSignatureTemplateMutation,
    useGetSignatureTemplateQuery,
    useGetTemplateQuery,
    useSetSignatureTemplateMutation,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useEffect } from "react";
import { pdfjs } from "react-pdf";
import { DocumentCallback, PageCallback } from "react-pdf/dist/cjs/shared/types";
import { Rnd } from "react-rnd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useIsAllowed, useTenantSettings } from "store/selectors/hooks";
import { getSanitizedMarkUpFromEmailBody } from "util/email";
import {
    DocumentPdfInfo,
    DocumentRender,
    PagePicker,
    PdfDocumentProxyMetadata,
} from "../pdf/document-render";
import { getFontForFieldType } from "../signature-defaults";
import {
    getSelectedSignatureBorderStyle,
    getSignatureBackgroundStyle,
    getSignatureBorderStyle,
    getSignatureLinkMetadataStyle,
    getSignatureLinkStyle,
    getSignaturePlaceholderStyle,
    getSignatureRingStyle,
    getSignatureSelectedFieldBackgroundStyle,
} from "./signature-colors";
import { SignatureCustomizeEmailDialog } from "./signature-customize-email-dialog";
import { SignatureFieldIcon } from "./signature-field-icon";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

type SaveAllowedStatus =
    | "unknown"
    | "allowed"
    | "notAllowedTooMany"
    | "notAllowedPermissions";

interface State {
    signatureTemplate: SignatureTemplateInput;
    numPages: number | null;
    pageNumber: number | null;
    currentPageDimensions: number[] | null;
    selectedTemplateFieldId: SignatureTemplateFieldId | null;
    isSaving: boolean;
    finishedInitialLoad: boolean;
    existingSignatureTemplateId: SignatureTemplateId | null;
    saveAllowed: SaveAllowedStatus;
    defaultEmailContent: {
        subject: string;
        body: string;
        attachments: FileId[];
    } | null;
    showCustomizeEmailPanel: boolean;
    pdfInfo: DocumentPdfInfo | null;
}

const initialState: State = {
    signatureTemplate: {
        signatureTemplateFields: [],
        recipientCount: 1,
        emailTemplate: null,
    },
    numPages: null,
    pageNumber: 1,
    currentPageDimensions: null,
    selectedTemplateFieldId: null,
    isSaving: false,
    finishedInitialLoad: false,
    existingSignatureTemplateId: null,
    saveAllowed: "unknown",
    defaultEmailContent: null,
    showCustomizeEmailPanel: false,
    pdfInfo: null,
};

type Action =
    | { type: "addSignatureTemplateField" }
    | {
          type: "onDocumentLoadSuccess";
          document: DocumentCallback;
          documentMetadata: PdfDocumentProxyMetadata;
      }
    | { type: "onPageLoadSuccess"; document: PageCallback }
    | { type: "updateField"; field: SignatureTemplateFieldInput }
    | { type: "selectField"; fieldId: SignatureTemplateFieldId }
    | { type: "insertField"; recipient: number }
    | { type: "addRecipient" }
    | { type: "setPage"; page: number }
    | { type: "removeRecipient"; index: number }
    | { type: "removeField"; fieldId: SignatureTemplateFieldId }
    | { type: "saveStart" }
    | { type: "saveEnd" }
    | {
          type: "initialLoadComplete";
          payload: GetSignatureTemplateQuery["getSignatureTemplate"];
      }
    | { type: "limitCheckComplete"; canCreate: SignatureCreatePermission }
    | {
          type: "incrementFieldPosition";
          fieldId: SignatureTemplateFieldId;
          x: number;
          y: number;
      }
    | {
          type: "updateDefaultEmailContent";
          payload: {
              subject: string;
              body: string;
              attachments: FileId[];
          };
      }
    | { type: "showCustomizeEmailPanel" }
    | { type: "hideCustomizeEmailPanel" }
    | {
          type: "updateTemplateEmailContent";
          payload: {
              subject?: string | null;
              body?: string | null;
          } | null;
      };

const filledArray = (length: number): number[] => Array.from({ length }, (_, i) => i + 1);

const getFieldToClone = (
    state: State,
    pageNumber: number,
    recipient: number,
    fieldType: SignatureFieldType,
): SignatureTemplateFieldInput | null => {
    const selectedField = state.signatureTemplate.signatureTemplateFields.find(
        f => f.id === state.selectedTemplateFieldId,
    );

    if (selectedField) {
        return selectedField;
    }

    const fieldsOnPage = state.signatureTemplate.signatureTemplateFields.filter(
        f => f.page === pageNumber,
    );

    if (fieldsOnPage.length > 0) {
        const sameTypeSameRecipient = fieldsOnPage.filter(
            f => f.fieldType === fieldType && f.recipientIndex === recipient,
        );

        if (sameTypeSameRecipient.length > 0) {
            return sameTypeSameRecipient[sameTypeSameRecipient.length - 1];
        }

        const sameRecipientDifferentType = fieldsOnPage.filter(
            f => f.recipientIndex === recipient,
        );

        if (sameRecipientDifferentType.length > 0) {
            return sameRecipientDifferentType[sameRecipientDifferentType.length - 1];
        }

        const sameTypeDifferentRecipient = fieldsOnPage.filter(
            f => f.fieldType === fieldType,
        );

        if (sameTypeDifferentRecipient.length > 0) {
            return sameTypeDifferentRecipient[sameTypeDifferentRecipient.length - 1];
        }

        return fieldsOnPage[fieldsOnPage.length - 1];
    }

    const fieldsSameRecipient = state.signatureTemplate.signatureTemplateFields.filter(
        f => f.recipientIndex === recipient,
    );

    if (fieldsSameRecipient.length > 0) {
        const sameType = fieldsSameRecipient.filter(f => f.fieldType === fieldType);

        if (sameType.length > 0) {
            return sameType[sameType.length - 1];
        }

        return fieldsSameRecipient[fieldsSameRecipient.length - 1];
    }

    if (state.signatureTemplate.signatureTemplateFields.length > 0) {
        return state.signatureTemplate.signatureTemplateFields[
            state.signatureTemplate.signatureTemplateFields.length - 1
        ];
    }

    return null;
};

const reducer = (state: State, action: Action): State => {
    return produce(state, draft => {
        if (action.type === "setPage") {
            draft.pageNumber = action.page;
        }

        if (action.type === "onDocumentLoadSuccess") {
            draft.numPages = action.document.numPages;
            draft.pageNumber = 1;
            draft.pdfInfo = action.documentMetadata.info;
        }

        if (action.type === "updateField") {
            const fieldIndex = draft.signatureTemplate.signatureTemplateFields.findIndex(
                f => f.id === action.field.id,
            );
            if (fieldIndex === -1) {
                return;
            }
            draft.signatureTemplate.signatureTemplateFields[fieldIndex] = action.field;
            draft.selectedTemplateFieldId = action.field.id;
        }

        if (action.type === "insertField") {
            const pageNumber = draft.pageNumber ? draft.pageNumber : 1;
            const fieldType =
                action.recipient === 0
                    ? SignatureFieldType.Text
                    : SignatureFieldType.Signature;
            const font = getFontForFieldType(fieldType);

            const fieldToClone = getFieldToClone(
                draft,
                pageNumber,
                action.recipient,
                fieldType,
            );

            const x = fieldToClone?.x ?? 100;
            const width = fieldToClone?.width ?? 200;
            const height = fieldToClone?.height ?? 20;
            const startingY = fieldToClone?.y ?? 100;
            const y = startingY + height + 20;
            const fontSize = fieldToClone?.fontSize ?? 20;

            const newField: SignatureTemplateFieldInput = {
                id: genEntityId<SignatureTemplateFieldId>(),
                x,
                y,
                width,
                height,
                page: pageNumber,
                defaultValue: "",
                font,
                order:
                    Math.max(
                        ...draft.signatureTemplate.signatureTemplateFields.map(
                            f => f.order,
                        ),
                        0,
                    ) + 1,
                fontSize,
                recipientIndex: action.recipient,
                fieldType,
            };
            draft.signatureTemplate.signatureTemplateFields.push(newField);
            draft.selectedTemplateFieldId = newField.id;
        }

        if (action.type === "selectField") {
            draft.selectedTemplateFieldId = action.fieldId;
            const existingField = draft.signatureTemplate.signatureTemplateFields.find(
                f => f.id === action.fieldId,
            );
            if (existingField) {
                draft.pageNumber = existingField.page;
            }
        }

        if (action.type === "addRecipient") {
            draft.signatureTemplate.recipientCount =
                draft.signatureTemplate.recipientCount + 1;
        }

        if (action.type === "removeRecipient") {
            draft.signatureTemplate.recipientCount =
                draft.signatureTemplate.recipientCount - 1;
            draft.signatureTemplate.signatureTemplateFields =
                draft.signatureTemplate.signatureTemplateFields.filter(
                    f => f.recipientIndex !== action.index,
                );
            draft.signatureTemplate.signatureTemplateFields =
                draft.signatureTemplate.signatureTemplateFields.map(f => {
                    if (f.recipientIndex > action.index) {
                        return {
                            ...f,
                            recipientIndex:
                                f.recipientIndex > action.index
                                    ? f.recipientIndex - 1
                                    : f.recipientIndex,
                        };
                    }
                    return f;
                });
        }

        if (action.type === "removeField") {
            draft.signatureTemplate.signatureTemplateFields =
                draft.signatureTemplate.signatureTemplateFields.filter(
                    f => f.id !== action.fieldId,
                );
            if (draft.selectedTemplateFieldId === action.fieldId) {
                draft.selectedTemplateFieldId = null;
            }
        }

        if (action.type === "saveStart") {
            draft.isSaving = true;
        }

        if (action.type === "saveEnd") {
            draft.isSaving = false;
        }

        if (
            action.type === "initialLoadComplete" &&
            !draft.finishedInitialLoad &&
            action.payload
        ) {
            const { id, ...rest } = action.payload;
            draft.signatureTemplate = rest;
            draft.finishedInitialLoad = true;
            if (id) {
                draft.existingSignatureTemplateId = id;
            }
        }

        if (action.type === "limitCheckComplete") {
            if (action.canCreate === SignatureCreatePermission.TooMany) {
                draft.saveAllowed = "notAllowedTooMany";
            } else if (action.canCreate === SignatureCreatePermission.NotAllowed) {
                draft.saveAllowed = "notAllowedPermissions";
            } else if (action.canCreate === SignatureCreatePermission.Allowed) {
                draft.saveAllowed = "allowed";
            } else {
                const _exhaustiveCheck: never = action.canCreate;
                throw new Error(`Unhandled permission type ${_exhaustiveCheck}`);
            }
        }

        if (action.type === "incrementFieldPosition") {
            const field = draft.signatureTemplate.signatureTemplateFields.find(
                f => f.id === action.fieldId,
            );
            if (field) {
                field.x += action.x;
                field.y += action.y;
            }
        }

        if (action.type === "updateDefaultEmailContent") {
            const newDefaultEmailContent = {
                subject: action.payload.subject || defaultSignatureEmailSubject,
                body: action.payload.body || defaultSignatureEmailBody,
                attachments: action.payload.attachments || [],
            };
            draft.defaultEmailContent = newDefaultEmailContent;
        }

        if (action.type === "showCustomizeEmailPanel") {
            draft.showCustomizeEmailPanel = true;
        }

        if (action.type === "hideCustomizeEmailPanel") {
            draft.showCustomizeEmailPanel = false;
        }

        if (action.type === "updateTemplateEmailContent") {
            draft.signatureTemplate.emailTemplate = action.payload;
        }
    });
};

export const SignatureTemplate: React.FC<unknown> = () => {
    const { fileId } = useRouteMatch().params as {
        fileId: FileId;
    };
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const getSignature = useGetSignatureTemplateQuery(
        { fileId },
        {
            onSuccess: data =>
                dispatch({
                    type: "initialLoadComplete",
                    payload: data.getSignatureTemplate,
                }),
        },
    );
    useCanCreateSignatureTemplateQuery(
        {},
        {
            onSuccess: data => {
                dispatch({
                    type: "limitCheckComplete",
                    canCreate: data.canCreateSignatureTemplate,
                });
            },
        },
    );
    const tenantSettings = useTenantSettings();
    useGetTemplateQuery(
        {
            id: tenantSettings.signatureRequestInitialTemplateId,
        },
        {
            enabled: !!tenantSettings.signatureRequestInitialTemplateId,
            onSuccess: data => {
                if (data.getTemplate) {
                    dispatch({
                        type: "updateDefaultEmailContent",
                        payload: {
                            subject: data.getTemplate.template.subject,
                            body: data.getTemplate.template.body,
                            attachments: data.getTemplate.template.attachments,
                        },
                    });
                }
            },
        },
    );

    useEffect(() => {
        if (!tenantSettings.signatureRequestInitialTemplateId) {
            dispatch({
                type: "updateDefaultEmailContent",
                payload: {
                    subject: defaultSignatureEmailSubject,
                    body: defaultSignatureEmailBody,
                    attachments: [],
                },
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const saveMutation = useSetSignatureTemplateMutation();
    const deleteMutation = useDeleteSignatureTemplateMutation();
    const history = useHistory();
    const canDelete =
        useIsAllowed(ActionType.DeleteSignatureTemplate) &&
        state.existingSignatureTemplateId;

    const onLoadSuccess = React.useCallback(async (document: DocumentCallback) => {
        const documentMetadata = await document.getMetadata();
        dispatch({ type: "onDocumentLoadSuccess", document, documentMetadata });
    }, []);
    const onPageRender = React.useCallback((document: PageCallback) => {
        dispatch({ type: "onPageLoadSuccess", document });
    }, []);
    const setPage = React.useCallback((pageNumber: number) => {
        dispatch({ type: "setPage", page: pageNumber });
    }, []);
    const selectField = (fieldId: SignatureTemplateFieldId) =>
        dispatch({
            type: "selectField",
            fieldId,
        });

    const addRecipient = React.useCallback(() => {
        dispatch({ type: "addRecipient" });
    }, []);
    const insertField = React.useCallback((recipient: number) => {
        dispatch({ type: "insertField", recipient });
    }, []);
    const removeField = React.useCallback((fieldId: SignatureTemplateFieldId) => {
        dispatch({ type: "removeField", fieldId });
    }, []);
    const incrementFieldPosition = React.useCallback(
        (fieldId: SignatureTemplateFieldId, changes: { x: number; y: number }) => {
            dispatch({
                type: "incrementFieldPosition",
                fieldId,
                x: changes.x,
                y: changes.y,
            });
        },
        [],
    );

    const save = React.useCallback(async () => {
        const recipientIndicesWithoutFields = filledArray(
            state.signatureTemplate.recipientCount,
        ).filter(
            i =>
                !state.signatureTemplate.signatureTemplateFields.some(
                    f => f.recipientIndex === i,
                ),
        );

        if (recipientIndicesWithoutFields.length > 0) {
            const confirm = await fancyConfirm(
                "Recipients without fields",
                `The recipients listed below have no fields defined. Proceed with saving?\n\n${recipientIndicesWithoutFields
                    .map(i => `Recipient ${i}`)
                    .join("\n")}`,
                "Yes, save",
                "No, cancel",
            );

            if (!confirm) {
                return;
            }
        }

        dispatch({ type: "saveStart" });
        await saveMutation.mutateAsync({
            fileId,
            signatureTemplate: state.signatureTemplate,
        });
        history.goBack();
    }, [saveMutation, state.signatureTemplate, fileId, history]);

    const doDelete = React.useCallback(async () => {
        if (!canDelete) return;
        if (!state.existingSignatureTemplateId) return;
        dispatch({ type: "saveStart" });
        const result = await deleteMutation.mutateAsync({
            signatureTemplateId: state.existingSignatureTemplateId,
        });
        if (result.deleteSignatureTemplate.success) {
            history.goBack();
        } else {
            await fancyAlert("Error", result.deleteSignatureTemplate.message, "warning");
            dispatch({ type: "saveEnd" });
        }
    }, [deleteMutation, history, canDelete, state.existingSignatureTemplateId]);

    const fieldsForPage = state.signatureTemplate.signatureTemplateFields.filter(
        f => f.page === state.pageNumber,
    );

    const fieldsForRecipient = (recipient: number) =>
        state.signatureTemplate.signatureTemplateFields.filter(
            f => f.recipientIndex === recipient,
        );

    const handleFieldChange = React.useCallback(
        (newField: SignatureTemplateFieldInput) => {
            dispatch({
                type: "updateField",
                field: newField,
            });

            if (typeof newField.page === "number" && newField.page !== state.pageNumber) {
                setPage(newField.page);
            }
        },
        [dispatch, setPage, state.pageNumber],
    );

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (
                (e.key === "Delete" || e.key === "Backspace") &&
                state.selectedTemplateFieldId
            ) {
                e.preventDefault();
                e.stopPropagation();
                removeField(state.selectedTemplateFieldId);
            }

            if (e.key === "ArrowUp" && state.selectedTemplateFieldId) {
                e.preventDefault();
                e.stopPropagation();
                incrementFieldPosition(state.selectedTemplateFieldId, { x: 0, y: -1 });
            }

            if (e.key === "ArrowDown" && state.selectedTemplateFieldId) {
                e.preventDefault();
                e.stopPropagation();
                incrementFieldPosition(state.selectedTemplateFieldId, { x: 0, y: 1 });
            }

            if (e.key === "ArrowLeft" && state.selectedTemplateFieldId) {
                e.preventDefault();
                e.stopPropagation();
                incrementFieldPosition(state.selectedTemplateFieldId, { x: -1, y: 0 });
            }

            if (e.key === "ArrowRight" && state.selectedTemplateFieldId) {
                e.preventDefault();
                e.stopPropagation();
                incrementFieldPosition(state.selectedTemplateFieldId, { x: 1, y: 0 });
            }
        },
        [state.selectedTemplateFieldId, incrementFieldPosition, removeField],
    );

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);

        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [onKeyDown]);

    const showEncryptionBanner = typeof state.pdfInfo?.EncryptFilterName === "string";
    const canSave =
        (state.saveAllowed === "allowed" || state.existingSignatureTemplateId) &&
        !showEncryptionBanner;
    const showLimitBanner =
        state.saveAllowed === "notAllowedTooMany" && !state.existingSignatureTemplateId;
    const showPermissionsBanner = state.saveAllowed === "notAllowedPermissions";

    return (
        <QueryRenderer query={getSignature} name="GetSignatureTemplate">
            {data => {
                const emailContentSubject =
                    state.signatureTemplate.emailTemplate?.subject ??
                    state.defaultEmailContent?.subject;
                const emailContentBody =
                    state.signatureTemplate.emailTemplate?.body ??
                    state.defaultEmailContent?.body;
                const attachments =
                    state.signatureTemplate.emailTemplate?.attachments ??
                    state.defaultEmailContent?.attachments ??
                    [];
                return (
                    <Stage>
                        {showLimitBanner ? (
                            <InlineBanner type="warning">
                                Your agency is at its limit for SignWise templates, so
                                saving a new template is disabled. Delete an existing
                                template or contact support to increase your limit.
                            </InlineBanner>
                        ) : null}
                        {showPermissionsBanner ? (
                            <InlineBanner type="warning">
                                Sorry, only administrators can create SignWise templates.
                            </InlineBanner>
                        ) : null}
                        {showEncryptionBanner ? (
                            <InlineBanner type="warning">
                                This file has password-protected content. Files using
                                encryption or password protection are not supported in
                                SignWise. Please remove the password protection, re-upload
                                the file and try again.
                            </InlineBanner>
                        ) : null}
                        <SectionHeader
                            title={`SignWise Template - ${
                                data.getFile.originalFilename ?? "(untitled)"
                            }`}
                        />
                        <ContentSection2Up>
                            <div className="flex space-x-1 md:space-x-4">
                                <div className="space-y-8 min-w-80 max-w-md">
                                    <div className="space-y-2">
                                        <ul className="space-y-4">
                                            <li
                                                className={getSignatureBackgroundStyle(
                                                    0,
                                                )}>
                                                <div className="flex items-center space-x-1">
                                                    <h3 className="font-bold">
                                                        Fields to edit before sending
                                                    </h3>
                                                </div>
                                                <ul>
                                                    {fieldsForRecipient(0).map(field => (
                                                        <SignatureTemplateFieldListItem
                                                            recipientIndex={0}
                                                            key={field.id}
                                                            selectField={selectField}
                                                            selectedSignatureTemplateFieldId={
                                                                state.selectedTemplateFieldId
                                                            }
                                                            removeField={removeField}
                                                            signatureTemplateField={field}
                                                            onChange={handleFieldChange}
                                                            recipientCount={
                                                                state.signatureTemplate
                                                                    .recipientCount
                                                            }
                                                            numPages={state.numPages ?? 1}
                                                        />
                                                    ))}
                                                </ul>

                                                <SecondaryButton
                                                    onClick={() => insertField(0)}>
                                                    Add field
                                                </SecondaryButton>
                                            </li>
                                            {filledArray(
                                                state.signatureTemplate.recipientCount,
                                            ).map(i => (
                                                <li
                                                    key={`${i}`}
                                                    className={getSignatureBackgroundStyle(
                                                        i,
                                                    )}>
                                                    <div className="flex items-center justify-between">
                                                        <h3 className="font-bold">
                                                            Recipient {i}
                                                        </h3>
                                                        <DeleteButton
                                                            onClick={() =>
                                                                dispatch({
                                                                    type: "removeRecipient",
                                                                    index: i,
                                                                })
                                                            }
                                                            backgroundColor={"bg-white"}
                                                        />
                                                    </div>
                                                    <ul>
                                                        {fieldsForRecipient(i).map(
                                                            field => (
                                                                <SignatureTemplateFieldListItem
                                                                    recipientIndex={i}
                                                                    key={field.id}
                                                                    selectField={
                                                                        selectField
                                                                    }
                                                                    selectedSignatureTemplateFieldId={
                                                                        state.selectedTemplateFieldId
                                                                    }
                                                                    removeField={
                                                                        removeField
                                                                    }
                                                                    signatureTemplateField={
                                                                        field
                                                                    }
                                                                    onChange={
                                                                        handleFieldChange
                                                                    }
                                                                    recipientCount={
                                                                        state
                                                                            .signatureTemplate
                                                                            .recipientCount
                                                                    }
                                                                    numPages={
                                                                        state.numPages ??
                                                                        1
                                                                    }
                                                                />
                                                            ),
                                                        )}
                                                    </ul>
                                                    <SecondaryButton
                                                        onClick={() => insertField(i)}>
                                                        Add field
                                                    </SecondaryButton>
                                                </li>
                                            ))}
                                            <li
                                                className={getSignatureBackgroundStyle(
                                                    state.signatureTemplate
                                                        .recipientCount + 1,
                                                )}>
                                                <SecondaryButton onClick={addRecipient}>
                                                    Add recipient
                                                </SecondaryButton>
                                            </li>
                                            {state.defaultEmailContent ? (
                                                <li>
                                                    <div className="bg-white rounded-lg p-2 lg:p-4 space-y-4 shadow">
                                                        <div className="flex items-center justify-between">
                                                            <h3 className="font-bold">
                                                                Email content
                                                            </h3>
                                                            <SecondaryButton
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    dispatch({
                                                                        type: "showCustomizeEmailPanel",
                                                                    });
                                                                }}>
                                                                <span className="text-sm">
                                                                    Customize
                                                                </span>
                                                            </SecondaryButton>
                                                        </div>
                                                        <div className="space-y-1">
                                                            <p className="text-sm">
                                                                Subject
                                                            </p>
                                                            <p>{emailContentSubject}</p>
                                                        </div>
                                                        <div className="space-y-1">
                                                            <p className="text-sm">
                                                                Body
                                                            </p>
                                                            <p
                                                                className="preview-email-body-content selectable-texts line-clamp-6"
                                                                dangerouslySetInnerHTML={getSanitizedMarkUpFromEmailBody(
                                                                    emailContentBody,
                                                                )}
                                                            />
                                                        </div>
                                                        <ReadOnlyAttachments
                                                            attachmentIds={attachments}
                                                        />
                                                    </div>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </div>
                                    <div className="px-2 lg:px-4 flex items-center space-x-4">
                                        {state.isSaving ? (
                                            <SecondaryButton disabled>
                                                <Spinner />
                                            </SecondaryButton>
                                        ) : (
                                            <PrimaryButton
                                                disabled={!canSave}
                                                onClick={save}>
                                                Save template
                                            </PrimaryButton>
                                        )}
                                        {canDelete ? (
                                            <DeleteButton
                                                backgroundColor="bg-gray-200"
                                                confirm={{
                                                    message:
                                                        "Are you sure you want to delete this template?",
                                                    title: "Delete template",
                                                }}
                                                onClick={doDelete}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div>
                                    <DocumentRender
                                        documentUrl={`/api/files/download/${fileId}`}
                                        pageNumber={state.pageNumber}
                                        setPageNumber={setPage}
                                        onRenderSuccess={onPageRender}
                                        onLoadSuccess={onLoadSuccess}
                                        extraControls={
                                            <div className="flex items-center space-x-4">
                                                {canDelete ? (
                                                    <DeleteButton
                                                        backgroundColor="bg-gray-200"
                                                        confirm={{
                                                            message:
                                                                "Are you sure you want to delete this template?",
                                                            title: "Delete template",
                                                        }}
                                                        onClick={doDelete}
                                                    />
                                                ) : null}
                                                {state.isSaving ? (
                                                    <SecondaryButton disabled>
                                                        <Spinner />
                                                    </SecondaryButton>
                                                ) : (
                                                    <PrimaryButton
                                                        disabled={!canSave}
                                                        onClick={save}>
                                                        Save template
                                                    </PrimaryButton>
                                                )}
                                            </div>
                                        }>
                                        {fieldsForPage.map(f => (
                                            <SignatureTemplateFieldPlaceholder
                                                selectField={selectField}
                                                signatureTemplateField={f}
                                                onChange={field =>
                                                    dispatch({
                                                        type: "updateField",
                                                        field,
                                                    })
                                                }
                                                isSelected={
                                                    f.id === state.selectedTemplateFieldId
                                                }
                                                key={f.id}
                                            />
                                        ))}
                                    </DocumentRender>
                                </div>
                            </div>
                        </ContentSection2Up>
                        {state.defaultEmailContent ? (
                            <SignatureCustomizeEmailDialog
                                isOpen={state.showCustomizeEmailPanel}
                                onDismiss={() => {
                                    dispatch({ type: "hideCustomizeEmailPanel" });
                                }}
                                defaultContent={state.defaultEmailContent}
                                initialCustomContent={
                                    state.signatureTemplate.emailTemplate
                                }
                                onCommit={newContent => {
                                    dispatch({
                                        type: "updateTemplateEmailContent",
                                        payload: newContent,
                                    });
                                }}
                                defaultContentLabel="Use default email content"
                                defaultContentDescription="Use the default email content defined in your Signwise agency settings"
                                placeholderTypes={[
                                    AdvancedSearchEntityType.Client,
                                    AdvancedSearchEntityType.Community,
                                    AdvancedSearchEntityType.ReferenceContact,
                                    AdvancedSearchEntityType.ReferenceBusiness,
                                ]}
                            />
                        ) : null}
                    </Stage>
                );
            }}
        </QueryRenderer>
    );
};

interface SignatureTemplateFieldPlaceholderProps {
    signatureTemplateField: SignatureTemplateFieldInput;
    onChange: (signatureTemplateField: SignatureTemplateFieldInput) => void;
    selectField: (fieldId: SignatureTemplateFieldId) => void;
    isSelected: boolean;
}

export const SignatureTemplateFieldPlaceholder: React.FC<
    SignatureTemplateFieldPlaceholderProps
> = props => {
    const { signatureTemplateField, isSelected, onChange, selectField } = props;
    let placeholder = "";
    if (signatureTemplateField.fieldType === SignatureFieldType.Text) {
        placeholder =
            signatureTemplateField.defaultValue ||
            signatureTemplateField.placeholder ||
            `${signatureTemplateField.fieldType}`;
    } else {
        placeholder = `${signatureTemplateField.fieldType}`;
    }
    return (
        <Rnd
            onDragStop={(e, d) => {
                onChange({
                    ...signatureTemplateField,
                    x: Math.round(d.x),
                    y: Math.round(d.y),
                });
            }}
            onResize={(e, direction, ref) => {
                let dx = 0;
                let dy = 0;
                const oldWidth = signatureTemplateField.width;
                const oldHeight = signatureTemplateField.height;

                switch (direction) {
                    case "topLeft":
                        dx = oldWidth - ref.offsetWidth;
                        dy = oldHeight - ref.offsetHeight;
                        break;
                    case "topRight":
                        dx = 0;
                        dy = oldHeight - ref.offsetHeight;
                        break;
                    case "bottomLeft":
                        dx = oldWidth - ref.offsetWidth;
                        dy = 0;
                        break;
                    case "bottomRight":
                        dx = 0;
                        dy = 0;
                        break;
                    case "top":
                        dx = 0;
                        dy = oldHeight - ref.offsetHeight;
                        break;
                    case "bottom":
                        dx = 0;
                        dy = 0;
                        break;
                    case "left":
                        dx = oldWidth - ref.offsetWidth;
                        dy = 0;
                        break;
                    case "right":
                        dx = 0;
                        dy = 0;
                        break;
                }

                onChange({
                    ...signatureTemplateField,
                    width: Math.round(ref.offsetWidth),
                    height: Math.round(ref.offsetHeight),
                    x: Math.round(signatureTemplateField.x + dx),
                    y: Math.round(signatureTemplateField.y + dy),
                    fontSize: Math.round(ref.offsetHeight),
                });
            }}
            size={{
                width: signatureTemplateField.width,
                height: signatureTemplateField.height,
            }}
            style={{
                fontSize: `${signatureTemplateField.fontSize}px`,
                lineHeight: `${signatureTemplateField.height - 1}px`,
                fontFamily: signatureTemplateField.font,
            }}
            position={{ x: signatureTemplateField.x, y: signatureTemplateField.y }}
            className={`rounded-sm border-2 whitespace-nowrap ${getSignaturePlaceholderStyle(
                signatureTemplateField.recipientIndex,
            )} ${getSignatureBorderStyle(signatureTemplateField.recipientIndex)} ${
                isSelected
                    ? getSignatureRingStyle(signatureTemplateField.recipientIndex)
                    : ""
            }`}
            onClick={() => selectField(signatureTemplateField.id)}
            resizeHandleStyles={{
                top: { top: "-10px" },
                bottom: { bottom: "-10px" },
                left: { left: "-10px" },
                right: { right: "-10px" },
                topLeft: { top: "-10px", left: "-10px", width: "10px", height: "10px" },
                topRight: { top: "-10px", right: "-10px", width: "10px", height: "10px" },
                bottomLeft: {
                    bottom: "-10px",
                    left: "-10px",
                    width: "10px",
                    height: "10px",
                },
                bottomRight: {
                    bottom: "-10px",
                    right: "-10px",
                    width: "10px",
                    height: "10px",
                },
            }}>
            {placeholder}
        </Rnd>
    );
};

interface SignatureTemplateFieldDetailsProps {
    signatureTemplateField: SignatureTemplateFieldInput;
    onChange: (signatureTemplateField: SignatureTemplateFieldInput) => void;
    removeField: (fieldId: SignatureTemplateFieldId) => void;
    recipientCount: number;
    selectField: (fieldId: SignatureTemplateFieldId) => void;
    numPages: number;
}

interface SignatureTemplateFieldListItemProps {
    selectedSignatureTemplateFieldId: SignatureTemplateFieldId;
    recipientIndex: number;
}

const SignatureTemplateFieldListItem: React.FC<
    SignatureTemplateFieldListItemProps & SignatureTemplateFieldDetailsProps
> = props => {
    const {
        recipientIndex,
        selectedSignatureTemplateFieldId,
        selectField,
        signatureTemplateField,
        removeField,
        numPages,
        ...rest
    } = props;
    const isSelected = selectedSignatureTemplateFieldId === signatureTemplateField.id;
    const select = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            selectField(signatureTemplateField.id);
        },
        [selectField, signatureTemplateField.id],
    );

    const metadataStyle = getSignatureLinkMetadataStyle(recipientIndex);

    return (
        <li>
            {isSelected ? (
                <SignatureTemplateFieldDetails
                    removeField={removeField}
                    signatureTemplateField={signatureTemplateField}
                    selectField={selectField}
                    numPages={numPages}
                    {...rest}
                />
            ) : (
                <div className="flex justify-between pr-4">
                    <div className="flex space-x-1 items-center">
                        <SignatureFieldIcon
                            recipientIndex={recipientIndex}
                            fieldType={signatureTemplateField.fieldType}
                        />
                        <a
                            href="#"
                            className={getSignatureLinkStyle(recipientIndex)}
                            onClick={select}>
                            {signatureTemplateField.fieldType}
                        </a>
                        {numPages >= 2 ? (
                            <p className={`${metadataStyle} text-xs pl-2 pt-0.5`}>
                                Page {signatureTemplateField.page}
                            </p>
                        ) : null}
                    </div>
                </div>
            )}
        </li>
    );
};

interface SignatureTypeButtonProps {
    isSelected: boolean;
    recipientIndex: number;
    fieldType: SignatureFieldType;
    onChange: (fieldType: SignatureFieldType) => void;
}

const SignatureTypeButton: React.FC<SignatureTypeButtonProps> = props => {
    const { fieldType, onChange, recipientIndex, isSelected } = props;

    const handleClick = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            onChange(fieldType);
        },
        [onChange, fieldType],
    );

    const borderStyle = isSelected ? "border-2" : "border";

    const borderColor = isSelected
        ? getSelectedSignatureBorderStyle(recipientIndex)
        : getSignatureBorderStyle(recipientIndex);
    const textStyle = getSignatureLinkStyle(recipientIndex);

    return (
        <a
            className={`${borderStyle} flex flex-col items-center px-1 pt-2 pb-1 space-y-1 rounded-md ${borderColor} ${textStyle}`}
            href="#"
            onClick={handleClick}>
            <SignatureFieldIcon fieldType={fieldType} recipientIndex={recipientIndex} />
            <p className={`text-xs`}>{fieldType}</p>
        </a>
    );
};

interface SignatureFieldTypePickerProps {
    recipientIndex: number;
    onChange: (fieldType: SignatureFieldType) => void;
    value: SignatureFieldType;
}

const SignatureFieldTypePicker: React.FC<SignatureFieldTypePickerProps> = props => {
    const { onChange, value, recipientIndex } = props;

    const listItemClassName = recipientIndex === 0 ? "w-16" : "flex-1";

    return (
        <div>
            <p className="text-sm mt-2 mb-1">Type</p>
            <ul className="flex space-x-2">
                {recipientIndex !== 0 ? (
                    <li className={listItemClassName}>
                        <SignatureTypeButton
                            isSelected={value === SignatureFieldType.Signature}
                            fieldType={SignatureFieldType.Signature}
                            onChange={onChange}
                            recipientIndex={recipientIndex}
                        />
                    </li>
                ) : null}
                <li className={listItemClassName}>
                    <SignatureTypeButton
                        isSelected={value === SignatureFieldType.Text}
                        fieldType={SignatureFieldType.Text}
                        onChange={onChange}
                        recipientIndex={recipientIndex}
                    />
                </li>
                <li className={listItemClassName}>
                    <SignatureTypeButton
                        isSelected={value === SignatureFieldType.Date}
                        fieldType={SignatureFieldType.Date}
                        onChange={onChange}
                        recipientIndex={recipientIndex}
                    />
                </li>
                {recipientIndex !== 0 ? (
                    <li className={listItemClassName}>
                        <SignatureTypeButton
                            isSelected={value === SignatureFieldType.Initial}
                            fieldType={SignatureFieldType.Initial}
                            onChange={onChange}
                            recipientIndex={recipientIndex}
                        />
                    </li>
                ) : null}
            </ul>
        </div>
    );
};

export const SignatureTemplateFieldDetails: React.FC<
    SignatureTemplateFieldDetailsProps
> = props => {
    const { removeField, signatureTemplateField, onChange, selectField, numPages } =
        props;

    const handleAccept = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            selectField(null);
        },
        [selectField],
    );

    return (
        <div
            className={getSignatureSelectedFieldBackgroundStyle(
                signatureTemplateField.recipientIndex,
            )}>
            <div className="pr-4">
                <div className="flex space-x-2">
                    <p className="flex-1">Edit field</p>
                    <button onClick={handleAccept}>
                        <CheckIcon className="w-5 h-5 text-green-700 hover:bg-gray-100" />
                    </button>
                    <DeleteButton
                        onClick={() => removeField(signatureTemplateField.id)}
                        backgroundColor={"bg-white"}
                    />
                </div>
                <SignatureFieldTypePicker
                    recipientIndex={signatureTemplateField.recipientIndex}
                    value={SignatureFieldType[signatureTemplateField.fieldType]}
                    onChange={fieldType => {
                        onChange(
                            produce(signatureTemplateField, draft => {
                                draft.defaultValue =
                                    fieldType === SignatureFieldType.Text
                                        ? signatureTemplateField.defaultValue
                                        : "";
                                draft.fieldType = fieldType;
                                draft.font = getFontForFieldType(fieldType);
                            }),
                        );
                    }}
                />
                {numPages >= 2 ? (
                    <div className="flex space-x-2 mt-4 items-center">
                        <p>Page</p>
                        <PagePicker
                            selectedPage={signatureTemplateField.page}
                            numPages={numPages}
                            onChange={page =>
                                onChange(
                                    produce(signatureTemplateField, draft => {
                                        draft.page = page;
                                    }),
                                )
                            }
                        />
                    </div>
                ) : null}
                {signatureTemplateField.fieldType === SignatureFieldType.Text &&
                signatureTemplateField.recipientIndex === 0 ? (
                    <Input
                        label={<p className="text-sm mt-1">Default text</p>}
                        value={signatureTemplateField.defaultValue}
                        onKeyDown={e => {
                            e.stopPropagation();
                        }}
                        onChange={e =>
                            onChange(
                                produce(signatureTemplateField, draft => {
                                    draft.defaultValue = e.target.value;
                                }),
                            )
                        }
                    />
                ) : null}
                {signatureTemplateField.fieldType === SignatureFieldType.Text &&
                signatureTemplateField.recipientIndex > 0 ? (
                    <Input
                        label={<p className="text-sm mt-1">Placeholder text</p>}
                        value={signatureTemplateField.placeholder}
                        onKeyDown={e => {
                            e.stopPropagation();
                        }}
                        onChange={e =>
                            onChange(
                                produce(signatureTemplateField, draft => {
                                    draft.placeholder = e.target.value;
                                }),
                            )
                        }
                    />
                ) : null}
            </div>
        </div>
    );
};
