import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import React from "react";
import { useCopyToClipboard } from "./hooks";

interface CopyableLinkProps {
    url: string;
}

export const CopyableLink: React.FC<CopyableLinkProps> = props => {
    const { url } = props;
    const [copy, isCopied] = useCopyToClipboard();
    return (
        <div className="md:flex sm:space-y-2 md:space-y-0 md:space-x-2">
            <div className="md:flex-1">
                {isCopied ? (
                    <div className="rounded text-center bg-green-100 text-green-600 text-lg py-2 font-bold">
                        Link copied!
                    </div>
                ) : (
                    <Input readOnly value={url} />
                )}
            </div>
            <div className="md:flex-0">
                <PrimaryButton onClick={() => copy(url)}>Copy to clipboard</PrimaryButton>
            </div>
        </div>
    );
};
