import { QuestionId } from "@sp-crm/core";

export interface ISearchRating {
    value: number;
    includeUnrated: boolean;
}

export interface ISearchAddress {
    street: string;
    zip: string;
    state: string;
    city: string;
    county: string;
}

export interface ISearchPrice {
    min: number;
    max: number;
    skipMax?: boolean;
    includePriceUndefined?: boolean;
    isOverlap?: boolean;
}

export function isSearchLocationValid(location: ISearchAddress): boolean {
    if (!location) {
        return false;
    }
    return !!(
        isZipValid(location.zip) ||
        (location.state && location.city && location.state.length && location.city.length)
    );
}

export function isZipValid(zip: string): boolean {
    if (zip && zip.length >= 5) return true;
    return false;
}

export interface ISearchAnswer {
    questionId: QuestionId;
    booleanAnswer: "na" | "any" | "true" | "false";
    selectionAnswer: string[];
    rangeAnswer?: ISearchPrice;
}
