import { fancyAlert } from "components/ui/fancy-alert";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import { useUserManagementCreateSystemUserMutation } from "generated/graphql";
import React from "react";
import { useRegionId } from "store/selectors/hooks";
import { invalidateUserList } from "../manage-users/helper";

interface FormValues {
    name: string;
    role: string;
}

const initialValues: FormValues = {
    name: "",
    role: "power-user",
};

interface ManageIntegrationsNewAccountProps {
    onCreated: () => void;
}

export const ManageIntegrationsNewAccount: React.FC<
    ManageIntegrationsNewAccountProps
> = props => {
    const { onCreated } = props;
    const regionId = useRegionId();
    const mutation = useUserManagementCreateSystemUserMutation();
    const [showSuccessNotification, setShowSuccessNotification] = React.useState(false);
    const validateFormInput = (values: FormValues): FormikErrors<FormValues> => {
        const errors: FormikErrors<FormValues> = {};
        if (values.name === undefined || values.name === null || values.name === "") {
            errors.name = "Name cannot be blank";
        }
        return errors;
    };
    const submitForm = React.useCallback(
        async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
            helpers.setSubmitting(true);
            const payload = {
                name: values.name,
                roleKey: values.role,
                regionId,
            };
            try {
                await mutation.mutateAsync(payload);
                helpers.setSubmitting(false);
                helpers.resetForm();
                invalidateUserList();
                onCreated();
                setShowSuccessNotification(true);
                setTimeout(() => setShowSuccessNotification(false), 5000);
            } catch (e) {
                helpers.setSubmitting(false);
                fancyAlert("Sorry, something went wrong", e.message, "warning");
            }
        },
        [regionId, mutation, onCreated],
    );

    return (
        <Formik
            validate={validateFormInput}
            onSubmit={submitForm}
            initialValues={initialValues}>
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                touched,
                isSubmitting,
                isValid,
                dirty,
            }) => (
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Input
                            label="Display name"
                            name="name"
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                        />
                        {touched.name && errors.name ? (
                            <div className="text-red-700 font-bold mt-1">
                                {errors.name}
                            </div>
                        ) : null}
                    </div>
                    {dirty && isValid && !isSubmitting ? (
                        <PrimaryButton type="submit">Create</PrimaryButton>
                    ) : (
                        <SecondaryButton disabled type="submit">
                            Create
                        </SecondaryButton>
                    )}
                    {showSuccessNotification ? (
                        <InlineBanner type="success">
                            Integration account created!
                        </InlineBanner>
                    ) : null}
                </form>
            )}
        </Formik>
    );
};
