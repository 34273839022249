import { Action, Dispatch } from "redux";
import { GetUserTenantRegionOptionsQuery } from "../../generated/graphql";
import * as http from "../../util/http";
import { loadAll, navigate } from "./index";
import { createChangeRegionAction } from "./region";

export const switchUser = async (
    option: GetUserTenantRegionOptionsQuery["getUserTenantRegionOptions"]["options"][0],
    dispatch: Dispatch<Action>,
) => {
    const requestDescription = http.newRequestDescription();
    const payload = {
        tenantId: option.tenantId,
        userId: option.userId,
    };
    await http.put(requestDescription, `/api/session`, payload);
    dispatch(createChangeRegionAction(option.regionKey));
    loadAll(dispatch);
    navigate("/");
};
