import { PhoneArrowUpRightIcon } from "@heroicons/react/24/solid";
import { formatPhoneNumber } from "@sp-crm/core";
import { CrmEntityContactType } from "generated/graphql";
import React from "react";
import { Taskv2 } from "./types";

interface TaskInlineCallProps {
    contact: Taskv2["entity"]["contact"];
}

const contactTypeToLabel = (type: CrmEntityContactType): string => {
    switch (type) {
        case CrmEntityContactType.BestContact:
            return "Best Contact";
        case CrmEntityContactType.Main:
            return "Main";
        case CrmEntityContactType.Cell:
            return "Cell";
        case CrmEntityContactType.Other:
            return "Alternate";
        default:
            // eslint-disable-next-line no-case-declarations
            const _exhaustiveCheck: never = type;
            return "";
    }
};

const phoneButtonClasses =
    "flex justify-start items-center py-2 px-4 border-2 border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-800 bg-white " +
    "hover:bg-gray-200 hover:text-black " +
    "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 " +
    "space-x-1 lg:space-x-2 text-sm";

export const TaskInlineCall: React.FC<TaskInlineCallProps> = props => {
    const { contact } = props;
    if (!contact) return <div />;
    if (!contact.phoneNumber) return <div />;
    return (
        <div>
            <a
                className={`twoverride ${phoneButtonClasses}`}
                href={`tel:${contact.phoneNumber}`}>
                <PhoneArrowUpRightIcon className="flex-0 h-4 w-4 text-green-600" />
                <span className="flex-1 whitespace-nowrap text-ellipsis overflow-hidden">
                    {contact.name || formatPhoneNumber(contact.phoneNumber)} (
                    {contactTypeToLabel(contact.contactType)})
                </span>
            </a>
        </div>
    );
};
