import { BookmarkIcon } from "@heroicons/react/24/outline";
import { SupportEmailLink } from "components/app/support-email-link";
import { useRefreshCustomQueries } from "components/reports/show/custom-reports/custom-reports-helpers";
import { DeleteButton } from "components/ui/action-button";
import { Checkbox } from "components/ui/checkbox";
import { fancyAlert } from "components/ui/fancy-alert";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    AdvancedSearchRequest,
    GetSavedQueriesQuery,
    useCreateSavedQueryMutation,
    useDeleteSavedQueryMutation,
    useUpdateSavedQueryMutation,
} from "generated/graphql";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUsers } from "store/selectors/hooks";

interface AdvancedSearchSaveExistingProps {
    report: GetSavedQueriesQuery["getSavedQueries"]["savedQueries"][0];
    searchRequest: AdvancedSearchRequest;
}

export const AdvancedSearchSaveExisting: React.FC<
    AdvancedSearchSaveExistingProps
> = props => {
    const { report, searchRequest } = props;
    const updateMutation = useUpdateSavedQueryMutation();
    const deleteMutation = useDeleteSavedQueryMutation();
    const { push } = useHistory();

    const refresh = useRefreshCustomQueries();
    const [queryName, setQueryName] = useState(report.name);
    const [saveQueryUiVisible, setSaveQueryUiVisible] = useState(false);
    const [isShared, setIsShared] = useState(report.isShared);
    const toggleSaveQueryUi = useCallback(
        (
            e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
        ) => {
            e && e.preventDefault && e.preventDefault();
            setSaveQueryUiVisible(x => !x);
        },
        [],
    );
    const saveReport = useCallback(async () => {
        if (!queryName || queryName.trim() === "") {
            await fancyAlert("Name missing", "Please enter a name for the report");
            return;
        }
        const savedQuery = {
            name: queryName,
            id: report.id,
            condition: searchRequest.condition,
            sort: searchRequest.sort,
            isShared,
            entityType: searchRequest.entityType,
            select: searchRequest.select,
            summaries: searchRequest.summaries,
        };
        await updateMutation.mutateAsync({ savedQuery: savedQuery });
        toggleSaveQueryUi(null);
        refresh();
    }, [
        updateMutation,
        searchRequest,
        report,
        isShared,
        refresh,
        queryName,
        toggleSaveQueryUi,
    ]);

    const handleSubmit = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            await saveReport();
        },
        [saveReport],
    );
    const deleteReport = useCallback(async () => {
        const confirmed = await fancyConfirm(
            "Delete report",
            "Are you sure you want to delete this report?",
            "Yes, delete",
            "Cancel",
        );
        if (!confirmed) return;
        await deleteMutation.mutateAsync({ savedQueryId: report.id });
        refresh();
        push(`/reports/show/custom/new`);
    }, [refresh, push, deleteMutation, report]);

    const users = useUsers().users;

    const ownerName = users[report.createdBy]?.name || "another user";

    if (!saveQueryUiVisible) {
        return (
            <div className="flex space-x-4 items-center">
                <SecondaryButton disabled={!report.editable} onClick={toggleSaveQueryUi}>
                    Save
                </SecondaryButton>
                {report.editable ? (
                    <DeleteButton backgroundColor="bg-gray-200" onClick={deleteReport} />
                ) : (
                    <InlineBanner type="info">
                        This query is read-only because it is owned by {ownerName}
                    </InlineBanner>
                )}
            </div>
        );
    }
    return (
        <div className="space-y-4 p-4">
            <div>
                <Input
                    label="Name"
                    value={queryName}
                    onChange={e => setQueryName(e.target.value)}
                />
            </div>
            <div>
                <Checkbox
                    label="Share query with everyone at my company"
                    checked={isShared}
                    onChange={e => setIsShared(e.target.checked)}
                />
            </div>
            <div className="flex space-x-4 items-center">
                <PrimaryButton type="button" onClick={handleSubmit}>
                    Save
                </PrimaryButton>
                <SecondaryButton type="button" onClick={toggleSaveQueryUi}>
                    Cancel
                </SecondaryButton>
            </div>
        </div>
    );
};

interface AdvancedSearchSaveNewProps {
    searchRequest: AdvancedSearchRequest;
    canSaveAdditional: boolean;
}

export const AdvancedSearchSaveNew: React.FC<AdvancedSearchSaveNewProps> = props => {
    const { searchRequest, canSaveAdditional } = props;
    const { push } = useHistory();
    const refresh = useRefreshCustomQueries();
    const createMutation = useCreateSavedQueryMutation();
    const [queryName, setQueryName] = useState("");
    const [saveQueryUiVisible, setSaveQueryUiVisible] = useState(false);
    const [isShared, setIsShared] = useState(false);
    const toggleSaveQueryUi = useCallback(
        (
            e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
        ) => {
            e && e.preventDefault && e.preventDefault();
            setSaveQueryUiVisible(x => !x);
        },
        [setSaveQueryUiVisible],
    );
    const saveReport = useCallback(async () => {
        if (!queryName || queryName.trim() === "") {
            await fancyAlert("Name missing", "Please enter a name for the report");
            return;
        }
        const savedQuery = {
            name: queryName,
            condition: searchRequest.condition,
            sort: searchRequest.sort,
            isShared,
            entityType: searchRequest.entityType,
            select: searchRequest.select,
            summaries: searchRequest.summaries,
        };
        const result = await createMutation.mutateAsync({ savedQuery: savedQuery });
        push(`/reports/show/custom/${result.createSavedQuery.id}`);
        refresh();
    }, [createMutation, searchRequest, isShared, push, refresh, queryName]);

    const handleSubmit = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();
            await saveReport();
        },
        [saveReport],
    );

    if (!saveQueryUiVisible) {
        return (
            <SecondaryButton
                onClick={toggleSaveQueryUi}
                className="flex items-center space-x-2">
                <BookmarkIcon className="w-6 h-6" />
                <div>Save report</div>
            </SecondaryButton>
        );
    }
    if (!canSaveAdditional) {
        return (
            <div className="flex space-x-4 items-center">
                <SecondaryButton
                    disabled={!canSaveAdditional}
                    onClick={toggleSaveQueryUi}>
                    Save
                </SecondaryButton>
                <InlineBanner type="info">
                    You have reached the maximum number of saved reports. Please contact
                    your administrator or <SupportEmailLink /> to upgrade to a higher
                    plan.
                </InlineBanner>
            </div>
        );
    }
    return (
        <div className="space-y-4 p-4">
            <div>
                <Input
                    label="Name"
                    value={queryName}
                    onChange={e => setQueryName(e.target.value)}
                />
            </div>
            <div>
                <Checkbox
                    label="Share query with everyone at my company"
                    checked={isShared}
                    onChange={e => setIsShared(e.target.checked)}
                />
            </div>
            <div className="flex space-x-4 items-center">
                <PrimaryButton type="button" onClick={handleSubmit}>
                    Save
                </PrimaryButton>
                <SecondaryButton type="button" onClick={toggleSaveQueryUi}>
                    Cancel
                </SecondaryButton>
            </div>
        </div>
    );
};
