import { Localization } from "@sp-crm/core";
import { Input } from "components/ui/input";
import { SelectPro } from "components/ui/select-pro";
import { SelectBaseOption, SelectProOption } from "helpers/select-defs";
import * as React from "react";
import * as Util from "../../../util";
import { LocaleContext } from "../../locale-provider/locale-provider";
import { ValidationError } from "../../shared/validation-error";
import { ISearchFilterClosable } from "./search-filter-closable.interface";
import { SearchFilterProps } from "./search-filter-props.interface";
import { ISearchAddress, isSearchLocationValid, isZipValid } from "./util";

interface SearchLocationProps {
    address: ISearchAddress;
    maxDistance: number;
    specificZip: boolean;
    specificCounty: boolean;
    specificCity: boolean;
    defaultState: string | null;
}

interface SearchLocationState {
    address: ISearchAddress;
    maxDistance: number;
    specificZip: boolean;
    specificCounty: boolean;
    specificCity: boolean;
    isValid: boolean;
    disabledInputs: IDisabledInputs;
}

interface IDisabledInputs {
    street: boolean;
    state: boolean;
    city: boolean;
    zip: boolean;
}

const SpecificDistance = {
    city: "specificCity",
    zip: "specificZip",
    county: "specificCounty",
};

type SearchLocationFilterProps = SearchLocationProps & SearchFilterProps;

export class SearchLocation
    extends React.Component<SearchLocationFilterProps, SearchLocationState>
    implements ISearchFilterClosable
{
    constructor(props: SearchLocationFilterProps) {
        super(props);
        this.state = {
            address: {
                city: props.address.city || "",
                state: props.address.state || props.defaultState || "",
                street: props.address.street || "",
                zip: props.address.zip || "",
                county: props.address.county || "",
            },
            specificZip: props.specificZip,
            specificCity: props.specificCity,
            specificCounty: props.specificCounty,
            maxDistance: props.maxDistance,
            disabledInputs: this.disableInputs(props.specificZip, props.specificCity),
            isValid: true,
        };

        this.onClose = this.onClose.bind(this);
        this.onValid = this.onValid.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
    }

    static mapDistances(localizationStrings: Localization): SelectProOption[] {
        const availableDistances: SelectBaseOption[] = [1, 3, 5, 10, 15, 20, 30, 50].map(
            index => {
                return {
                    value: index.toString(),
                    text: `Within ${index} 
                        ${
                            index > 1
                                ? `${localizationStrings.strings.distanceUnitPlural}`
                                : `${localizationStrings.strings.distanceUnit}`
                        } 
                        of`,
                };
            },
        );

        return [
            {
                header: "In specific...",
                groupOptions: [
                    {
                        value: SpecificDistance.city,
                        text: "City",
                    },
                    {
                        value: SpecificDistance.zip,
                        text: localizationStrings.strings.zip,
                    },
                    {
                        value: SpecificDistance.county,
                        text: "County",
                    },
                ],
            },
            { header: "Proximity", groupOptions: availableDistances },
        ];
    }

    UNSAFE_componentWillMount() {
        this.onValid();
    }

    componentWillUnmount() {
        this.onClose();
    }

    disableInputs(specificZip: boolean, specificCity: boolean): IDisabledInputs {
        const initialInputsState = {
            street: false,
            state: false,
            city: false,
            zip: false,
        };

        if (!specificZip && !specificCity) {
            return initialInputsState;
        }

        if (specificCity) {
            initialInputsState.street = true;
            initialInputsState.state = false;
            initialInputsState.zip = true;
        } else {
            initialInputsState.street = true;
            initialInputsState.state = true;
            initialInputsState.city = true;
        }

        return initialInputsState;
    }

    isFormTouched(): boolean {
        const { zip, street, city, state } = this.state.address;
        return !!(zip.length || street.length || city.length || state.length);
    }

    getDistanceSelectedKey(): string {
        return (
            (this.state.specificCity && "specificCity") ||
            (this.state.specificZip && "specificZip") ||
            (this.state.specificCounty && "specificCounty") ||
            this.state.maxDistance.toString()
        );
    }

    isSpecificLocationValid(address: ISearchAddress): boolean {
        if (this.state.specificCity) {
            return address.city.length > 0;
        }
        if (this.state.specificZip) {
            return isZipValid(address.zip);
        }
        if (this.state.specificCounty) {
            return this.state.address.county.length > 0;
        }
        return false;
    }

    onClose() {
        this.props.onClose({
            activeState: {
                address: this.state.address,
                maxDistance: this.state.maxDistance,
                specificZip: this.state.specificZip,
                specificCity: this.state.specificCity,
                specificCounty: this.state.specificCounty,
                sort: "distance",
            },
            defaultState: {
                address: { zip: "", street: "", state: "", city: "" },
                maxDistance: 5,
                specificZip: false,
                specificCity: false,
                specificCounty: false,
                sort: "name",
            },
        });
    }

    onValid() {
        const newFormValid =
            this.state.specificCity || this.state.specificZip || this.state.specificCounty
                ? this.isSpecificLocationValid(this.state.address)
                : isSearchLocationValid(this.state.address);

        this.setState({ ...this.state, isValid: newFormValid });
        return this.props.onValid(newFormValid);
    }

    onAddressChange(prop: { [key: string]: number | string }) {
        const newStateAddress: ISearchAddress = { ...this.state.address, ...prop };
        this.setState({ ...this.state, address: newStateAddress }, () => this.onValid());
    }

    onDistanceChange(distance: string) {
        const newState = { ...this.state };

        const parsedDistance = parseInt(distance, 10);

        if (parsedDistance.toString() === distance) {
            Object.assign(newState, {
                maxDistance: parsedDistance,
                [SpecificDistance.city]: false,
                [SpecificDistance.zip]: false,
                [SpecificDistance.county]: false,
            });
        } else {
            const distances = {
                [SpecificDistance.city]: distance === SpecificDistance.city,
                [SpecificDistance.zip]: distance === SpecificDistance.zip,
                [SpecificDistance.county]: distance === SpecificDistance.county,
            };
            Object.assign(newState, distances);
        }

        newState.disabledInputs = this.disableInputs(
            newState.specificZip,
            newState.specificCity,
        );
        Object.keys(newState.disabledInputs).forEach(key => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            (newState.address as any)[key] = (newState.disabledInputs as any)[key]
                ? ""
                : // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                  (newState.address as any)[key];
        });
        this.setState({ ...this.state, ...newState }, () => this.onValid());
    }

    onStateChange(key: string) {
        const newStateAddress: ISearchAddress = {
            ...this.state.address,
            state: key,
        };
        this.setState({ ...this.state, address: newStateAddress }, () => this.onValid());
    }

    render() {
        return (
            <LocaleContext.Consumer>
                {locale => (
                    <form className="space-y-2 w-112">
                        <ValidationError
                            hasError={this.props.isSubmitted && !this.state.isValid}>
                            Enter at least City and {locale.strings.state} or{" "}
                            {locale.strings.zip}
                        </ValidationError>

                        <div>
                            <SelectPro
                                includePlaceholderOption={false}
                                value={this.getDistanceSelectedKey()}
                                onChange={e => this.onDistanceChange(e.target.value)}
                                options={SearchLocation.mapDistances(locale)}
                            />
                        </div>
                        {this.state.specificCounty ? (
                            <div className="flex space-x-2">
                                <div className="flex-1">
                                    <Input
                                        value={this.state.address.county}
                                        onChange={event =>
                                            this.onAddressChange({
                                                county: event.target.value,
                                            })
                                        }
                                        placeholder="County"
                                    />
                                </div>
                                <div className="flex-1">
                                    <SelectPro
                                        value={this.state.address.state}
                                        includePlaceholderOption
                                        placeholderStr={
                                            this.state.disabledInputs.state
                                                ? ""
                                                : "(select)"
                                        }
                                        onChange={ev =>
                                            this.onStateChange(ev.target.value)
                                        }
                                        options={Util.default.getStatesArray()}
                                    />
                                </div>
                            </div>
                        ) : (
                            <>
                                <div>
                                    <Input
                                        value={this.state.address.street}
                                        onChange={event =>
                                            this.onAddressChange({
                                                street: event.target.value,
                                            })
                                        }
                                        placeholder="Street Address (optional)"
                                        disabled={this.state.disabledInputs.street}
                                    />
                                </div>

                                <div className="flex space-x-2">
                                    <div className="flex-1">
                                        <Input
                                            value={this.state.address.city}
                                            onChange={e =>
                                                this.onAddressChange({
                                                    city: e.target.value,
                                                })
                                            }
                                            placeholder="City"
                                            disabled={this.state.disabledInputs.city}
                                        />
                                    </div>

                                    <div className="flex-1">
                                        <SelectPro
                                            value={this.state.address.state}
                                            includePlaceholderOption
                                            placeholderStr={
                                                // if disabled, set the placeholder str to an empty string
                                                // so we don't show a disabled control that says "(select)"
                                                this.state.disabledInputs.state
                                                    ? ""
                                                    : "(select)"
                                            }
                                            onChange={ev =>
                                                this.onStateChange(ev.target.value)
                                            }
                                            disabled={this.state.disabledInputs.state}
                                            options={Util.default.getStatesArray()}
                                        />
                                    </div>

                                    <div className="flex-1">
                                        <Input
                                            value={this.state.address.zip.toString()}
                                            onChange={e =>
                                                this.onAddressChange({
                                                    zip: e.target.value,
                                                })
                                            }
                                            placeholder={locale.strings.zip}
                                            disabled={this.state.disabledInputs.zip}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                )}
            </LocaleContext.Consumer>
        );
    }
}
