import {
    EmailTemplateId,
    LayoutSectionKey,
    emailTokens,
    parseEntityIdSafe,
} from "@sp-crm/core";
import { SpecialComposeCommmunityComparisonProps } from "components/messages/special/community-comparison";
import { Checkbox } from "components/ui/checkbox";
import {} from "components/ui/inline-banner";
import { Select } from "components/ui/select";
import React, { useCallback } from "react";
import {
    LayoutSectionParentKey,
    useGetLayoutSectionsQuery,
} from "../../../../generated/graphql";
import { LayoutSectionResult } from "../../../layout/layout-items";
import { LayoutSectionPicker } from "../../../layout/layout-section-picker";
import { QueryRenderer } from "./query-renderer";

type EmailCommunityComparisonCustomizationProps = SpecialComposeCommmunityComparisonProps;

export const EmailCommunityComparisonCustomization: React.FC<
    EmailCommunityComparisonCustomizationProps
> = props => {
    const { values, onChange, templates, liveLinkText } = props;

    const onSectionsChanged = useCallback(
        (sections: LayoutSectionResult[]) => {
            onChange({
                ...values,
                sectionKeys: sections
                    .filter(s => s.visible)
                    .map(s => s.sectionKey as LayoutSectionKey),
            });
        },
        [values, onChange],
    );

    const query = useGetLayoutSectionsQuery(
        {
            parentKey: LayoutSectionParentKey.CommunityComparisonTable,
        },
        {
            onSuccess: data => {
                onSectionsChanged(data.getLayoutSections.layoutSections);
            },
        },
    );

    return (
        <QueryRenderer query={query} name="EmailCommunityComparisonCustomization">
            {data => (
                <div className="space-y-2">
                    <Checkbox
                        checked={values.includeLiveLink}
                        label={`Insert link to live community comparison with label "${liveLinkText}"`}
                        onChange={e => {
                            onChange({ ...values, includeLiveLink: e.target.checked });
                        }}
                    />
                    {values.includeLiveLink ? (
                        <em>
                            <span className="block">
                                You&apos;ll need to add &quot;
                                <code>{emailTokens.intakeLiveLink}</code>&quot; to the
                                message body so we know where to insert the community
                                comparison link.
                            </span>
                        </em>
                    ) : null}
                    <Checkbox
                        checked={values.includePDF}
                        label="Attach Community Comparison PDF"
                        onChange={e => {
                            onChange({ ...values, includePDF: e.target.checked });
                        }}
                    />
                    {values.includePDF ? (
                        templates.length > 0 ? (
                            <div className="space-y-2 pl-2 border-l-8 border-gray-100">
                                <Checkbox
                                    checked={values.showCoverPage}
                                    label="Show cover page"
                                    onChange={e => {
                                        onChange({
                                            ...values,
                                            showCoverPage: e.target.checked,
                                        });
                                    }}
                                />
                                {values.showCoverPage ? (
                                    <Select
                                        onChange={e => {
                                            const parsedId =
                                                parseEntityIdSafe<EmailTemplateId>(
                                                    e.target.value,
                                                );
                                            onChange({
                                                ...values,
                                                coverPageTemplate: parsedId.hasValue
                                                    ? parsedId.get()
                                                    : null,
                                            });
                                        }}
                                        value={values.coverPageTemplate ?? "_"}>
                                        <option value="_">Select template</option>
                                        {templates.map(t => (
                                            <option key={t.id} value={t.id}>
                                                {t.description ?? "Untitled"}
                                            </option>
                                        ))}
                                    </Select>
                                ) : null}
                            </div>
                        ) : (
                            <div>
                                <em>
                                    To include a cover page, create a{" "}
                                    <strong>template</strong> in your settings.
                                </em>
                            </div>
                        )
                    ) : null}
                    {values.includePDF || values.includeLiveLink ? (
                        <div className="pl-2 border-l-8 border-gray-100">
                            <LayoutSectionPicker
                                layoutSections={data.getLayoutSections.layoutSections.map(
                                    s => ({
                                        ...s,
                                        visible: values.sectionKeys
                                            ? values.sectionKeys.includes(
                                                  s.sectionKey as LayoutSectionKey,
                                              )
                                            : s.visible,
                                    }),
                                )}
                                onSectionsChanged={onSectionsChanged}
                                hideSections={
                                    values.includePDF && !values.includeLiveLink
                                        ? [LayoutSectionKey.CommunityComparisonPhotosRow]
                                        : []
                                }
                            />
                        </div>
                    ) : null}
                </div>
            )}
        </QueryRenderer>
    );
};
