import { ContactIcon, IContact } from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { AutosavingStateInput } from "components/ui/autosaving-state-input";
import { Checkbox } from "components/ui/checkbox";
import { ContactNameInput } from "components/ui/contact-name-input";
import * as React from "react";
import { Feature } from "../feature";
import { Header } from "../header";
import { LocaleContext } from "../locale-provider/locale-provider";
import Shared from "../shared";

export type ContactLayout = "Standard" | "Physician";

export interface ContactRenderInfo {
    contactGroupIcon: ContactIcon;
    contactGroupHeading: string;
    contactGroupHeadingShortName?: string;
    showRelationshipField: boolean;
    showRoleField: boolean;
    showAddressFields: boolean;
    specializedLayout: ContactLayout;
    hideContactNotes: boolean;
    allowReferenceLink: boolean;
    contactNotesLabel?: string;
    allowPreferredName: boolean;
}

export interface InputContactPersonProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (field: ContactFormFields, newValue: any) => void;
    contact: IContact;
    headerLabel: string;
    contactRenderInfo: ContactRenderInfo;
    banner?: JSX.Element;
    children?: React.ReactNode;
}

export type ContactFormFields =
    | "name"
    | "relationship"
    | "role"
    | "cellPhone"
    | "phone1"
    | "fax"
    | "email1"
    | "email1OptOut"
    | "address1"
    | "city"
    | "state"
    | "zip"
    | "primary"
    | "contactNotes"
    | "clientPowerOfAttorney"
    | "referenceId";

export class InputContactPerson extends React.Component<InputContactPersonProps> {
    constructor(props: InputContactPersonProps) {
        super(props);
    }

    private physicianNameRelationshipAndRole(): JSX.Element {
        return (
            <div>
                <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                    <AutosavingInput
                        label="Provider name"
                        initial={this.props.contact.name}
                        onCommit={newVal => this.props.onFieldChange("name", newVal)}
                    />
                    <AutosavingInput
                        label="Type of provider"
                        initial={this.props.contact.relationship}
                        onCommit={newVal =>
                            this.props.onFieldChange("relationship", newVal)
                        }
                    />
                </div>
                <AutosavingInput
                    label="Clinic / business name (if applicable)"
                    initial={this.props.contact.role}
                    onCommit={newVal => this.props.onFieldChange("role", newVal)}
                />
            </div>
        );
    }

    private standardNameRelationshipRole(): JSX.Element {
        const nameField = this.props.contactRenderInfo.allowPreferredName ? (
            <ContactNameInput
                name={this.props.contact.name}
                preferredName={this.props.contact.preferredName}
                onCommit={this.props.onFieldChange}
            />
        ) : (
            <AutosavingInput
                label="Name"
                initial={this.props.contact.name}
                onCommit={newVal => this.props.onFieldChange("name", newVal)}
            />
        );

        let relationshipOrRole = null;

        if (this.props.contactRenderInfo.showRelationshipField) {
            relationshipOrRole = (
                <AutosavingInput
                    label="Relationship"
                    initial={this.props.contact.relationship}
                    onCommit={newVal => this.props.onFieldChange("relationship", newVal)}
                />
            );
        } else if (this.props.contactRenderInfo.showRoleField) {
            relationshipOrRole = (
                <AutosavingInput
                    label="Role / title"
                    initial={this.props.contact.role}
                    onCommit={newVal => this.props.onFieldChange("role", newVal)}
                />
            );
        }

        return (
            <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                {nameField} {relationshipOrRole}
            </div>
        );
    }

    private showNameRelationshipRoleFields(): JSX.Element {
        if (this.props.contactRenderInfo.specializedLayout == "Physician") {
            return this.physicianNameRelationshipAndRole();
        }

        return this.standardNameRelationshipRole();
    }

    private showAddress(): JSX.Element {
        if (this.props.contactRenderInfo.showAddressFields) {
            return (
                <LocaleContext.Consumer>
                    {locale => (
                        <div className="space-y-1 md:space-y-2">
                            <div>
                                <AutosavingInput
                                    label="Address"
                                    initial={this.props.contact.address1}
                                    onCommit={newVal =>
                                        this.props.onFieldChange("address1", newVal)
                                    }
                                />
                            </div>
                            <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                                <AutosavingInput
                                    label="City"
                                    initial={this.props.contact.city}
                                    onCommit={newVal =>
                                        this.props.onFieldChange("city", newVal)
                                    }
                                />
                                <AutosavingStateInput
                                    label={locale.strings.state}
                                    initial={this.props.contact.state}
                                    onCommit={newVal =>
                                        this.props.onFieldChange("state", newVal)
                                    }
                                />
                                <AutosavingInput
                                    label={locale.strings.zip}
                                    initial={this.props.contact.zip}
                                    onCommit={newVal =>
                                        this.props.onFieldChange("zip", newVal)
                                    }
                                />
                            </div>
                        </div>
                    )}
                </LocaleContext.Consumer>
            );
        }

        return null;
    }

    private phoneInputs(): JSX.Element {
        if (this.props.contactRenderInfo.specializedLayout == "Physician") {
            return (
                <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                    <Shared.PhoneInput
                        label="Phone number"
                        initial={this.props.contact.phone1}
                        onCommit={newVal => this.props.onFieldChange("phone1", newVal)}
                    />
                    <Shared.PhoneInput
                        label="Fax number"
                        initial={this.props.contact.fax}
                        onCommit={newVal => this.props.onFieldChange("fax", newVal)}
                    />
                </div>
            );
        } else {
            return (
                <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                    <Shared.PhoneInput
                        label="Cell phone number"
                        initial={this.props.contact.cellPhone}
                        onCommit={newVal => this.props.onFieldChange("cellPhone", newVal)}
                    />
                    <Shared.PhoneInput
                        label="Phone number (other)"
                        initial={this.props.contact.phone1}
                        onCommit={newVal => this.props.onFieldChange("phone1", newVal)}
                    />
                </div>
            );
        }
    }

    private renderHeader(): JSX.Element {
        return <span>{this.props.headerLabel}</span>;
    }

    private renderInner() {
        return (
            <>
                <Header iconName="08_ContactPerson">{this.renderHeader()}</Header>
                {this.props.banner ? <div>{this.props.banner}</div> : null}
                <div className="space-y-2 md:space-y-4">
                    <div>{this.showNameRelationshipRoleFields()}</div>
                    <div>{this.phoneInputs()}</div>
                    <div>
                        <AutosavingInput
                            label="Email"
                            initial={this.props.contact.email1}
                            onCommit={newVal =>
                                this.props.onFieldChange("email1", newVal)
                            }
                        />
                    </div>
                    <Feature name="emailoptout">
                        <div>
                            <Checkbox
                                checked={this.props.contact.email1OptOut}
                                label="Email Opt-out (for bulk/newsletter emails)"
                                onChange={e =>
                                    this.props.onFieldChange(
                                        "email1OptOut",
                                        e.target.checked,
                                    )
                                }
                            />
                        </div>
                    </Feature>
                    <div>{this.showAddress()}</div>
                    {this.props.contactRenderInfo.hideContactNotes === true ? null : (
                        <div>
                            <AutosavingInput
                                label={
                                    this.props.contactRenderInfo.contactNotesLabel ||
                                    "Contact notes / details"
                                }
                                multiLine={true}
                                initial={this.props.contact.contactNotes}
                                onCommit={newVal =>
                                    this.props.onFieldChange("contactNotes", newVal)
                                }
                            />
                        </div>
                    )}
                    {this.props.children ? <div>{this.props.children}</div> : null}
                </div>
            </>
        );
    }

    render() {
        return (
            <div className="input-form-block best-contact-person-block">
                {this.renderInner()}
            </div>
        );
    }
}
