import {
    AnswerEntityId,
    IAnswer,
    LayoutSectionParentKey,
    QuestionSource,
} from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { useGetLayoutContainerByKeyQuery } from "generated/graphql";
import React from "react";
import { stableQueryOptions } from "util/requests";
import { LayoutFormSection } from "./layout-form-section";

interface EntityLayoutContainerProps {
    containerKey: LayoutSectionParentKey;
    entity: unknown;
    entityType: string;
    entityId: AnswerEntityId;
    answerMode: QuestionSource;
    onAnswerCommitted: (answerEntityId: AnswerEntityId, answer: IAnswer) => void;
    onFieldChange: (field: string, value: string | boolean) => void;
}

export const EntityLayoutContainer: React.FC<EntityLayoutContainerProps> = props => {
    const {
        containerKey,
        entity,
        entityId,
        entityType,
        answerMode,
        onAnswerCommitted,
        onFieldChange,
    } = props;

    const getLayoutContainer = useGetLayoutContainerByKeyQuery(
        { key: containerKey },
        stableQueryOptions(),
    );

    return (
        <QueryRenderer
            name={`EntityLayoutContainer.${containerKey}`}
            query={getLayoutContainer}>
            {data => {
                return (
                    <ul>
                        {data.getLayoutContainerByKey.layoutSections.map(section => {
                            return (
                                <li key={section.id}>
                                    <LayoutFormSection
                                        layoutSection={section}
                                        entity={entity}
                                        entityId={entityId}
                                        entityType={entityType}
                                        mode="tile"
                                        answerMode={answerMode}
                                        onAnswerCommitted={onAnswerCommitted}
                                        onFieldChange={onFieldChange}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                );
            }}
        </QueryRenderer>
    );
};
