import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
    ArrowRightOnRectangleIcon,
    CogIcon,
    QuestionMarkCircleIcon,
    TableCellsIcon,
} from "@heroicons/react/24/outline";
import { User } from "@sp-crm/core";
import React from "react";
import { Link } from "react-router-dom";
import { WhiteLabelRestricted } from "./white-label-restricted";

interface UserMenuProps {
    showCsvInterface: () => void;
    currentUser: User;
    editAgencySettingsAllowed: boolean;
    csvDownloadEnabled: boolean;
    navigate: (path: string) => void;
    logout: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

export const UserMenu: React.FC<UserMenuProps> = props => {
    if (props.currentUser) {
        return (
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <MenuButton className="rounded-full p-1 flex items-center text-gray-600 hover:text-gray-900 hover:bg-brand-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500">
                        <span className="sr-only">User options</span>
                        <CogIcon className="h-6 w-6" aria-hidden="true" />
                    </MenuButton>
                </div>
                <MenuItems className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div className="py-1">
                        <MenuItem>
                            {({ focus }) => (
                                <Link
                                    to="/settings"
                                    className={classNames(
                                        focus
                                            ? "bg-brand-100 text-brand-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm",
                                    )}>
                                    <CogIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Settings
                                </Link>
                            )}
                        </MenuItem>
                    </div>
                    {props.csvDownloadEnabled ? (
                        <div className="py-1">
                            <MenuItem>
                                {({ focus }) => (
                                    <a
                                        href="#"
                                        onClick={e => {
                                            e.preventDefault();
                                            props.showCsvInterface();
                                        }}
                                        className={classNames(
                                            focus
                                                ? "bg-brand-100 text-brand-900"
                                                : "text-gray-700",
                                            "group flex items-center px-4 py-2 text-sm",
                                        )}>
                                        <TableCellsIcon
                                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                        CSV Export
                                    </a>
                                )}
                            </MenuItem>
                        </div>
                    ) : null}
                    <WhiteLabelRestricted>
                        <div className="py-1">
                            <MenuItem>
                                {({ focus }) => (
                                    <a
                                        href="https://docs.seniorplace.io/"
                                        target="_blank"
                                        className={classNames(
                                            focus
                                                ? "bg-brand-100 text-brand-900"
                                                : "text-gray-700",
                                            "group flex items-center px-4 py-2 text-sm",
                                        )}
                                        rel="noreferrer">
                                        <QuestionMarkCircleIcon
                                            className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                        Help
                                    </a>
                                )}
                            </MenuItem>
                        </div>
                    </WhiteLabelRestricted>
                    <div className="py-1">
                        <MenuItem>
                            {({ focus }) => (
                                <a
                                    href="#"
                                    onClick={e => {
                                        e.preventDefault();
                                        props.logout();
                                    }}
                                    className={classNames(
                                        focus
                                            ? "bg-brand-100 text-brand-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm",
                                    )}>
                                    <ArrowRightOnRectangleIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                    />
                                    Log out
                                </a>
                            )}
                        </MenuItem>
                    </div>
                </MenuItems>
            </Menu>
        );
    }
    return <span />;
};
