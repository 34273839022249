import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { CommunityRelationshipType } from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";

import { defaultLinkStyle } from "components/ui/link";
import React from "react";
import { ClientCommunityRelationship } from "../types";

interface CommunityCardShortlistExperienceProps {
    clientCommunityRelationship: ClientCommunityRelationship;
}

export const CommunityCardShortlistExperience: React.FC<
    CommunityCardShortlistExperienceProps
> = props => {
    const { privateNote, publicNote, updateRelationship, relationship } =
        props.clientCommunityRelationship;
    const [isPrivateNoteVisible, setIsPrivateNoteVisible] = React.useState(!!privateNote);
    const showPrivateNotes = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            setIsPrivateNoteVisible(true);
        },
        [],
    );
    const savePublicNote = React.useCallback(
        (newNote: string) => {
            updateRelationship({
                action: "updateNote",
                note: newNote,
                noteType: "public",
            });
        },
        [updateRelationship],
    );
    const savePrivateNote = React.useCallback(
        (newNote: string) => {
            updateRelationship({
                action: "updateNote",
                note: newNote,
                noteType: "private",
            });
        },
        [updateRelationship],
    );
    return (
        <>
            <div className="p-2 lg:p-6">
                <AutosavingInput
                    multiLine={true}
                    onCommit={savePublicNote}
                    initial={publicNote ?? ""}
                    disabled={relationship !== CommunityRelationshipType.featured}
                    label={
                        relationship === CommunityRelationshipType.featured
                            ? "Notes (shared with family)"
                            : "Notes (add to family view to edit notes)"
                    }
                />
            </div>
            <div className="p-2 lg:p-6">
                {isPrivateNoteVisible ? (
                    <AutosavingInput
                        multiLine={true}
                        onCommit={savePrivateNote}
                        initial={privateNote ?? ""}
                        label={"Internal notes (not shared)"}
                    />
                ) : (
                    <a href="#" className={defaultLinkStyle} onClick={showPrivateNotes}>
                        Add internal notes
                    </a>
                )}
            </div>
            <div className="-mt-px flex divide-x divide-gray-200 cursor-pointer">
                <div className="flex w-0 flex-1">
                    <a
                        onClick={e => {
                            e.preventDefault();
                            updateRelationship({
                                action: "removeRelationship",
                            });
                        }}
                        className="relative inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-brand-600 bg-brand-100 hover:bg-gray-100 hover:text-gray-800 twoverride">
                        <CheckCircleIcon className="w-4 h-4" />
                        <span className="ml-3">Potential match</span>
                    </a>
                </div>
                <div className="-ml-px flex w-0 flex-1">
                    {relationship === CommunityRelationshipType.featured ? (
                        <a
                            onClick={e => {
                                e.preventDefault();
                                updateRelationship({
                                    action: "updateRelationship",
                                    relationship: CommunityRelationshipType.matched,
                                });
                            }}
                            className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium bg-green-100 text-green-600 hover:bg-gray-100 hover:text-gray-800 twoverride group">
                            <CheckCircleIcon className="w-4 h-4 text-green-800 group-hover:text-grey-800" />
                            <span className="ml-3">In family view</span>
                        </a>
                    ) : (
                        <a
                            onClick={e => {
                                e.preventDefault();
                                updateRelationship({
                                    action: "updateRelationship",
                                    relationship: CommunityRelationshipType.featured,
                                });
                            }}
                            className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 twoverride">
                            <span className="ml-3">Show in family view</span>
                        </a>
                    )}
                </div>
            </div>
        </>
    );
};
