import {
    AnswerEntityId,
    IAnswer,
    LayoutSectionParentKey,
    QuestionSource,
} from "@sp-crm/core";
import { Col, Row } from "components/shared/grid";
import React from "react";
import { EntityLayoutContainer } from "./entity-layout-container";

interface EntityTabProps {
    columns: LayoutSectionParentKey[];
    entity: unknown;
    entityId: AnswerEntityId;
    entityType: string;
    answerMode: QuestionSource;
    onAnswerCommitted: (answerEntityId: AnswerEntityId, answer: IAnswer) => void;
    onFieldChange: (field: string, value: string | boolean) => void;
}

export const EntityTab: React.FC<EntityTabProps> = props => {
    const {
        columns,
        entity,
        entityId,
        entityType,
        answerMode,
        onAnswerCommitted,
        onFieldChange,
    } = props;
    return (
        <Row type="flex" className="collapsible">
            {columns.map(column => {
                return (
                    <Col key={column} className="full-width">
                        <EntityLayoutContainer
                            containerKey={column}
                            entity={entity}
                            entityId={entityId}
                            entityType={entityType}
                            answerMode={answerMode}
                            onAnswerCommitted={onAnswerCommitted}
                            onFieldChange={onFieldChange}
                        />
                    </Col>
                );
            })}
        </Row>
    );
};
