import { CommunityId, ContactId, ReferenceContactId } from "@sp-crm/core";
import {
    AdvancedSearchResults,
    RenderRowActionParams,
} from "components/advanced-search/advanced-search-results";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { SearchInputDebounced } from "components/ui/search-input-debounced";
import { SecondaryButton } from "components/ui/secondary-button";
import { produce } from "immer";
import React, { useState } from "react";
import { useRegionId } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    FieldConditionOperator,
    useGetEntitiesQuery,
    usePromoteCommunityContactMutation,
} from "../../../generated/graphql";

interface ReferenceCommunityContactPickerProps {
    onSelect: (contact: ReferenceContactId, community: CommunityId) => void;
}

export const ReferenceCommunityContactPicker: React.FC<
    ReferenceCommunityContactPickerProps
> = props => {
    const { onSelect } = props;

    const regionId = useRegionId();

    const initialSearchRequest: AdvancedSearchRequest = {
        entityType: AdvancedSearchEntityType.CommunityContact,
        select: [
            { title: "Name", fieldName: "contact.name" },
            {
                title: "Community",
                fieldName: "community.name",
            },
        ].filter(s => !!s),
        sort: { column: "contact.name", ascending: true },
        condition: {
            nodeType: AdvancedSearchConditionNodeType.And,
            children: [
                {
                    nodeType: AdvancedSearchConditionNodeType.Or,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                            fieldName: "contact",
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "name",
                                    operator: FieldConditionOperator.StringContains,
                                    textValue: "",
                                },
                            ],
                        },
                        {
                            nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                            fieldName: "community",
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "name",
                                    operator: FieldConditionOperator.StringContains,
                                    textValue: "",
                                },
                            ],
                        },
                    ],
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                    fieldName: "community",
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                            fieldName: "regionId",
                            operator: FieldConditionOperator.StringEquals,
                            textValue: regionId,
                        },
                    ],
                },
            ],
        },
    };

    const [searchRequest, setSearchRequest] =
        useState<AdvancedSearchRequest>(initialSearchRequest);

    const handleSearchInput = React.useCallback(
        (value: string) => {
            setSearchRequest(prev =>
                produce(prev, draft => {
                    draft.condition.children[0].children[0].children[0].textValue = value;
                    draft.condition.children[0].children[1].children[0].textValue = value;
                }),
            );
        },
        [setSearchRequest],
    );

    const getEntitiesQuery = useGetEntitiesQuery({}, stableQueryOptions());
    const promoteCommunityContactMutation = usePromoteCommunityContactMutation();

    const handleSelect = React.useCallback(
        async (params: RenderRowActionParams) => {
            const contactId = params.record.contactId as ContactId;
            const communityId = params.record.communityId as CommunityId;
            const result = await promoteCommunityContactMutation.mutateAsync({
                regionId,
                contactId,
                communityId,
            });

            if (result.promoteCommunityContact) {
                onSelect(
                    result.promoteCommunityContact.referenceContact.id,
                    result.promoteCommunityContact.communityId,
                );
            }
        },
        [onSelect, promoteCommunityContactMutation, regionId],
    );

    const renderRowAction = React.useCallback(
        (params: RenderRowActionParams) => {
            return (
                <SecondaryButton
                    className="text-sm"
                    onClick={e => {
                        e.preventDefault();
                        handleSelect(params);
                    }}>
                    Select
                </SecondaryButton>
            );
        },
        [handleSelect],
    );

    return (
        <QueryRenderer
            query={getEntitiesQuery}
            name="ReferenceCommunityContactPicker.getEntities">
            {data => (
                <div>
                    <SearchInputDebounced
                        initial=""
                        onChange={handleSearchInput}
                        placeholder="Search for contact"
                        autoFocus
                    />
                    <AdvancedSearchResults
                        request={searchRequest}
                        entityMetadataList={data.getEntities}
                        onChangeRequest={setSearchRequest}
                        renderRowAction={renderRowAction}
                    />
                </div>
            )}
        </QueryRenderer>
    );
};
