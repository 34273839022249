import { EmailTemplateId, EmailTemplateType, FileId } from "@sp-crm/core";
import { Editor, PlacementEditor } from "components/editor/editor";
import { Template } from "components/editor/template";
import { templateFromRequest, upgradeTemplate } from "components/editor/upgrade-template";
import { Checkbox } from "components/ui/checkbox";
import { ErrorMessage } from "components/ui/error-message";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import {
    CreateOrUpdateTemplateMutationVariables,
    DeleteTemplateMutationVariables,
    GetTemplateQuery,
    useCreateOrUpdateTemplateMutation,
    useDeleteTemplateMutation,
    useGetTemplateQuery,
} from "generated/graphql";
import React, { MutableRefObject, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { TemplateAttachments } from "./template-attachments";
import { templatePlaceholderTypes } from "./template-helpers";

interface Props {
    canShareTemplates: boolean;
    templateId: EmailTemplateId;
}

export const TemplateEdit: React.FC<Props> = props => {
    const query = useGetTemplateQuery({ id: props.templateId });
    if (query.isLoading) {
        return <Spinner />;
    }
    if (query.isError) {
        return (
            <ErrorMessage component="Template Edit">
                <pre>{JSON.stringify(query.error, null, 2)}</pre>
            </ErrorMessage>
        );
    }
    if (!query.data) {
        return <Spinner />;
    }

    const parsedTemplate = upgradeTemplate(templateFromRequest(query.data.getTemplate));
    return (
        <TemplateEditInternal
            {...props}
            parsedTemplate={parsedTemplate}
            reload={query.refetch}
            template={query.data.getTemplate}
        />
    );
};

interface InternalProps extends Props {
    template: GetTemplateQuery["getTemplate"];
    parsedTemplate: Template;
    reload: () => void;
}

const TemplateEditInternal: React.FC<InternalProps> = props => {
    const { canShareTemplates, templateId, template, parsedTemplate, reload } = props;
    const query = useCreateOrUpdateTemplateMutation();
    const deleteQuery = useDeleteTemplateMutation();
    const router = useHistory();
    const [description, setDescription] = React.useState(
        template.template.description ?? "",
    );
    const [isShared, setIsShared] = React.useState(template.template.isShared);
    const [isDefault, setIsDefault] = React.useState(template.isDefaultSignature);
    const [subject, setSubject] = React.useState(template.template.subject || "");

    const [signatureOrTemplate, setSignatureOrTemplate] =
        React.useState<EmailTemplateType>(
            props.template.template.type as EmailTemplateType,
        );
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [attachmentFileIds, setAttachmentFileIds] = React.useState<FileId[]>(
        template.template.attachments ?? [],
    );
    const handleRadio = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSignatureOrTemplate(e.target.value as EmailTemplateType);
        },
        [setSignatureOrTemplate],
    );
    const editorRef = React.useRef() as MutableRefObject<PlacementEditor>;

    const submit = () => {
        setIsSubmitting(true);
        (async () => {
            const payload: CreateOrUpdateTemplateMutationVariables = {
                template: {
                    id: templateId,
                    isDefaultSignature: signatureOrTemplate === "signature" && isDefault,
                    isShared: isShared && signatureOrTemplate === "insertable",
                    isSignature: signatureOrTemplate === "signature",
                    type: signatureOrTemplate,
                    subject: signatureOrTemplate === "insertable" ? subject : null,
                    description,
                    body: editorRef.current.getContent(),
                    migrationVersion: 1,
                    attachments:
                        signatureOrTemplate === "insertable" ? attachmentFileIds : null,
                },
            };
            await query.mutateAsync(payload);
            reload();
            router.push(`/settings/templates`);
        })();
    };
    const startDelete = useCallback(() => {
        setIsSubmitting(true);
        (async () => {
            const shouldDelete = await fancyConfirm(
                "Really delete?",
                "Really delete this template?",
                "Yes, delete",
                "Cancel",
            );
            if (shouldDelete) {
                const payload: DeleteTemplateMutationVariables = { id: templateId };
                await deleteQuery.mutateAsync(payload);
                reload();
                router.push(`/settings/templates`);
            } else {
                setIsSubmitting(false);
            }
        })();
    }, [setIsSubmitting, templateId, deleteQuery, reload, router]);

    const handleSubject = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSubject(e.target.value);
        },
        [setSubject],
    );

    return (
        <div className="space-y-4">
            {!template.isEditable ? (
                <InlineBanner type="info">
                    This template cannot be edited because it was shared with you from a
                    different user ({template.author.email}).
                </InlineBanner>
            ) : null}
            <h3 className="text-xl mb-2">Edit template</h3>
            <Input
                label="Title"
                value={description}
                onChange={e => setDescription(e.target.value)}
                disabled={!template.isEditable}
            />
            {signatureOrTemplate === "insertable" ? (
                <Input label="Subject" value={subject} onChange={handleSubject} />
            ) : null}
            <Editor
                ref={editorRef}
                initialContent={parsedTemplate.body ?? ""}
                placeholderTypes={templatePlaceholderTypes}
            />
            {signatureOrTemplate === "insertable" ? (
                <TemplateAttachments
                    attachmentIds={attachmentFileIds}
                    onAttachmentIdsChanged={setAttachmentFileIds}
                />
            ) : null}
            <div className="bg-white rounded-md -space-y-px">
                <label
                    className={
                        "rounded-tl-md rounded-tr-md relative border p-4 flex cursor-pointer focus:outline-none " +
                        (signatureOrTemplate === "insertable"
                            ? "bg-brand-100 border-brand-200 z-10"
                            : "border-gray-200")
                    }>
                    <input
                        onChange={handleRadio}
                        type="radio"
                        name="sinatureOrTemplate"
                        value="insertable"
                        className={
                            "h-4 w-4 mt-0.5 form-radio cursor-pointer text-brand-600 border-gray-300 focus:ring-brand-500"
                        }
                        checked={signatureOrTemplate === "insertable"}
                        disabled={!template.isEditable}
                    />
                    <div className={"ml-3 flex flex-col"}>
                        <span
                            className={
                                "block text-sm font-medium " +
                                (signatureOrTemplate === "insertable"
                                    ? "text-brand-800"
                                    : "text-gray-900")
                            }>
                            Template
                        </span>
                        <span
                            className={
                                "block text-sm " +
                                (signatureOrTemplate === "insertable"
                                    ? "text-brand-700"
                                    : "text-gray-500")
                            }>
                            Templates can be inserted into an email using the{" "}
                            <em>Insert Template</em> menu while composing an email.
                        </span>
                    </div>
                </label>

                <label
                    className={
                        "rounded-bl-md rounded-br-md relative border p-4 flex cursor-pointer focus:outline-none " +
                        (signatureOrTemplate === "signature"
                            ? "bg-brand-100 border-brand-200 z-10"
                            : "border-gray-200")
                    }>
                    <input
                        onChange={handleRadio}
                        type="radio"
                        name="sinatureOrTemplate"
                        value="signature"
                        className={
                            "h-4 w-4 mt-0.5 form-radio cursor-pointer text-brand-600 border-gray-300 focus:ring-brand-500"
                        }
                        checked={signatureOrTemplate === "signature"}
                        disabled={!template.isEditable}
                    />
                    <div className={"ml-3 flex flex-col"}>
                        <span
                            className={
                                "block text-sm font-medium " +
                                (signatureOrTemplate === "signature"
                                    ? "text-brand-800"
                                    : "text-gray-900")
                            }>
                            Signature
                        </span>
                        <span
                            className={
                                "block text-sm " +
                                (signatureOrTemplate === "signature"
                                    ? "text-brand-700"
                                    : "text-gray-500")
                            }>
                            A signature can be added to the bottom of any email.
                            Additionally, you can set a default signature to always be
                            included.
                        </span>
                    </div>
                </label>
            </div>
            {canShareTemplates && signatureOrTemplate === "insertable" ? (
                <Checkbox
                    label={`Share with all users`}
                    onChange={e => setIsShared(e.target.checked)}
                    checked={isShared}
                />
            ) : null}
            {signatureOrTemplate === "signature" ? (
                props.template.isDefaultSignature ? (
                    <div>
                        <em>This is your default signature</em>
                    </div>
                ) : (
                    <Checkbox
                        label={`Set as default signature`}
                        onChange={e => setIsDefault(e.target.checked)}
                        checked={isDefault}
                    />
                )
            ) : null}
            <div className="flex space-x-2">
                <PrimaryButton
                    onClick={submit}
                    disabled={isSubmitting || !template.isEditable}>
                    {isSubmitting ? <Spinner /> : "Save"}
                </PrimaryButton>
                {isSubmitting ? null : (
                    <SecondaryButton
                        disabled={isSubmitting || !template.isEditable}
                        onClick={startDelete}>
                        Delete
                    </SecondaryButton>
                )}
            </div>
        </div>
    );
};
