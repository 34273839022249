import { ClientId, IClient } from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { SecondaryButton } from "components/ui/secondary-button";
import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { getClient } from "store/selectors/selectors";
import { ApplicationState } from "store/state";
import { updateClient, updateClientMultiField } from "../../../../store/actions";
import { tenantSettings } from "../../../../store/selectors/preferences";
import AdditionalClientInfo from "./additional-client-info";
import ClientInfoHeader from "./header";
import PrimaryClientInfo from "./primary-client-info";

type ClientInfoFormOwnProps = {
    clientId: ClientId;
};

type ClientInfoFormPropsFromState = {
    client: IClient;
    showClientFirst: boolean;
    showClientIntakeWeight: boolean;
    showClientIntakeHeight: boolean;
    now: Date;
};

type ClientInfoFormDispatchProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onMultiFieldChange: (newValues: any) => void;
};

type ClientInfoFormProps = ClientInfoFormOwnProps &
    ClientInfoFormPropsFromState &
    ClientInfoFormDispatchProps;

const CONFIRM_DATA_OVERWRITE_TEXT = `This will copy the Best Contact person's details.
    It will overwrite at least some of "Client's" details that
    you've already typed below (this may be exactly what you want
    to do!).`;

const ClientInfoForm: FunctionComponent<ClientInfoFormProps> = props => {
    const sameAsBestContactCheckboxChanged = (newValue: boolean) => {
        if (!newValue) {
            props.onFieldChange("clientSameAsBestContact", newValue);
        } else {
            (async () => {
                const result = await fancyConfirm(
                    "Copy Best Contact Info?",
                    CONFIRM_DATA_OVERWRITE_TEXT,
                    "Yes, I'm sure",
                    "Cancel",
                );
                if (result) {
                    props.onMultiFieldChange({
                        name: props.client.bestContactEntity.name,
                        cellPhone: props.client.bestContactEntity.cellPhone,
                        phone: props.client.bestContactEntity.phone1,
                        email: props.client.bestContactEntity.email1,
                        emailOptOut: props.client.bestContactEntity.email1OptOut,
                        address: props.client.bestContactEntity.address1,
                        city: props.client.bestContactEntity.city,
                        state: props.client.bestContactEntity.state,
                        zip: props.client.bestContactEntity.zip,
                        clientSameAsBestContact: true,
                    });
                }
            })();
        }
    };

    const onCreateNewAdditionalClient = () => props.onFieldChange("additionalClient", {});

    const onDeleteAdditionalClient = async () => {
        const result = await fancyConfirm(
            "Delete additional client?",
            "This will delete all the additional client data",
            "Yes, I'm sure",
            "Cancel",
        );
        if (result) {
            props.onFieldChange("additionalClient", null);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const onAdditionalClientFieldChange = (fieldName: string, newValue: any) => {
        props.onFieldChange(`additionalClient.${fieldName}`, newValue);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    const onAdditionalClientMultiFieldChange = (newValues: any) => {
        const additionalClientNewValues = {};
        for (const [key, value] of Object.entries(newValues)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            (additionalClientNewValues as any)[`additionalClient.${key}`] = value;
        }

        props.onMultiFieldChange(additionalClientNewValues);
    };

    const hasAdditionalClient = !!props.client.additionalClient;

    const clientBasicInfo = {
        id: props.client.id,
        name: props.client.name,
        preferredName: props.client.preferredName,
        email: props.client.email,
        phone: props.client.phone,
        cellPhone: props.client.cellPhone,
        birthday: props.client.birthday,
        isBirthdayFake: props.client.isBirthdayFake,
        gender: props.client.gender,
        weight: props.client.weight,
        height: props.client.height,
        maritalStatus: props.client.maritalStatus,
    };

    const title = "Client" + (hasAdditionalClient ? "s" : "");

    return (
        <div className="input-form-block">
            <ClientInfoHeader title={title} showIcon isCouple={hasAdditionalClient}>
                {!props.showClientFirst && (
                    <div>
                        <Checkbox
                            onChange={e =>
                                sameAsBestContactCheckboxChanged(e.target.checked)
                            }
                            checked={props.client.clientSameAsBestContact}
                            label="Resident is same as Best Contact"
                        />
                    </div>
                )}
            </ClientInfoHeader>
            <PrimaryClientInfo
                clientBasicInfo={clientBasicInfo}
                clientSameAsBestContact={props.client.clientSameAsBestContact}
                onFieldChange={props.onFieldChange}
                onMultiFieldChange={props.onMultiFieldChange}
                showClientIntakeHeight={props.showClientIntakeHeight}
                showClientIntakeWeight={props.showClientIntakeWeight}
                now={props.now}
                emailOptOut={props.client.emailOptOut}
                hasAdditionalClient={hasAdditionalClient}
            />
            <div className="mb-5" />
            {hasAdditionalClient ? (
                <AdditionalClientInfo
                    clientInfo={props.client.additionalClient}
                    clientSameAsBestContact={props.client.clientSameAsBestContact}
                    showClientIntakeWeight={props.showClientIntakeWeight}
                    showClientIntakeHeight={props.showClientIntakeHeight}
                    emailOptOut={props.client.emailOptOut}
                    now={props.now}
                    onFieldChange={onAdditionalClientFieldChange}
                    onMultiFieldChange={onAdditionalClientMultiFieldChange}
                    onDeleteAdditionalClient={onDeleteAdditionalClient}
                />
            ) : (
                <SecondaryButton onClick={onCreateNewAdditionalClient}>
                    Add another client
                </SecondaryButton>
            )}
        </div>
    );
};

const mapDispatchToProps = (
    dispatch: Dispatch<Action>,
    ownProps: ClientInfoFormOwnProps,
) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => {
        updateClient(ownProps.clientId, fieldName, newValue, dispatch);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onMultiFieldChange: (newValues: any) => {
        updateClientMultiField(ownProps.clientId, newValues, dispatch);
    },
});

const mapStateToProps = (state: ApplicationState, ownProps: ClientInfoFormOwnProps) => ({
    client: getClient(state, ownProps.clientId),
    showClientFirst: tenantSettings(state).showClientFirst,
    showClientIntakeWeight: tenantSettings(state).showClientIntakeWeight,
    showClientIntakeHeight: tenantSettings(state).showClientIntakeHeight,
    now: state.time.now,
});

export default connect<
    ClientInfoFormPropsFromState,
    ClientInfoFormDispatchProps,
    ClientInfoFormOwnProps
>(
    mapStateToProps,
    mapDispatchToProps,
)(ClientInfoForm);
