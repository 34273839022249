import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import React from "react";
import { secondaryClasses } from "./secondary-button";

interface InlineMenuProps {
    options: { key: string; label: string; action: () => void }[];
    anchor?: "left" | "right";
    children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

const anchors = {
    right: "origin-top-right right-0",
    left: "origin-top-left left-0",
} as const;

export const InlineMenu: React.FC<InlineMenuProps> = props => {
    const anchor = props.anchor || "right";
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton className={secondaryClasses}>{props.children}</MenuButton>
            </div>
            <MenuItems
                className={`z-1300 absolute ${anchors[anchor]} mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}>
                <div className="py-1">
                    {props.options.map(option => (
                        <MenuItem key={option.key}>
                            {({ focus }) => (
                                <div
                                    onClick={option.action}
                                    className={classNames(
                                        focus
                                            ? "bg-brand-100 text-brand-900"
                                            : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm",
                                    )}>
                                    {option.label}
                                </div>
                            )}
                        </MenuItem>
                    ))}
                </div>
            </MenuItems>
        </Menu>
    );
};
