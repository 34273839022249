import { TrashIcon } from "@heroicons/react/24/outline";
import {
    ActionType,
    AnnotationType,
    InteractionType,
    QuestionCategories,
    QuestionId,
    QuestionType,
} from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import { invalidateLayoutQueries } from "components/layout/layout-query-helpers";
import { Radio, RadioOption } from "components/shared/radio";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { Input } from "components/ui/input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import {
    BridgeEntityResult,
    BridgeQuestionInput,
    UpsertEntityQuestionMutation,
    useDeleteQuestionMutation,
    useUpsertEntityQuestionMutation,
} from "generated/graphql";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useRegionsWithPermission } from "store/selectors/hooks";
import { QuestionAnnotationInput } from "./question-annotation-input";
import {
    QuestionInteractionInput,
    getInitialInteraction,
} from "./question-interaction-input";
import { QuestionKeyInput } from "./question-key-input";
import { QuestionPromptInput } from "./question-prompt-input";
import { QuestionRegionInput } from "./question-region-input";

function titleToSlug(title: string) {
    return title
        .trim()
        .replace(/\s+/g, "_")
        .replace(/[^a-zA-Z0-9_]/g, "");
}

const questionTypeOptions: RadioOption[] = [
    {
        key: QuestionType[QuestionType.text],
        text: "Text / Notes",
        helpText:
            'One-line or multi-line text field. Example: "Reason for Seeking Supportive Housing"',
    },
    {
        key: QuestionType[QuestionType.nary],
        text: "Multiple Options (choose one or many)",
        helpText:
            "Select one or more options from a predefined list. Example: Room types available",
    },
    {
        key: QuestionType[QuestionType.binary],
        text: "Single Checkbox Option (Yes/No)",
        helpText: "Example: Pets allowed / Has a pet",
    },
    {
        key: QuestionType[QuestionType.range],
        text: "Range (low and high values)",
        helpText:
            "Low to high range of numbers or dollars. Example: Price range for room",
    },
    {
        key: QuestionType[QuestionType.number],
        text: "Number only",
        helpText: "Any numerical value. Example: Number of beds",
    },
    {
        key: QuestionType[QuestionType.currency],
        text: "Dollar amount only",
        helpText: "Single dollar value. Example: Shared Room Rate",
    },
    {
        key: QuestionType[QuestionType.date],
        text: "Date",
        helpText: "Example: Community opened since",
    },
    {
        key: QuestionType[QuestionType.calendarDateNoYear],
        text: "Date (without year)",
        helpText: "Example: Birthday or anniversary",
    },
    {
        key: QuestionType[QuestionType.user],
        text: "Employee",
        helpText: "Employee at your company",
    },
];

interface EntityQuestionEditorProps {
    entityMetadata: BridgeEntityResult;
    questionId: QuestionId;
    onCancel: () => void;
    onSaved: (question: UpsertEntityQuestionMutation["upsertEntityQuestion"]) => void;
    onDeleted: (questionId: QuestionId) => void;
}

export const EntityQuestionEditor: React.FC<EntityQuestionEditorProps> = props => {
    const { entityMetadata, questionId, onCancel, onSaved, onDeleted } = props;

    const regionsWithUpdateQuestion = useRegionsWithPermission(ActionType.UpdateQuestion);

    const queryClient = useQueryClient();

    const makeNewQuestion = useCallback((): BridgeQuestionInput => {
        return {
            id: questionId,
            key: questionId,
            title: "",
            questionType: QuestionType[QuestionType.text],
            interaction: InteractionType[InteractionType.singleline],
            annotation: AnnotationType[AnnotationType.none],
            category: QuestionCategories[QuestionCategories.flexible],
            order: 0,
            prompts: [],
            regions: regionsWithUpdateQuestion.map(r => r.id),
        };
    }, [questionId, regionsWithUpdateQuestion]);

    const existingQuestion = entityMetadata.questions.find(q => q.id === questionId);
    const initialQuestion: BridgeQuestionInput = React.useMemo(
        () =>
            existingQuestion
                ? {
                      id: existingQuestion.id,
                      key: existingQuestion.key,
                      title: existingQuestion.title,
                      questionType:
                          existingQuestion.questionType ||
                          QuestionType[QuestionType.text],
                      interaction:
                          existingQuestion.interaction ||
                          InteractionType[InteractionType.none],
                      annotation:
                          existingQuestion.annotation ||
                          AnnotationType[AnnotationType.none],
                      category:
                          existingQuestion.category ||
                          QuestionCategories[QuestionCategories.flexible],
                      order: existingQuestion.order || 0,
                      prompts: (existingQuestion.prompts || []).map(p => ({
                          id: p.id,
                          key: p.key,
                          title: p.title,
                          order: p.order,
                      })),
                      regions: existingQuestion.regions,
                  }
                : makeNewQuestion(),
        [existingQuestion, makeNewQuestion],
    );

    const [question, setQuestion] = useState<BridgeQuestionInput>(initialQuestion);
    const autoGenerateKey = !existingQuestion;
    const editable = !existingQuestion || existingQuestion.editable;

    const handleTitleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newTitle = e.target.value;

            setQuestion(q => {
                let newKey = q.key;
                if (autoGenerateKey) {
                    const suffix = titleToSlug(newTitle);

                    newKey = suffix ? `Question_${suffix}` : q.id;
                }
                return {
                    ...q,
                    key: newKey,
                    title: newTitle,
                };
            });
        },
        [setQuestion, autoGenerateKey],
    );

    const handleTypeChange = useCallback(
        (value: string) => {
            setQuestion(q => ({
                ...q,
                questionType: value,
                interaction:
                    value === initialQuestion.questionType
                        ? initialQuestion.interaction
                        : getInitialInteraction(value),
            }));
        },
        [initialQuestion, setQuestion],
    );

    const handleCancel = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();
            onCancel();
        },
        [onCancel],
    );

    const deleteQuestionMutation = useDeleteQuestionMutation();

    const handleDelete = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e && e.preventDefault && e.preventDefault();

            const result = await fancyConfirm(
                "Are you sure you want to delete this question?",
                "This will cause this question (and all corresponding answers) to disappear from every existing record.",
                "Delete",
                "Cancel",
            );

            if (result) {
                await deleteQuestionMutation.mutateAsync({
                    questionId,
                });
                onDeleted(questionId);
            }
        },
        [questionId, deleteQuestionMutation, onDeleted],
    );

    const upsertQuestionMutation = useUpsertEntityQuestionMutation({
        onSuccess: () => {
            invalidateLayoutQueries(queryClient);
        },
    });

    const handleSave = useCallback(
        async (e: React.FormEvent) => {
            e && e.preventDefault && e.preventDefault();

            const response = await upsertQuestionMutation.mutateAsync({
                entityType: entityMetadata.name,
                question: {
                    ...question,
                    interaction:
                        question.interaction || InteractionType[InteractionType.none],
                    annotation:
                        question.annotation || AnnotationType[AnnotationType.none],
                    category:
                        question.category || QuestionCategories[QuestionCategories.type],
                    order: question.order || 0,
                    questionType:
                        question.questionType || QuestionType[QuestionType.text],
                    title: question.title || `Untitled field ${question.id}`,
                    prompts: (question.prompts || [])
                        .filter(p => !!p.title.trim())
                        .map(p => {
                            const oldPrompts = existingQuestion?.prompts || [];

                            if (!oldPrompts.some(op => op.id === p.id)) {
                                const suffix = titleToSlug(p.title);
                                return {
                                    ...p,
                                    key: `Option_${suffix}`,
                                };
                            }

                            return p;
                        }),
                },
            });
            onSaved(response.upsertEntityQuestion);
        },
        [
            entityMetadata,
            upsertQuestionMutation,
            question,
            onSaved,
            existingQuestion?.prompts,
        ],
    );

    const handleTitleKeyPress = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
                handleSave(e);
            }
        },
        [handleSave],
    );

    return (
        <div>
            <form onSubmit={handleSave} className="space-y-4">
                {!editable ? (
                    <InlineBanner type="info">
                        This field requires administrative access to additional Senior
                        Place regions to make changes. Contact your agency administrator
                        or <SupportEmailLink /> to request changes.
                    </InlineBanner>
                ) : null}
                <QuestionKeyInput
                    question={question}
                    onChange={setQuestion}
                    editable={!!existingQuestion?.editable}
                />
                <Input
                    label="Title"
                    value={question.title}
                    onChange={handleTitleChange}
                    onKeyPress={handleTitleKeyPress}
                    required
                    disabled={!editable}
                />
                <Radio
                    options={questionTypeOptions}
                    value={question.questionType}
                    label="Field type"
                    onChange={handleTypeChange}
                    disabled={!editable}
                />
                <QuestionInteractionInput
                    question={question}
                    onChange={setQuestion}
                    editable={editable}
                />
                <QuestionAnnotationInput
                    question={question}
                    onChange={setQuestion}
                    editable={editable}
                />
                <QuestionPromptInput
                    question={question}
                    onChange={setQuestion}
                    editable={editable}
                />
                <QuestionRegionInput
                    question={question}
                    onChange={setQuestion}
                    editable={editable}
                />
                <div className="flex space-x-2">
                    {editable ? <PrimaryButton type="submit">Save</PrimaryButton> : null}
                    <SecondaryButton onClick={handleCancel}>
                        {editable ? `Cancel` : `Close`}
                    </SecondaryButton>
                    {editable ? (
                        <SecondaryButton
                            onClick={handleDelete}
                            className="flex items-center text-red-600 space-x-1">
                            <TrashIcon className="w-4 h-4" />
                            <span>Delete</span>
                        </SecondaryButton>
                    ) : null}
                </div>
                {upsertQuestionMutation.isLoading ? <Spinner /> : null}
            </form>
        </div>
    );
};
