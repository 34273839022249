import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import {
    AnnotationType,
    IPrompt,
    IQuestion,
    InteractionType,
    Perspective,
    PromptId,
    QuestionCategories,
    emptyPrompt,
    genEntityId,
    isCustomizableSection,
} from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { Input } from "components/ui/input";
import { SecondaryButton } from "components/ui/secondary-button";
import * as React from "react";

// eslint-disable-next-line @typescript-eslint/ban-types
type PromptUpdateCallback = <T extends {}>(
    cb: (q: IPrompt, val: T) => void,
) => (val: T) => void;

interface Props {
    question: IQuestion;
    // eslint-disable-next-line @typescript-eslint/ban-types -- eslintintroduction
    updateQuestion: <T extends {}>(
        cb: (q: IQuestion, val: T) => void,
    ) => (val: T) => void;
}

export const EditOptions: React.FunctionComponent<Props> = props => {
    const sortedPrompts = props.question.prompts
        .slice()
        .sort((a, b) => a.order - b.order);
    const prompts = sortedPrompts.map((p, i) => {
        const first = i === 0;
        const last = i === sortedPrompts.length - 1;
        const updatePrompt = buildUpdatePrompt(props, p);
        const deleteAction = async () => {
            const result = await fancyConfirm(
                "Delete this option?",
                "This will cause this option to disappear from client intakes and community data. You will lose any data associated with this option.",
                "Yes, remove",
                "Cancel",
            );
            if (result) {
                props.updateQuestion(
                    q => (q.prompts = q.prompts.filter(prompt => prompt.id !== p.id)),
                )(null);
            }
        };
        const moveUp = first
            ? null
            : () => {
                  const order = Math.floor(
                      i === 1
                          ? sortedPrompts[0].order - 1000
                          : (sortedPrompts[i - 2].order + sortedPrompts[i - 1].order) / 2,
                  );
                  updatePrompt(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                      (newPrompt, newOrder: any) => (newPrompt.order = newOrder),
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                  )(order as any);
              };
        const moveDown = last
            ? null
            : () => {
                  const order = Math.floor(
                      i === sortedPrompts.length - 2
                          ? sortedPrompts[i + 1].order + 1000
                          : (sortedPrompts[i + 1].order + sortedPrompts[i + 2].order) / 2,
                  );
                  updatePrompt(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                      (newPrompt, newOrder: any) => (newPrompt.order = newOrder),
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                  )(order as any);
              };
        return (
            <div className="option-row" key={p.id}>
                <EditOption
                    question={props.question}
                    moveUp={moveUp}
                    moveDown={moveDown}
                    delete={deleteAction}
                    updatePrompt={updatePrompt}
                    prompt={p}
                />
            </div>
        );
    });

    const addOption = props.updateQuestion((q: IQuestion) => {
        const order = q.prompts.reduce(
            (highest: number, prompt: IPrompt) => Math.max(highest, prompt.order) + 1,
            0,
        );
        const prompt = emptyPrompt(order, genEntityId<PromptId>());
        prompt.legacyClientAnnotation = q.legacyClientAnnotation !== AnnotationType.none;
        prompt.legacyCommunityAnnotation =
            q.legacyCommunityAnnotation !== AnnotationType.none;
        q.prompts.push(prompt);
    });

    return (
        <div className="input-form-block full-width">
            <h4>
                <b>Multiple Choice Options</b>
            </h4>
            <div className="prompt-edit">
                <div className="headerRow">
                    <div className="flex-row-no-bottom-margin">
                        <div className="full-width column-label">
                            Client label for option
                        </div>
                        <div className="full-width column-label">
                            Community label for option
                        </div>
                        <div className="full-width column-label">
                            Reorder / Add / Remove Options
                        </div>
                    </div>
                </div>
                {prompts}
            </div>
            <div className="flex-row ">
                <div className="full-width"></div>
                <div className="full-width"></div>
                <div className="full-width">
                    <div className="new-option-button">
                        <SecondaryButton type="button" onClick={addOption}>
                            Add new option
                        </SecondaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface EditOptionProps {
    question: IQuestion;
    prompt: IPrompt;
    updatePrompt: PromptUpdateCallback;
    moveUp?: () => void;
    moveDown?: () => void;
    delete: () => void;
}

const EditOption: React.FunctionComponent<EditOptionProps> = props => {
    const additionalNotesLabel = "Allow additional notes";
    return (
        <>
            <div className="flex-row-no-bottom-margin " data-prompt={props.prompt.id}>
                <div className="full-width">
                    {props.question.legacyClientInteraction !== InteractionType.none &&
                        isCustomizableSection(
                            Perspective.Client,
                            props.question.category as QuestionCategories,
                        ) && (
                            <>
                                <div>
                                    <Input
                                        value={props.prompt.legacyClientTitle}
                                        onChange={props.updatePrompt(
                                            (prompt, val) =>
                                                (prompt.legacyClientTitle =
                                                    val.target.value),
                                        )}
                                    />
                                </div>
                                {props.question.legacyClientAnnotation !==
                                    AnnotationType.none && (
                                    <div>
                                        <Checkbox
                                            label={additionalNotesLabel}
                                            checked={props.prompt.legacyClientAnnotation}
                                            onChange={e =>
                                                props.updatePrompt(
                                                    (prompt, val) =>
                                                        (prompt.legacyClientAnnotation =
                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                                            val as any),
                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                                )(e.target.checked as any)
                                            }
                                        />
                                    </div>
                                )}
                            </>
                        )}
                </div>
                <div className="full-width">
                    {props.question.legacyCommunityInteraction !== InteractionType.none &&
                        isCustomizableSection(
                            Perspective.Community,
                            props.question.category as QuestionCategories,
                        ) && (
                            <>
                                <div>
                                    <Input
                                        value={props.prompt.legacyCommunityTitle}
                                        onChange={props.updatePrompt(
                                            (prompt, val) =>
                                                (prompt.legacyCommunityTitle =
                                                    val.target.value),
                                        )}
                                    />
                                </div>{" "}
                                {props.question.legacyCommunityAnnotation !==
                                    AnnotationType.none && (
                                    <div>
                                        <Checkbox
                                            label={additionalNotesLabel}
                                            checked={
                                                props.prompt.legacyCommunityAnnotation
                                            }
                                            onChange={e =>
                                                props.updatePrompt(
                                                    (prompt, val) =>
                                                        (prompt.legacyCommunityAnnotation =
                                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                                            val as any),
                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                                                )(e.target.checked as any)
                                            }
                                        />
                                    </div>
                                )}
                            </>
                        )}
                </div>
                <div className="full-width meta-actions">
                    <div className="meta-actions--action">
                        {props.moveUp && (
                            <button
                                type="button"
                                data-action="moveup"
                                onClick={props.moveUp}
                                className="text-gray-800 rounded-full hover:bg-gray-100">
                                <ArrowUpIcon className="w-4 h-4" />
                            </button>
                        )}
                    </div>
                    <div className="meta-actions--action">
                        {props.moveDown && (
                            <button
                                type="button"
                                data-action="movedown"
                                onClick={props.moveDown}
                                className="text-gray-800 rounded-full hover:bg-gray-100">
                                <ArrowDownIcon className="w-4 h-4" />
                            </button>
                        )}
                    </div>
                    <div className="meta-actions--last-action">
                        <SecondaryButton type="button" onClick={props.delete}>
                            Remove option
                        </SecondaryButton>
                    </div>
                </div>
            </div>
        </>
    );
};

function buildUpdatePrompt(
    props: React.PropsWithChildren<Props>,
    p: IPrompt,
): PromptUpdateCallback {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    return (cb: (p: IPrompt, val: any) => void) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        return props.updateQuestion((q: IQuestion, val: any) => {
            const foundPromptIndex = q.prompts.findIndex(needle => needle.id === p.id);
            if (foundPromptIndex >= 0) {
                const newPrompt = { ...p };
                cb(newPrompt, val);
                q.prompts[foundPromptIndex] = newPrompt;
            }
        });
    };
}
