import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { InlineBanner } from "components/ui/inline-banner";
import { defaultLinkStyle } from "components/ui/link";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import React, { MutableRefObject, Reducer, useReducer } from "react";
import { useSupportEmailAddress } from "store/selectors/branding";
import {
    AdvancedSearchEntityType,
    ResendInvoiceMutationVariables,
    useResendInvoiceMutation,
} from "../../generated/graphql";
import { MessageComposeInline } from "../messages/message-componse-internal";
import { PlacementMessageCompose } from "../messages/types";
import { PrimaryButton } from "../ui/primary-button";
import { ClientInvoice, CommunityInvoice } from "./invoice-helpers";
import { InvoiceRecipientsField } from "./invoice-recipients-field";
import { InvoiceView } from "./invoice-view";

const dismiss = () => 0;
interface Props {
    invoice: ClientInvoice | CommunityInvoice;
    refetch: () => void;
    onCancel: () => void;
}

interface ComponentState {
    state: "open" | "submitting" | "sent";
    errorMessage: string | null;
}
type Action =
    | { type: "submit" }
    | { type: "submitFailure"; error: string | null }
    | { type: "submitSuccess" };

const initialState: ComponentState = {
    state: "open",
    errorMessage: null,
};
const reducer: Reducer<ComponentState, Action> = (p, a) => {
    switch (a.type) {
        case "submit":
            return { ...p, state: "submitting" };
        case "submitSuccess":
            return { ...p, state: "sent", errorMessage: null };
        case "submitFailure":
            return { ...p, state: "open", errorMessage: a.error };
    }
};

export const InvoiceResend: React.FC<Props> = props => {
    const { invoice, onCancel, refetch } = props;

    return invoice.isExternallyManaged ? (
        <InvoiceSendExternal invoice={invoice} onCancel={onCancel} refetch={refetch} />
    ) : (
        <InvoiceSendInternal {...props} />
    );
};

const InvoiceSendInternal: React.FC<Props> = props => {
    const { invoice, refetch, onCancel } = props;
    const [{ state, errorMessage }, dispatch] = useReducer(reducer, initialState);
    const inlineEditorRef =
        React.useRef<PlacementMessageCompose>() as MutableRefObject<PlacementMessageCompose>;
    const mutation = useResendInvoiceMutation();

    const submit = (e: React.FormEvent) => {
        e.preventDefault();
        (async () => {
            dispatch({ type: "submit" });
            try {
                const payload: ResendInvoiceMutationVariables = {
                    invoiceId: invoice.id,
                    message: inlineEditorRef.current.getMessage(),
                };
                await mutation.mutateAsync(payload);
                dispatch({ type: "submitSuccess" });
                refetch();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                dispatch({ type: "submitFailure", error: e.message });
            }
        })();
    };

    const handleCancel = (e: React.MouseEvent) => {
        e.preventDefault();
        onCancel();
    };

    if (state === "sent") {
        return (
            <div className="flex items-center space-x-2">
                <CheckCircleIcon className="w-4 h-4" />
                <span className="block">Sent!</span>
            </div>
        );
    }
    if (state === "open" || state === "submitting") {
        return (
            <div>
                <form onSubmit={submit}>
                    <div className="max-w-3xl">
                        <MessageComposeInline
                            entityId={invoice.clientId}
                            entityType={AdvancedSearchEntityType.Client}
                            onDismiss={dismiss}
                            requestSpecialMessageExperience="invoice"
                            ref={inlineEditorRef}
                        />
                        {errorMessage ? (
                            <div className="bg-red-200 text-red-700 p-4 rounded font-bold mb-2">
                                {errorMessage}
                            </div>
                        ) : null}
                        <div className="flex space-x-2 items-center my-4">
                            <PrimaryButton
                                type="submit"
                                disabled={state === "submitting"}>
                                Send
                            </PrimaryButton>
                            <SecondaryButton onClick={handleCancel}>
                                Cancel
                            </SecondaryButton>
                            {state === "submitting" ? <Spinner /> : null}
                        </div>
                    </div>
                </form>
                <InvoiceView invoice={invoice} />
            </div>
        );
    }

    const exhaustiveCheck: never = state;
    console.warn(`Unhandled state: ${exhaustiveCheck}`);
    return null;
};

type InvoiceSendExternalProps = {
    invoice: ClientInvoice | CommunityInvoice;
    onCancel: () => void;
    refetch: () => void;
};

const InvoiceSendExternal: React.FC<InvoiceSendExternalProps> = props => {
    const { invoice, onCancel, refetch } = props;

    const mutation = useResendInvoiceMutation();

    const [finished, setFinished] = React.useState(false);
    const [recipients, setRecipients] = React.useState<string[]>([]);
    const [sendError, setSendError] = React.useState<string | null>(null);
    const supportEmail = useSupportEmailAddress();

    const handleCancel = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            onCancel();
        },
        [onCancel],
    );

    const handleSubmit = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setSendError(null);
            await mutation.mutateAsync(
                {
                    invoiceId: invoice.id,
                    message: {
                        attachmentIds: [],
                        bccMyself: false,
                        body: "",
                        subject: "",
                        entityId: invoice.clientId,
                        recipients,
                    },
                },
                {
                    onError: (e: Record<string, unknown> | null) => {
                        if (typeof e?.message === "string") {
                            setSendError(e.message);
                        } else {
                            setSendError(
                                `An error occurred while saving the invoice. If this issue persists, please contact ${supportEmail}`,
                            );
                        }
                    },
                },
            );
            refetch();
            setFinished(true);
        },
        [
            mutation,
            recipients,
            refetch,
            invoice.id,
            invoice.clientId,
            setFinished,
            setSendError,
            supportEmail,
        ],
    );

    if (finished) {
        return (
            <InlineBanner type="success">
                The QuickBooks invoice sent successfully.{" "}
                <a
                    target="_blank"
                    rel="noreferrer"
                    className={defaultLinkStyle}
                    href={invoice.externalReference}>
                    View the invoice in QuickBooks
                </a>{" "}
                to see more details.
            </InlineBanner>
        );
    }

    return (
        <div className="space-y-2">
            <InvoiceRecipientsField
                clientId={invoice.clientId}
                onChange={setRecipients}
            />
            <p>
                This will direct QuickBooks to send this invoice to the recipients above,
                using the default invoice email template you have configured in
                QuickBooks.
            </p>
            {sendError ? <InlineBanner type="error">{sendError}</InlineBanner> : null}
            <div className="flex space-x-2 items-center my-4">
                <PrimaryButton onClick={handleSubmit} disabled={mutation.isLoading}>
                    Send
                </PrimaryButton>
                <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
                {mutation.isLoading ? <Spinner /> : null}
            </div>
        </div>
    );
};
