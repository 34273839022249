import {
    AdditionalClientContact,
    ClientId,
    ContactId,
    IAdditionalClient,
    IClient,
    IContact,
    InternalId,
    ReferenceContactId,
} from "@sp-crm/core";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { ClientContactRelationship } from "generated/graphql";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { getClient } from "store/selectors/selectors";
import { ApplicationState } from "store/state";
import {
    addAdditionalClientPhysicianContact,
    deleteAdditionalClientContactPerson,
    updateAdditionalClientContactPerson,
    updateClient,
    updateClientMultiField,
} from "../../../../store/actions";
import { tenantSettings } from "../../../../store/selectors/preferences";
import { ClientContacts } from "../client-contacts";
import EditCareProviderContact from "./edit-contact";
import MultipleClientContacts from "./multiple-clients";

const findContact = (contacts: IContact[], id: string): IContact =>
    contacts.find(contact => contact.id === id);

type CareProvidersOwnProps = {
    clientId: ClientId;
};

type CareProvidersPropsFromState = {
    client: IClient;
    additionalClient: IAdditionalClient;
    showPhysicianContacts: boolean;
};

type CareProvidersDispatchProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onMultiFieldChange: (newValues: any) => void;
    onAddNewContactForAdditionalClient: (
        additionalClientId: InternalId,
        payload: Partial<AdditionalClientContact>,
    ) => Promise<{ client: IClient; newContactId: ContactId } | null>;
    onUpdateContactForAdditionalClient: (
        additionalClientId: InternalId,
        contactId: ContactId,
        contact: AdditionalClientContact,
    ) => void;
    onDeleteContactForAdditionalClient: (
        additionalClientId: InternalId,
        contactId: ContactId,
    ) => void;
};

type CareProvidersProps = CareProvidersOwnProps &
    CareProvidersPropsFromState &
    CareProvidersDispatchProps;

type CareProvidersState = {
    shouldShowEditContactPanel: boolean;
    editingContactId: ContactId | null;
    isEditingContactFromPrimaryClient: boolean;
};

class CareProviders extends Component<CareProvidersProps, CareProvidersState> {
    constructor(props: CareProvidersProps) {
        super(props);
        this.handleSameForBothClientsCheckbox =
            this.handleSameForBothClientsCheckbox.bind(this);

        this.handleAddAdditionalClientContact =
            this.handleAddAdditionalClientContact.bind(this);
        this.handleLinkAdditionalClientContact =
            this.handleLinkAdditionalClientContact.bind(this);
        this.handleDeleteAdditionalClientContact =
            this.handleDeleteAdditionalClientContact.bind(this);
        this.showAdditionalClientEditContactPanel =
            this.showAdditionalClientEditContactPanel.bind(this);
        this.hideEditContactPanel = this.hideEditContactPanel.bind(this);
        this.handleUpdateContact = this.handleUpdateContact.bind(this);

        this.state = {
            shouldShowEditContactPanel: false,
            editingContactId: null,
            isEditingContactFromPrimaryClient: false,
        };
    }

    handleSameForBothClientsCheckbox(newValue: boolean) {
        if (newValue && this.props.additionalClient.physicianContacts.length > 0) {
            (async () => {
                const result = await fancyConfirm(
                    "Use same contacts for both clients?",
                    "This will erase any Additional Client's Physician Contacts registered so far",
                    "Yes, I'm sure",
                    "Cancel",
                );
                if (result) {
                    this.props.onMultiFieldChange({
                        "additionalClient.physicianContactsSameAsPrimaryClient": newValue,
                        "additionalClient.physicianContacts": [],
                    });
                }
            })();
        } else {
            this.props.onFieldChange(
                `additionalClient.physicianContactsSameAsPrimaryClient`,
                newValue,
            );
        }
    }

    handleAddAdditionalClientContact() {
        this.props
            .onAddNewContactForAdditionalClient(this.props.additionalClient.id, {})
            .then(responseData =>
                this.showAdditionalClientEditContactPanel(responseData.newContactId),
            );
    }

    handleLinkAdditionalClientContact(referenceContactId: ReferenceContactId) {
        this.props.onAddNewContactForAdditionalClient(this.props.additionalClient.id, {
            referenceId: referenceContactId,
        });
    }

    handleDeleteAdditionalClientContact(contactId: ContactId) {
        this.props.onDeleteContactForAdditionalClient(
            this.props.additionalClient.id,
            contactId,
        );
    }

    showAdditionalClientEditContactPanel(contactId: ContactId) {
        this.setState({
            shouldShowEditContactPanel: true,
            editingContactId: contactId,
            isEditingContactFromPrimaryClient: false,
        });
    }

    hideEditContactPanel() {
        this.setState({
            shouldShowEditContactPanel: false,
            editingContactId: null,
            isEditingContactFromPrimaryClient: false,
        });
    }

    handleUpdateContact(contactId: ContactId, contact: AdditionalClientContact) {
        this.setState(prevState => ({
            ...prevState,
            editingContact: contact,
        }));
        this.props.onUpdateContactForAdditionalClient(
            this.props.additionalClient.id,
            contactId,
            contact,
        );
    }

    render() {
        if (!this.props.showPhysicianContacts) return null;

        const editingContact = this.loadContact();

        const showSingleClient = !this.props.additionalClient;
        return (
            <>
                {showSingleClient ? (
                    <div className="input-form-block">
                        <ClientContacts
                            clientId={this.props.client.id}
                            relationship={ClientContactRelationship.CareProvider}
                        />
                    </div>
                ) : (
                    <MultipleClientContacts
                        client={this.props.client}
                        additionalClient={this.props.additionalClient}
                        handleSameForBothClientsCheckbox={
                            this.handleSameForBothClientsCheckbox
                        }
                        physicianContactsSameAsPrimaryClient={
                            this.props.additionalClient
                                .physicianContactsSameAsPrimaryClient
                        }
                        onAddNewAdditionalClientContact={
                            this.handleAddAdditionalClientContact
                        }
                        onLinkNewAdditionalClientContact={
                            this.handleLinkAdditionalClientContact
                        }
                        editAdditionalClientContact={
                            this.showAdditionalClientEditContactPanel
                        }
                        deleteAdditionalClientContact={
                            this.handleDeleteAdditionalClientContact
                        }
                    />
                )}

                {this.state.shouldShowEditContactPanel && editingContact ? (
                    <EditCareProviderContact
                        show={true}
                        contact={editingContact}
                        onUpdateContact={this.handleUpdateContact}
                        onCloseEditPanel={this.hideEditContactPanel}
                    />
                ) : null}
            </>
        );
    }

    private loadContact(): IContact | null {
        if (!this.state.editingContactId) {
            return null;
        }
        if (
            this.props.additionalClient &&
            this.props.additionalClient.physicianContacts
        ) {
            const found = findContact(
                this.props.additionalClient.physicianContacts,
                this.state.editingContactId,
            );
            if (found) {
                return found;
            }
        }

        return null;
    }
}

const mapDispatchToProps = (
    dispatch: Dispatch<Action>,
    ownProps: CareProvidersOwnProps,
) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => {
        updateClient(ownProps.clientId, fieldName, newValue, dispatch);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onMultiFieldChange: (newValues: any) => {
        updateClientMultiField(ownProps.clientId, newValues, dispatch);
    },
    onAddNewContactForAdditionalClient: (
        additionalClientId: InternalId,
        payload: Partial<AdditionalClientContact>,
    ) => {
        return addAdditionalClientPhysicianContact(
            ownProps.clientId,
            additionalClientId,
            payload,
            dispatch,
        );
    },
    onUpdateContactForAdditionalClient: (
        additionalClientId: InternalId,
        contactId: ContactId,
        contact: AdditionalClientContact,
    ) => {
        updateAdditionalClientContactPerson(
            ownProps.clientId,
            additionalClientId,
            contactId,
            contact,
            dispatch,
        );
    },
    onDeleteContactForAdditionalClient: (
        additionalClientId: InternalId,
        contactId: ContactId,
    ) => {
        deleteAdditionalClientContactPerson(
            ownProps.clientId,
            additionalClientId,
            contactId,
            dispatch,
        );
    },
});

const mapStateToProps = (state: ApplicationState, ownProps: CareProvidersOwnProps) => {
    const loadedClient = getClient(state, ownProps.clientId);
    return {
        client: loadedClient,
        additionalClient: loadedClient.additionalClient,
        showPhysicianContacts: tenantSettings(state).showPhysicianContacts,
    };
};

export default connect<
    CareProvidersPropsFromState,
    CareProvidersDispatchProps,
    CareProvidersOwnProps
>(
    mapStateToProps,
    mapDispatchToProps,
)(CareProviders);
