import { CommunityId } from "@sp-crm/core";
import { getMarkerIcon } from "components/map/map-common";
import { CommunitySearchMapViewQuery } from "generated/graphql";
import "maplibre-gl/dist/maplibre-gl.css";
import * as React from "react";
import { MapboxEvent, Marker } from "react-map-gl";

export const CommunitySearchMapMarker: React.FC<{
    community: CommunitySearchMapViewQuery["communitySearchMapView"]["communityCoordinates"][0];
    isHovered: boolean;
    selectCommunity?: (c: CommunityId) => void;
}> = props => {
    const { community, isHovered, selectCommunity } = props;
    const onClick = React.useCallback(
        (e: MapboxEvent<MouseEvent>) => {
            e.originalEvent.stopPropagation();
            if (selectCommunity) {
                selectCommunity(community.id);
            }
        },
        [community, selectCommunity],
    );
    if (!location) {
        return null;
    }

    return (
        <Marker
            longitude={community.lng}
            latitude={community.lat}
            anchor="bottom"
            onClick={onClick}>
            <img
                src={getMarkerIcon(community.contractStatus)}
                className={isHovered ? "w-10 h-16" : "w-6 h-10"}
            />
        </Marker>
    );
};

export const CommunitySearchMapCluster: React.FC<{
    cluster: CommunitySearchMapViewQuery["communitySearchMapView"]["communityClusters"][0];
    flyTo: (lng: number, lat: number) => void;
    isHovered: boolean;
}> = props => {
    const { flyTo, cluster, isHovered } = props;
    const onClick = React.useCallback(
        (e: MapboxEvent<MouseEvent>) => {
            e.originalEvent.stopPropagation();
            flyTo(cluster.lng, cluster.lat);
        },
        [cluster, flyTo],
    );
    if (!location) {
        return null;
    }

    return (
        <Marker
            longitude={cluster.lng}
            latitude={cluster.lat}
            anchor="bottom"
            onClick={onClick}>
            <div
                className={`${
                    isHovered ? "w-16 h-16" : "w-10 h-10"
                } rounded-full bg-green-200 border-2 border-green-400 flex items-center justify-center`}>
                <div className="text-sm font-bold text-green-700">{cluster.count}</div>
            </div>
        </Marker>
    );
};
