import { Checkbox } from "components/ui/checkbox";

import { PrimaryButton } from "components/ui/primary-button";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import * as actions from "../../store/actions";
import { EulaState as IEulaState } from "../../store/reducers/eula";

interface EulaPropsFromState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    userPayload: any;
}

interface EulaPropsFromActions {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onSubmit: (formState: EulaState, userPayload: any) => void;
}

interface EulaState {
    checked: boolean;
}

type EulaProps = EulaPropsFromActions & EulaPropsFromState;

class Eula extends React.Component<EulaProps, EulaState> {
    constructor(props: EulaProps) {
        super(props);
        this.state = { checked: false };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    handleSubmit(e: any) {
        e.preventDefault();
        this.props.onSubmit(this.state, this.props.userPayload);
    }

    changeValue(v: boolean): void {
        this.setState(Object.assign({}, this.state, { checked: v }));
    }

    render() {
        const label = (
            <span>
                I have read and agree to both the{" "}
                <a href="http://seniorplace.io/eula/" target="_blank" rel="noreferrer">
                    Terms of Service
                </a>{" "}
                and the{" "}
                <a href="http://seniorplace.io/privacy/" target="_blank" rel="noreferrer">
                    Privacy Policy
                </a>
                .
            </span>
        );
        return (
            <span>
                <div className="page-header row">
                    <div className="page-title">
                        <h2>Terms of Service</h2>
                    </div>
                </div>
                <div className="col main-body full-width">
                    <form onSubmit={e => this.handleSubmit(e)}>
                        <div className="space-y-2">
                            <Checkbox
                                id="agree"
                                checked={this.state.checked}
                                onChange={v => this.changeValue(v.target.checked)}
                                label={label}
                            />
                            <PrimaryButton id="proceed" onClick={this.handleSubmit}>
                                I Agree
                            </PrimaryButton>
                        </div>
                    </form>
                </div>
            </span>
        );
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
function mapStateToProps(state: any): EulaPropsFromState {
    return {
        userPayload: (state.eula as IEulaState).userPayload,
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action>): EulaPropsFromActions {
    return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        onSubmit: (formState: EulaState, userPayload: any) => {
            if (formState.checked) {
                actions.signEula(userPayload, dispatch);
            } else {
                alert(
                    "You must agree to the Terms of Service and Privacy Policy to continue.",
                );
            }
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Eula);
