import { localCalendarDate, Reminder } from "@sp-crm/core";
import { useUpdateTaskMutation } from "generated/graphql";
import moment from "moment";
import React, {
    createContext,
    Fragment,
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useRegionId } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import * as http from "../util/http";

interface ReminderState {
    isReminderEnabled: boolean;
    isReminderVisible: boolean;
    reminders: Reminder[];
}

const initialReminderState: ReminderState = {
    isReminderEnabled: false,
    isReminderVisible: false,
    reminders: [],
};

interface ReminderContextValue extends ReminderState {
    completeTask: (reminder: Reminder) => void;
    setVisible: (visible: boolean | ((oldState: boolean) => void)) => void;
}

export const ReminderContext = createContext<ReminderContextValue>({
    ...initialReminderState,
    completeTask: () => {
        /* nop */
    },
    setVisible: () => {
        /* nop */
    },
});

interface ReminderContextProviderProps {
    children?: React.ReactNode;
}

const ReminderContextProvider: FunctionComponent<ReminderContextProviderProps> = ({
    children,
}) => {
    const isInitial = useRef(true);
    const [timers, setTimers] = useState([]);
    const [isVisible, setVisible] = useState(false);
    const currentRegionId = useRegionId();

    const updateTask = useUpdateTaskMutation();
    const queryClient = useQueryClient();

    const { data: reminders, refetch } = useQuery<Reminder[]>(
        "getReminders",
        async () => {
            if (!currentRegionId) {
                return undefined;
            }
            const localDate = localCalendarDate();
            const res = await http.get<Reminder[]>(
                http.newRequestDescription(),
                `/api/regions/${currentRegionId}/tasks/reminders?date=${localDate.toString()}`,
            );

            const newReminders = res.data.map(reminder => Reminder.load(reminder));

            timers.forEach(timer => clearTimeout(timer));

            const newTimers = newReminders
                .filter(reminder => moment(reminder.when()).isAfter(new Date()))
                .map(reminder => {
                    return setTimeout(
                        () => setVisible(true),
                        moment(reminder.when()).diff(new Date()),
                    );
                });
            setTimers(newTimers);
            return newReminders.filter(reminder => {
                // no reminder if the task due date is after today
                if (moment(reminder.when()).isAfter(new Date(), "day")) {
                    return false;
                }
                return true;
            });
        },
        {
            refetchInterval: 15 * 60 * 1000,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    );

    const completeTask = useCallback(
        async (reminder: Reminder) => {
            await updateTask.mutateAsync({
                taskId: reminder.id,
                params: {
                    completedAt: new Date().toString(),
                    status: "done",
                },
            });
            refetch();
            queryClient.invalidateQueries("getTasks");
            queryClient.invalidateQueries("getTasksForEntity");
        },
        [updateTask, refetch, queryClient],
    );

    useEffect(() => {
        refetch();
    }, [currentRegionId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (reminders === undefined) {
            return;
        }

        if (isInitial.current === true) {
            isInitial.current = false;
            const todayReminders = reminders.filter(
                reminder =>
                    reminder.dueDate && moment(reminder.when()).isSame(new Date(), "day"),
            );
            if (todayReminders.length > 0) {
                setVisible(true);
            }
        }
    }, [reminders]);
    return (
        <ReminderContext.Provider
            value={{
                isReminderEnabled: true,
                isReminderVisible: isVisible,
                reminders: reminders || [],
                completeTask,
                setVisible,
            }}>
            {children}
        </ReminderContext.Provider>
    );
};

export const useReminderContext = () => useContext(ReminderContext);

interface ReminderProviderProps {
    children?: React.ReactNode;
}

export const ReminderProvider: FunctionComponent<ReminderProviderProps> = ({
    children,
}) => {
    const currentUser = useSelector(
        (state: ApplicationState) =>
            state.users.users &&
            state.userState.userId &&
            state.users.users[state.userState.userId],
    );

    const Wrapper =
        currentUser && currentUser.preferences.reminderEnabled
            ? ReminderContextProvider
            : Fragment;

    return <Wrapper>{children}</Wrapper>;
};
