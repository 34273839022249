import React from "react";

interface Props {
    value: number | null | undefined | string;
    type: "error" | "info" | "highlight";
}

const styles = {
    error: "bg-red-100 text-red-600",
    info: "bg-gray-200 text-gray-700 ",
    highlight: "bg-brand-200 text-brand-700",
};

export const Badge: React.FC<Props> = props => {
    const { value, type } = props;
    if (value === undefined || value === null || value === "") return null;
    return (
        <div
            className={`font-bold text-xs rounded-full ${styles[type]} inline px-2 py-0`}>
            {value}
        </div>
    );
};
