import { ActionType, TenantRegion, UserId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContentFull, ContentHeader } from "components/layout";
import { fancyConfirm } from "components/ui/fancy-confirm";
import { InlineBanner } from "components/ui/inline-banner";
import { defaultLinkStyle } from "components/ui/link";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { SelectPro } from "components/ui/select-pro";
import {
    UserManagementGetUserQuery,
    UserType,
    useUserManagementDisableUserMutation,
    useUserManagementEnableUserMutation,
    useUserManagementGetRolesQuery,
    useUserManagementGetUserQuery,
    useUserManagementHideUserMutation,
    useUserManagementShowUserMutation,
    useUserManagementUpdatePermissionMutation,
} from "generated/graphql";
import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { useRegionsWithPermission } from "store/selectors/hooks";
import { ApplicationState } from "store/state";
import { stableQueryOptions } from "util/requests";
import { CustomRolesBanner } from "./custom-roles-banner";
import { displayforUser, invalidateUserList, statusForUser } from "./helper";
import { ManageUsersPermissionsLevels } from "./manage-users-permissions";

export const ManageUsersEdit: React.FC<unknown> = () => {
    const { params, path } = useRouteMatch<{ userId: UserId }>();
    const user = useUserManagementGetUserQuery({ userId: params.userId });
    const regionsWithModifyAccess = useRegionsWithPermission(
        ActionType.SelfManageModifyUser,
    );
    const disableMutation = useUserManagementDisableUserMutation();
    const enableMutation = useUserManagementEnableUserMutation();
    const showMutation = useUserManagementShowUserMutation();
    const hideMutation = useUserManagementHideUserMutation();
    const refetch = React.useCallback(() => {
        user.refetch();
        invalidateUserList();
    }, [user]);
    const disableUser = React.useCallback(async () => {
        const proceed = await fancyConfirm(
            "Deactivate account",
            "Are you sure you want to deactivate this account?",
            "Deactivate account",
            "Cancel",
        );
        if (proceed) {
            await disableMutation.mutateAsync({ userId: params.userId });
            refetch();
        }
    }, [disableMutation, params.userId, refetch]);
    const enableUser = React.useCallback(async () => {
        await enableMutation.mutateAsync({ userId: params.userId });
        refetch();
    }, [enableMutation, params.userId, refetch]);
    const hideUser = React.useCallback(async () => {
        await hideMutation.mutateAsync({ userId: params.userId });
        refetch();
    }, [hideMutation, params.userId, refetch]);
    const showUser = React.useCallback(async () => {
        await showMutation.mutateAsync({ userId: params.userId });
        refetch();
    }, [showMutation, params.userId, refetch]);

    return (
        <ContentFull>
            <QueryRenderer name="Manage user" query={user}>
                {data => (
                    <>
                        <ContentHeader>
                            Edit account {displayforUser(data.userManagementGetUser.user)}
                        </ContentHeader>
                        <div className="space-y-6">
                            <div>
                                Status: {statusForUser(data.userManagementGetUser.user)}
                            </div>
                            {regionsWithModifyAccess.map(r => (
                                <ManageUsersEditRegion
                                    refetch={refetch}
                                    key={r.key}
                                    region={r}
                                    user={data.userManagementGetUser.user}
                                />
                            ))}
                            <div className="space-x-4">
                                {data.userManagementGetUser.canDisable &&
                                !data.userManagementGetUser.user.disabled ? (
                                    <SecondaryButton onClick={disableUser}>
                                        Deactivate account
                                    </SecondaryButton>
                                ) : null}
                                {data.userManagementGetUser.user.disabled ? (
                                    <>
                                        <SecondaryButton onClick={enableUser}>
                                            Reactivate account
                                        </SecondaryButton>
                                        {data.userManagementGetUser.user.type ===
                                        UserType.User ? (
                                            data.userManagementGetUser.user.hidden ? (
                                                <SecondaryButton onClick={showUser}>
                                                    Show user
                                                </SecondaryButton>
                                            ) : (
                                                <SecondaryButton onClick={hideUser}>
                                                    Hide user
                                                </SecondaryButton>
                                            )
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </>
                )}
            </QueryRenderer>
            <div className="mt-8">
                {path?.includes("integration") ? (
                    <Link
                        className={`${defaultLinkStyle} twoverride`}
                        to="/settings/integrations/accounts">
                        Back to integrations
                    </Link>
                ) : (
                    <Link
                        className={`${defaultLinkStyle} twoverride`}
                        to="/settings/users">
                        Back to accounts
                    </Link>
                )}
            </div>
        </ContentFull>
    );
};

interface ManageUsersEditRegionProps {
    region: TenantRegion;
    user: UserManagementGetUserQuery["userManagementGetUser"]["user"];
    refetch: () => void;
}

const ManageUsersEditRegion: React.FC<ManageUsersEditRegionProps> = props => {
    const { region, user, refetch } = props;
    const showRegion = useSelector(
        (state: ApplicationState) => state.region.regions.length > 1,
    );
    const roles = useUserManagementGetRolesQuery({}, stableQueryOptions());
    const existingPermission = user.permissions.find(p => p.region.key === region.key);
    const existingPermissionValue = existingPermission?.role ?? "";
    const [selectedPermission, setSelectedPermission] = React.useState(
        existingPermissionValue.startsWith("custom:")
            ? "custom"
            : existingPermissionValue,
    );
    const [showSuccessNotification, setShowSuccessNotification] = React.useState(false);
    const dirty = selectedPermission !== existingPermissionValue;
    const mutation = useUserManagementUpdatePermissionMutation();
    const save = React.useCallback(async () => {
        await mutation.mutateAsync({
            userId: user.id,
            regionId: region.id,
            roleKey: selectedPermission,
        });
        setShowSuccessNotification(true);
        setTimeout(() => setShowSuccessNotification(false), 3000);
        await refetch();
    }, [selectedPermission, region, refetch, mutation, user.id]);
    return (
        <div className="space-y-2">
            <div>
                <QueryRenderer name={`Edit permission for ${region.name}`} query={roles}>
                    {data => (
                        <div className="w-112">
                            <div className="flex items-end space-x-4">
                                <div className="flex-1">
                                    <SelectPro
                                        includePlaceholderOption={false}
                                        label={
                                            showRegion
                                                ? `Permissions for ${region.name}`
                                                : "Permission"
                                        }
                                        name="role"
                                        onChange={e =>
                                            setSelectedPermission(e.target.value)
                                        }
                                        value={selectedPermission}
                                        options={[
                                            { value: "", text: "No access" },
                                            ...data.userManagementGetRoles.map(role => ({
                                                value: role.key,
                                                text: role.humanReadableName,
                                            })),
                                            { value: "custom", text: "Custom" },
                                        ]}
                                    />
                                </div>
                                <div className="flex-0">
                                    {dirty && selectedPermission !== "custom" ? (
                                        <PrimaryButton onClick={save}>
                                            Update
                                        </PrimaryButton>
                                    ) : (
                                        <SecondaryButton disabled>Update</SecondaryButton>
                                    )}
                                </div>
                            </div>
                            <ManageUsersPermissionsLevels />
                            {selectedPermission === "custom" ? (
                                <CustomRolesBanner />
                            ) : null}
                            {showSuccessNotification ? (
                                <div className="mt-4">
                                    <InlineBanner type="success">
                                        Permission updated
                                    </InlineBanner>
                                </div>
                            ) : null}
                        </div>
                    )}
                </QueryRenderer>
            </div>
        </div>
    );
};
