import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
    CommunityOrgTypeSentinel,
    EVERYONE_SENTINEL,
    QuestionType,
    ReferralTypesOptions,
} from "@sp-crm/core";
import { SearchbarItemBadge } from "components/community-search/search-bar/searchbar-item-badge";
import { EntityFilters } from "components/shared/entity-filters";
import { useDebouncedState } from "components/shared/hooks";
import { SelectableUser, UserSelect } from "components/shared/user-select";
import { Callout } from "components/ui/callout";
import { Checkbox } from "components/ui/checkbox";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { SearchInput } from "components/ui/search-input";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    AdvancedSearchCondition,
    AdvancedSearchConditionNodeType,
    LayoutSectionParentKey,
    useGetEntitiesQuery,
    useGetLayoutSectionsQuery,
} from "generated/graphql";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTenantSettings } from "store/selectors/hooks";
import { ReferenceFilter } from "store/slices/reference-dashboard";
import { stableQueryOptions } from "util/requests";

interface ReferenceSearchBarProps {
    value: ReferenceFilter;
    onChange: (filter: ReferenceFilter) => void;
}

export const ReferenceSearchBar: React.FC<ReferenceSearchBarProps> = props => {
    const { value, onChange } = props;
    const tenantSettings = useTenantSettings();
    const ownedReferralSources = tenantSettings.ownedReferralSources;

    const [searchTerm, setSearchTerm, searchTermDebounced] = useDebouncedState(
        value.search,
        400,
    );

    useEffect(() => {
        if (searchTermDebounced !== value.search) {
            onChange({
                ...value,
                search: searchTermDebounced,
            });
        }
    }, [searchTermDebounced]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOrgTypeChanged = (orgTypes: string[]) => {
        onChange({
            ...value,
            orgTypes,
        });
    };

    const handleAssignedUserChanged = (assignedUserId: SelectableUser) => {
        onChange({
            ...value,
            assignedUserId,
        });
    };

    const handleContactConditionChanged = (condition: AdvancedSearchCondition | null) => {
        onChange({
            ...value,
            contactCondition: condition,
        });
    };

    const handleBusinessConditionChanged = (
        condition: AdvancedSearchCondition | null,
    ) => {
        onChange({
            ...value,
            businessCondition: condition,
        });
    };

    const showClearButton = useMemo(() => {
        return (
            value.search.length > 0 ||
            value.orgTypes.length > 0 ||
            countFiltersApplied(value.contactCondition) > 0 ||
            countFiltersApplied(value.businessCondition) > 0 ||
            value.assignedUserId !== EVERYONE_SENTINEL
        );
    }, [
        value.assignedUserId,
        value.search.length,
        value.orgTypes.length,
        value.contactCondition,
        value.businessCondition,
    ]);

    return (
        <div className="flex items-end space-x-4">
            <div className="flex-1 max-w-lg">
                <SearchInput
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    placeholder="Organization name or contact name"
                />
            </div>
            {ownedReferralSources ? (
                <UserSelect
                    label="Filter by assigned to"
                    onChange={handleAssignedUserChanged}
                    value={value.assignedUserId}
                    includeEveryone
                />
            ) : null}
            <div className="flex items-center space-x-4 pb-2">
                <OrgTypeFilter value={value.orgTypes} onChange={handleOrgTypeChanged} />
                <EntityMoreFilter
                    value={value.contactCondition}
                    onChange={handleContactConditionChanged}
                    entityType="ReferenceContact"
                    label="Contact filters"
                    layoutSectionParentKey={LayoutSectionParentKey.ReferenceContact}
                />
                <EntityMoreFilter
                    value={value.businessCondition}
                    onChange={handleBusinessConditionChanged}
                    entityType="ReferenceBusiness"
                    label="Organization filters"
                    layoutSectionParentKey={LayoutSectionParentKey.ReferenceBusiness}
                />
                {showClearButton ? (
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            setSearchTerm("");
                            onChange({
                                search: "",
                                orgTypes: [],
                                contactCondition: null,
                                businessCondition: null,
                                assignedUserId: EVERYONE_SENTINEL,
                            });
                        }}>
                        Clear all filters
                    </a>
                ) : null}
            </div>
        </div>
    );
};

interface EntityMoreFilterProps {
    value: AdvancedSearchCondition | null;
    onChange: (condition: AdvancedSearchCondition | null) => void;
    entityType: string;
    label: string;
    layoutSectionParentKey: LayoutSectionParentKey;
}

const countFiltersApplied = (condition: AdvancedSearchCondition | null): number => {
    if (!condition) {
        return 0;
    }

    if (condition.nodeType === AdvancedSearchConditionNodeType.FieldCondition) {
        return 1;
    }

    let sum = 0;

    condition.children?.forEach(c => {
        sum += countFiltersApplied(c);
    });

    return sum;
};

const EntityMoreFilter: React.FC<EntityMoreFilterProps> = props => {
    const { value, onChange, entityType, label, layoutSectionParentKey } = props;

    const entitiesQuery = useGetEntitiesQuery({}, { ...stableQueryOptions() });
    const layoutQuery = useGetLayoutSectionsQuery(
        { parentKey: layoutSectionParentKey },
        { ...stableQueryOptions() },
    );
    const [showPanel, setShowPanel] = useState(false);

    const entityMetadata = entitiesQuery.data?.getEntities.find(
        e => e.name === entityType,
    );

    const entityQuestions = entityMetadata?.questions;

    const hasCustomizedEntity = useMemo(() => {
        return (
            entityQuestions?.length > 0 &&
            layoutQuery.data?.getLayoutSections?.layoutSections?.some(
                section =>
                    section.layoutItems?.length > 0 &&
                    section.layoutItems?.some(layoutItem => {
                        const question = entityQuestions.find(
                            q => q.id === layoutItem.questionId,
                        );
                        return (
                            question?.questionType === QuestionType[QuestionType.nary] ||
                            question?.questionType === QuestionType[QuestionType.user]
                        );
                    }),
            )
        );
    }, [entityQuestions, layoutQuery.data]);

    if (hasCustomizedEntity) {
        const contactFilterSelectedCount = countFiltersApplied(value);

        return (
            <>
                <div
                    className="flex items-center space-x-1 cursor-pointer text-gray-700"
                    onClick={e => {
                        e.preventDefault();
                        setShowPanel(true);
                    }}>
                    <div className="text-lg">{label}</div>
                    {contactFilterSelectedCount > 0 ? (
                        <SearchbarItemBadge counter={contactFilterSelectedCount} />
                    ) : null}
                </div>
                <Panel
                    headerText={label}
                    type={PanelType.large}
                    isOpen={showPanel}
                    onDismiss={() => {
                        setShowPanel(false);
                    }}>
                    <EntityFilters
                        entityMetadata={entityMetadata}
                        layoutSections={layoutQuery.data.getLayoutSections.layoutSections}
                        condition={value}
                        onChange={onChange}
                    />
                </Panel>
            </>
        );
    }

    return null;
};

interface OrgTypeFilterProps {
    value: string[];
    onChange: (orgTypes: string[]) => void;
}

const OrgTypeFilter: React.FC<OrgTypeFilterProps> = props => {
    const { value, onChange } = props;

    const [itemFilterText, setItemFilterText] = useState("");

    const renderButton = useCallback(() => {
        return (
            <div className="flex items-center space-x-1 cursor-pointer text-gray-700">
                <div className="text-lg">Organization type</div>
                {value.length > 0 ? <SearchbarItemBadge counter={value.length} /> : null}
                <ChevronDownIcon className="w-5 h-5 text-gray-500" />
            </div>
        );
    }, [value.length]);

    const addOrgType = (orgType: string) => {
        if (!value.includes(orgType)) {
            const newOrgTypes = [...value, orgType];
            onChange(newOrgTypes);
        }
    };

    const removeOrgType = (orgType: string) => {
        if (value.includes(orgType)) {
            const newOrgTypes = value.filter(ot => ot !== orgType);
            onChange(newOrgTypes);
        }
    };

    const clearOrgTypes = useCallback(() => {
        onChange([]);
    }, [onChange]);

    const headerGroups = useMemo(() => {
        const communityOption = {
            header: "Record Type",
            groupOptions: [
                {
                    text: "Community",
                    value: CommunityOrgTypeSentinel,
                },
            ],
        };

        return [...ReferralTypesOptions, communityOption]
            .map(o => {
                return {
                    header: o.header,
                    groupOptions: o.groupOptions.filter(
                        g =>
                            o.header
                                .toLowerCase()
                                .includes(itemFilterText.toLowerCase()) ||
                            g.text.toLowerCase().includes(itemFilterText.toLowerCase()),
                    ),
                };
            })
            .filter(o => o.groupOptions.length > 0);
    }, [itemFilterText]);

    return (
        <Callout renderButton={renderButton}>
            <div className="space-y-2">
                <SecondaryButton
                    onClick={e => {
                        e.preventDefault();
                        clearOrgTypes();
                    }}>
                    <span className="text-sm">Clear</span>
                </SecondaryButton>
                <SearchInput
                    value={itemFilterText}
                    onChange={e => {
                        setItemFilterText(e.target.value);
                    }}
                />
                <ul className="w-80">
                    {headerGroups.map((option, index) => (
                        <li key={index}>
                            <p className="font-bold">{option.header}</p>
                            <ul>
                                {option.groupOptions.map(
                                    (groupOption, groupOptionIndex) => (
                                        <li key={groupOptionIndex}>
                                            <Checkbox
                                                checked={value.includes(
                                                    groupOption.value,
                                                )}
                                                onChange={e => {
                                                    e.target.checked
                                                        ? addOrgType(groupOption.value)
                                                        : removeOrgType(
                                                              groupOption.value,
                                                          );
                                                }}
                                                label={groupOption.text}
                                            />
                                        </li>
                                    ),
                                )}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        </Callout>
    );
};
