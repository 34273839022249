import { StarIcon } from "@heroicons/react/20/solid";
import {
    CommunityRelationshipType,
    formatDateNoTime,
    formatEntityValue,
    IContact,
    ILocation,
    isValidLocation,
    LayoutSectionParentKey,
    parseLocation,
    printableDistance,
} from "@sp-crm/core";
import { useLocalizedStrings } from "components/locale-provider/locale-provider";
import { ClampedText } from "components/ui/clamped-text";
import { computeDirectionsUrl } from "helpers/address";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useProductName } from "store/selectors/branding";
import {
    useBridgeEntityType,
    useBridgeQuestionsForCurrentRegion,
} from "store/selectors/bridge";
import { useRegions, useVisibleUsers } from "store/selectors/hooks";
import { EmailLink } from "util/email-link";
import { parsePhone } from "util/phone";
import { PhoneOneLine } from "util/phone-display";
import { stableQueryOptions } from "util/requests";
import { breakNewlines } from "util/text";
import { displayableUri, isUrl, sanitizeUri } from "util/uri";
import {
    AdvancedSearchEntityType,
    LayoutSectionKey,
    useGetLayoutSectionsQuery,
} from "../../../generated/graphql";
import { LayoutItemResult, LayoutItems } from "../../layout/layout-items";
import { ListableCommunity } from "../props";
import { ClientCommunityRelationship } from "../types";
import { CommunityCardAnswer } from "./community-card-answer";
import { CommunityCardClientSearchExperience } from "./community-card-client-search-experience";
import { CommunityCardShortlistExperience } from "./community-card-shortlist-experience";
import { CommunityCardThumbnail } from "./community-card-thumbnail";
import { CommunityCardTypes } from "./community-card-types";

export enum CommunityCardExperience {
    GlobalSearch = 0,
    ClientMatchSearch,
    ClientShortlist,
}

interface CommunityCardProps {
    community: ListableCommunity;
    clientCommunityRelationship?: ClientCommunityRelationship;
    experience: CommunityCardExperience;
    geoReference: ILocation | null;
}

const computeAddressLine2 = (community: ListableCommunity): string | null => {
    // If there is no address component at all, we want to make sure this function returns an
    // empty string so that the this.hasAddress() method works as expected.
    if (community.city)
        return `${community.city ?? ""}, ${community.state ?? ""} ${community.zip ?? ""}`;

    if (community.state) return `${community.state ?? ""} ${community.zip ?? ""}`;
    if (community.zip) return community.zip;
    return null;
};

interface CommunityCardLayoutItemsProps {
    community: ListableCommunity;
}

const CommunityCardLayoutItems: React.FC<CommunityCardLayoutItemsProps> = React.memo(
    props => {
        const { community } = props;

        const layoutQuery = useGetLayoutSectionsQuery(
            {
                parentKey: LayoutSectionParentKey.CommunityCard,
            },
            stableQueryOptions(),
        );

        const { entityType } = useBridgeEntityType(AdvancedSearchEntityType.Community);
        const regions = useRegions();
        const users = useVisibleUsers();
        const productName = useProductName();

        const layoutSection = layoutQuery.data?.getLayoutSections?.layoutSections?.find(
            s => s.sectionKey === LayoutSectionKey.CommunityCardKeyAttributes,
        );

        const questions = useBridgeQuestionsForCurrentRegion(
            AdvancedSearchEntityType.Community,
        );

        if (layoutQuery.isLoading || layoutQuery.isError || !layoutSection) {
            return null;
        }

        if (!entityType) {
            return null;
        }

        return (
            <LayoutItems
                getAnswers={() => community.answers}
                renderAnswer={(q, a) => (
                    <CommunityCardAnswer question={q} answer={a} users={users} />
                )}
                renderStandardField={(
                    community: ListableCommunity,
                    fieldName,
                    fieldDefinition,
                ) => {
                    const formatted = formatEntityValue(
                        community,
                        fieldName,
                        fieldDefinition,
                        {
                            regions,
                            users,
                            productName,
                        },
                    );

                    return formatted ? <p className="line-clamp-6">{formatted}</p> : null;
                }}
                questions={questions}
                layoutItems={layoutSection.layoutItems}
                fieldMetadataList={entityType.fields}>
                {(layoutItems, getTitle, renderValue) => (
                    <>
                        {layoutItems.map(layoutItem => {
                            if (layoutItem.entityFieldName === "rating") {
                                return (
                                    <LayoutItemRating
                                        key={layoutItem.id}
                                        layoutItem={layoutItem}
                                        community={community}
                                    />
                                );
                            }
                            if (layoutItem.entityFieldName === "__computed__placements") {
                                return (
                                    <LayoutItemPlacements
                                        key={layoutItem.id}
                                        layoutItem={layoutItem}
                                        community={community}
                                    />
                                );
                            }
                            if (layoutItem.entityFieldName === "__computed__referrals") {
                                return (
                                    <LayoutItemReferrals
                                        key={layoutItem.id}
                                        layoutItem={layoutItem}
                                        community={community}
                                    />
                                );
                            }
                            if (
                                layoutItem.entityFieldName ===
                                "__computed__licenseRecords"
                            ) {
                                return (
                                    <LayoutItemLicenseRecords
                                        key={layoutItem.id}
                                        layoutItem={layoutItem}
                                        community={community}
                                    />
                                );
                            }
                            const value = renderValue(community, layoutItem);

                            return value || layoutItem.showIfBlank ? (
                                <div key={layoutItem.id} className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {getTitle(layoutItem)}
                                    </dt>
                                    <dd className="mt-1 flex text-sm text-gray-900">
                                        {value}
                                    </dd>
                                </div>
                            ) : null;
                        })}
                    </>
                )}
            </LayoutItems>
        );
    },
);

CommunityCardLayoutItems.displayName = "CommunityCardLayoutItems";

interface SpecialLayoutItemProps {
    layoutItem: LayoutItemResult;
    community: ListableCommunity;
}

const LayoutItemRating: React.FC<SpecialLayoutItemProps> = props => {
    const { layoutItem, community } = props;
    const rating = community.rating;
    if ((rating === null || rating === undefined) && !layoutItem.showIfBlank) return null;
    return (
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Rating</dt>
            <dd className="mt-1 flex text-sm text-yellow-300">
                {Array.from({
                    length: community.rating,
                }).map((_, i) => (
                    <StarIcon key={i} className="h-6 w-6" />
                ))}
            </dd>
        </div>
    );
};

const LayoutItemPlacements: React.FC<SpecialLayoutItemProps> = props => {
    const { layoutItem, community } = props;
    const placements = community.placements;
    if (!placements && !layoutItem.showIfBlank) return null;
    return (
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Placements</dt>
            <dd className="mt-1 flex text-sm text-gray-900">
                {community.placements ? (
                    <>
                        <span className="font-bold">{community.placements}</span>

                        <span className="ml-2 text-gray-500" aria-hidden="true">
                            ·
                        </span>
                        <span className="ml-2 text-gray-500">
                            {formatDateNoTime(community.mostRecentPlacement)}
                        </span>
                    </>
                ) : (
                    <span className="text-gray-500">0</span>
                )}
            </dd>
        </div>
    );
};

const LayoutItemReferrals: React.FC<SpecialLayoutItemProps> = props => {
    const { layoutItem, community } = props;
    const referralCount = community.referralCount;
    if (!referralCount && !layoutItem.showIfBlank) return null;
    return (
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Referred Clients</dt>
            <dd className="mt-1 flex text-sm text-gray-900">
                {community.referralCount ? (
                    <span>{community.referralCount}</span>
                ) : (
                    <span className="text-gray-500">0</span>
                )}
            </dd>
        </div>
    );
};

interface LayoutItemLicenseRecordsProps {
    layoutItem: LayoutItemResult;
    community: ListableCommunity;
}

const LayoutItemLicenseRecords: React.FC<LayoutItemLicenseRecordsProps> = props => {
    const { layoutItem, community } = props;

    const licensesWithLink = useMemo(() => {
        return (community.licenses || []).filter(l => !!isUrl(l.href));
    }, [community.licenses]);

    if (licensesWithLink.length === 0 && !layoutItem.showIfBlank) {
        return null;
    }

    return (
        <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">License Records</dt>
            <dd className="mt-1 flex text-sm text-gray-900">
                <ul>
                    {licensesWithLink.map((license, i) => (
                        <li key={i}>
                            <a
                                className="truncate"
                                target="_blank"
                                rel="noreferrer"
                                href={sanitizeUri(license.href)}>
                                Record lookup{" "}
                                {license.licenseNumber ? `#${license.licenseNumber}` : ""}
                            </a>
                        </li>
                    ))}
                </ul>
            </dd>
        </div>
    );
};

export const CommunityCard: React.FC<CommunityCardProps> = props => {
    const { community, clientCommunityRelationship, experience, geoReference } = props;
    const [isLargePhoto, setIsLargePhoto] = React.useState(false);
    const toggleLargePhoto = useCallback(
        () => setIsLargePhoto(v => !v),
        [setIsLargePhoto],
    );
    const addressLine2 = computeAddressLine2(community);
    const phone = parsePhone(community.mainPhone ?? community.phone);

    const urlTarget = sanitizeUri(community.url);
    const urlDisplay = displayableUri(community.url, 35);
    const isExcluded =
        clientCommunityRelationship &&
        clientCommunityRelationship.relationship === CommunityRelationshipType.excluded;

    const communityLocation = parseLocation(community.lat, community.lng).getOrElse(null);
    const locale = useLocalizedStrings();

    return (
        <div className="col-span-1 divide-y divide-gray-200 rounded md:rounded-lg bg-white shadow">
            <div
                className={`${
                    isLargePhoto ? "space-y-2 lg:space-y-4" : "flex space-x-6"
                } w-full items-start justify-between p-6`}>
                {isExcluded ? (
                    <div className="w-32" />
                ) : (
                    <div className="cursor-pointer" onClick={toggleLargePhoto}>
                        <CommunityCardThumbnail
                            community={community}
                            className={`${
                                isLargePhoto ? "h-96 w-96" : "h-32 w-32"
                            } flex-shrink-0 rounded-md`}
                        />
                    </div>
                )}
                <div className="flex-1 overflow-auto">
                    <h3 className="twoverride text-gray-900 text-lg -mt-1.5">
                        <Link
                            to={`/communities/show/${community.id}`}
                            className="twoverride hover:underline">
                            {community.name || "(no name)"}
                        </Link>
                    </h3>
                    {isExcluded ? null : <CommunityCardTypes community={community} />}

                    {isExcluded ? null : (
                        <>
                            <div className="w-full mt-1 space-y-2 lg:space-y-0 lg:flex items-begin truncate text-sm text-gray-500">
                                <div className="flex-1">
                                    {community.address ? (
                                        <div>{community.address}</div>
                                    ) : null}
                                    {addressLine2 ? <div>{addressLine2}</div> : null}
                                    {community.address && addressLine2 ? (
                                        <div>
                                            <a
                                                href={computeDirectionsUrl(
                                                    community.address,
                                                    addressLine2,
                                                )}
                                                target="_blank"
                                                rel="noreferrer">
                                                Directions
                                            </a>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="flex-1">
                                    {phone.digits && phone.digits.length > 0 ? (
                                        <div>
                                            <a href={"tel:" + phone.digits}>
                                                {phone.display}
                                            </a>
                                        </div>
                                    ) : null}
                                    {urlDisplay ? (
                                        <div>
                                            <a
                                                href={urlTarget}
                                                target="_blank"
                                                rel="noreferrer">
                                                {urlDisplay}
                                            </a>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="mt-4 border-t border-gray-200 pt-2">
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                                    {community.summary ? (
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Summary
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <ClampedText lines={3}>
                                                    {breakNewlines(community.summary)}
                                                </ClampedText>
                                            </dd>
                                        </div>
                                    ) : null}
                                    {isValidLocation(geoReference) &&
                                    isValidLocation(communityLocation) ? (
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Distance
                                            </dt>
                                            <dd className="mt-1 flex text-sm text-gray-900">
                                                {printableDistance(
                                                    locale,
                                                    geoReference,
                                                    communityLocation,
                                                )}
                                            </dd>
                                        </div>
                                    ) : null}
                                    <CommunityCardLayoutItems community={community} />
                                    {community.contacts &&
                                    community.contacts.length > 0 ? (
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">
                                                Contacts
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <ul className="grid grid-cols-1 gap-x-4 gap-y-4 xl:grid-cols-2">
                                                    {community.contacts.map(
                                                        (contact, i) => (
                                                            <li
                                                                key={i}
                                                                className="space-y-0.5">
                                                                <div className="font-semibold">
                                                                    {contact.name ||
                                                                        "(no name)"}
                                                                </div>
                                                                {contact.role ? (
                                                                    <div className="text-gray-700">
                                                                        {contact.role}
                                                                    </div>
                                                                ) : null}
                                                                <EmailLink
                                                                    email={contact.email1}
                                                                    entityType={
                                                                        AdvancedSearchEntityType.Community
                                                                    }
                                                                    entityId={
                                                                        community.id
                                                                    }
                                                                />
                                                                <PhoneOneLine
                                                                    contact={
                                                                        contact as IContact
                                                                    }
                                                                />
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            </dd>
                                        </div>
                                    ) : null}
                                </dl>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {experience === CommunityCardExperience.ClientMatchSearch ? (
                <CommunityCardClientSearchExperience
                    clientCommunityRelationship={clientCommunityRelationship}
                />
            ) : null}
            {experience === CommunityCardExperience.ClientShortlist ? (
                <CommunityCardShortlistExperience
                    clientCommunityRelationship={clientCommunityRelationship}
                />
            ) : null}
        </div>
    );
};
