import {
    ContactId,
    IAdditionalClient,
    IClient,
    ReferenceContactId,
    SAME_FOR_BOTH_STR,
} from "@sp-crm/core";
import { Checkbox } from "components/ui/checkbox";
import { CrmPersona } from "components/ui/persona";
import { ClientContactRelationship } from "generated/graphql";
import React, { FunctionComponent, useCallback } from "react";
import { ClientContacts } from "../client-contacts";
import AdditionalClientContactList from "./additional-client-contact-list";
import { CareProvidersHeader } from "./care-providers-header";

type MultipleClientsProps = {
    client: IClient;
    additionalClient: IAdditionalClient;
    handleSameForBothClientsCheckbox: (newValue: boolean) => void;
    physicianContactsSameAsPrimaryClient: boolean;
    onAddNewAdditionalClientContact: () => void;
    onLinkNewAdditionalClientContact: (referenceContactId: ReferenceContactId) => void;
    editAdditionalClientContact: (contactId: ContactId) => void;
    deleteAdditionalClientContact: (contactId: ContactId) => void;
};

const MultipleClients: FunctionComponent<MultipleClientsProps> = ({
    client,
    additionalClient,
    handleSameForBothClientsCheckbox,
    physicianContactsSameAsPrimaryClient,
    onAddNewAdditionalClientContact,
    onLinkNewAdditionalClientContact,
    editAdditionalClientContact,
    deleteAdditionalClientContact,
}) => {
    const handleNewAdditionalClientContactClick = useCallback(
        () => onAddNewAdditionalClientContact(),
        [onAddNewAdditionalClientContact],
    );

    const handleLinkAdditionalClientContactClick = useCallback(
        (referenceContactId: ReferenceContactId) =>
            onLinkNewAdditionalClientContact(referenceContactId),
        [onLinkNewAdditionalClientContact],
    );

    return (
        <div className="input-form-block space-y-4">
            <CareProvidersHeader>
                <div>
                    <Checkbox
                        onChange={e => handleSameForBothClientsCheckbox(e.target.checked)}
                        checked={physicianContactsSameAsPrimaryClient}
                        label={SAME_FOR_BOTH_STR}
                    />
                </div>
            </CareProvidersHeader>

            {physicianContactsSameAsPrimaryClient ? (
                <>
                    <ClientContacts
                        header={
                            <CrmPersona
                                name={client.formatClientAndAdditionalClientNames()}
                                size="small"
                            />
                        }
                        clientId={client.id}
                        relationship={ClientContactRelationship.CareProvider}
                    />
                </>
            ) : (
                <>
                    <ClientContacts
                        header={
                            <CrmPersona
                                name={client.formatName(client.name)}
                                size="small"
                            />
                        }
                        clientId={client.id}
                        relationship={ClientContactRelationship.CareProvider}
                    />
                    <AdditionalClientContactList
                        clientId={client.id}
                        name={client.formatName(additionalClient.name)}
                        contacts={additionalClient.physicianContacts}
                        onAddNewContact={handleNewAdditionalClientContactClick}
                        onLinkContact={handleLinkAdditionalClientContactClick}
                        editContact={editAdditionalClientContact}
                        deleteContact={deleteAdditionalClientContact}
                    />
                </>
            )}
        </div>
    );
};

export default MultipleClients;
