import {
    COMMUNITY_REFERRAL,
    CommunityId,
    Maybe,
    ReferenceBusinessId,
    ReferenceContactId,
    ReferenceMap,
    parseEntityId,
} from "@sp-crm/core";
import { Subnav } from "components/shared/subnav";
import { SubnavItem } from "components/shared/subnav/subnav-types";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { ResponsiveMode } from "store/reducers/responsive";
import { ApplicationState } from "store/state";
import { GeneralReferralSourcePicker } from "./GeneralReferralSourcePicker";
import { ReferenceBusinessPicker } from "./reference-business-picker";
import { ReferenceCommunityContactPicker } from "./reference-community-contact-picker";
import { ReferenceCommunityPicker } from "./reference-community-picker";
import {
    ReferenceContactPicker,
    SelectedReferenceContact,
} from "./reference-contact-picker";

export type ReferralSourceSearchTab =
    | "referralContact"
    | "communityContact"
    | "referralBusiness"
    | "referralCommunity"
    | "general";

interface ReferralSourceSearchProps {
    onSelect: (referenceMap: ReferenceMap) => void;
    exclude?: ReferralSourceSearchTab[];
}

export const ReferralSourceSearch: React.FC<ReferralSourceSearchProps> = props => {
    const { onSelect } = props;

    const responsiveMode = useSelector(
        (state: ApplicationState) => state.responsive.mode,
    );

    const [activeTab, setActiveTab] = useState(
        responsiveMode < ResponsiveMode.large ? null : "referralContact",
    );

    const handleReferenceContactSelect = useCallback(
        (selected: SelectedReferenceContact) => {
            onSelect({
                identifier: selected.communityId
                    ? Maybe.fromValue(COMMUNITY_REFERRAL)
                    : Maybe.none(),
                business: selected.businessId
                    ? Maybe.fromValue(selected.businessId)
                    : selected.communityId
                    ? Maybe.fromValue(
                          parseEntityId<ReferenceBusinessId>(selected.communityId),
                      )
                    : Maybe.none(),
                contact: Maybe.fromValue(selected.referenceContactId),
            });
        },
        [onSelect],
    );

    const handleReferenceBusinessSelect = useCallback(
        (selected: ReferenceBusinessId) => {
            onSelect({
                identifier: Maybe.none(),
                business: Maybe.fromValue(selected),
                contact: Maybe.none(),
            });
        },
        [onSelect],
    );

    const handleReferenceCommunitySelect = useCallback(
        (selected: CommunityId) => {
            onSelect({
                identifier: Maybe.fromValue(COMMUNITY_REFERRAL),
                business: Maybe.fromValue(parseEntityId<ReferenceBusinessId>(selected)),
                contact: Maybe.none(),
            });
        },
        [onSelect],
    );

    const handleGeneralSelect = useCallback(
        (value: string) => {
            onSelect({
                identifier: Maybe.fromValue(value),
                business: Maybe.none(),
                contact: Maybe.none(),
            });
        },
        [onSelect],
    );

    const handleCommunityContactSelect = useCallback(
        (selected: ReferenceContactId, community: CommunityId) => {
            onSelect({
                identifier: Maybe.fromValue(COMMUNITY_REFERRAL),
                business: Maybe.fromValue(parseEntityId<ReferenceBusinessId>(community)),
                contact: Maybe.fromValue(selected),
            });
        },
        [onSelect],
    );

    const handleCloseSection = useCallback(() => {
        setActiveTab(null);
    }, [setActiveTab]);

    const renderReferenceContactPicker = useCallback(() => {
        return <ReferenceContactPicker onSelect={handleReferenceContactSelect} />;
    }, [handleReferenceContactSelect]);

    const renderReferenceBusinessPicker = useCallback(() => {
        return <ReferenceBusinessPicker onSelect={handleReferenceBusinessSelect} />;
    }, [handleReferenceBusinessSelect]);

    const renderReferenceCommunityPicker = useCallback(() => {
        return <ReferenceCommunityPicker onSelect={handleReferenceCommunitySelect} />;
    }, [handleReferenceCommunitySelect]);

    const renderGeneralReferralSourcePicker = useCallback(() => {
        return <GeneralReferralSourcePicker onSelect={handleGeneralSelect} />;
    }, [handleGeneralSelect]);

    const renderCommunityContactPicker = useCallback(() => {
        return (
            <ReferenceCommunityContactPicker onSelect={handleCommunityContactSelect} />
        );
    }, [handleCommunityContactSelect]);

    const tabs: SubnavItem[] = [
        {
            linkText: "Referral Contacts",
            subpage: "referralContact",
            render: renderReferenceContactPicker,
        },
        {
            linkText: "Community Contacts",
            subpage: "communityContact",
            render: renderCommunityContactPicker,
        },
        {
            linkText: "Organizations",
            subpage: "organization",
            render: renderReferenceBusinessPicker,
        },
        {
            linkText: "Communities",
            subpage: "community",
            render: renderReferenceCommunityPicker,
        },
        {
            linkText: "General",
            subpage: "general",
            render: renderGeneralReferralSourcePicker,
        },
    ].filter(tab => !props.exclude?.includes(tab.subpage as ReferralSourceSearchTab));

    return (
        <div>
            <Subnav
                subpages={tabs}
                defaultSubpage={null}
                selectedSubpage={activeTab}
                navigate={setActiveTab}
                closeSection={handleCloseSection}
            />
        </div>
    );
};
