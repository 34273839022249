import { CommunityId, Contact, IContact } from "@sp-crm/core";
import { RecipientsField } from "components/messages/recipients-field";
import { Checkbox } from "components/ui/checkbox";
import { InlineBanner } from "components/ui/inline-banner";
import { SecondaryButton } from "components/ui/secondary-button";
import { GetClientForIntakeEmailQuery } from "generated/graphql";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { handleEvent } from "util/user-events";
import Shared from "../../../shared";

const COMMUNITIES_TO_EMAIL = "Communities to email";

export interface CommunityRecipient {
    community: { name: string; id: CommunityId };
    isSelected: boolean;
    contacts: IContact[];
    recipients: IContact[];
}

export const buildCommunityRecipient = (
    clientCommunity: GetClientForIntakeEmailQuery["getClient"]["clientCommunities"][0],
): CommunityRecipient | null => {
    return {
        community: {
            id: clientCommunity.community.id,
            name: clientCommunity.community.name ?? "",
        },
        contacts: [
            Contact.load({
                name: clientCommunity.community.email ?? "",
                email1: clientCommunity.community.email ?? "",
                email1OptOut: clientCommunity.community.emailOptOut,
            }),
            ...clientCommunity.community.communityContacts.map(contact =>
                Contact.load({
                    name: contact.contact.name ?? "",
                    email1: contact.contact.email1 ?? "",
                    email1OptOut: contact.contact.email1OptOut,
                }),
            ),
        ].filter(c => !!c.email1),
        isSelected: true,
        recipients: [
            Contact.load({
                name: clientCommunity.community.email ?? "",
                email1: clientCommunity.community.email ?? "",
                email1OptOut: clientCommunity.community.emailOptOut,
            }),
            ...clientCommunity.community.communityContacts
                .filter(c => c.primary)
                .map(contact =>
                    Contact.load({
                        name: contact.contact.name ?? "",
                        email1: contact.contact.email1 ?? "",
                        email1OptOut: contact.contact.email1OptOut,
                    }),
                ),
        ].filter(c => !!c.email1 && !c.email1OptOut),
    };
};

export const SelectCommunityRecipients = ({
    communityRecipients,
    onUpdateCommunityRecipients,
}: {
    communityRecipients: CommunityRecipient[];
    onUpdateCommunityRecipients: (newCommunityRecipients: CommunityRecipient[]) => void;
}): JSX.Element => {
    const [isEditMode, setEditMode] = useState(false);

    const checkValidation = useCallback(() => {
        if (
            communityRecipients.filter(
                communityRecipient => communityRecipient.isSelected,
            ).length === 0
        ) {
            return "No communities selected";
        } else if (
            communityRecipients.filter(
                communityRecipient =>
                    communityRecipient.isSelected &&
                    communityRecipient.recipients.length === 0,
            ).length > 0
        ) {
            handleEvent("community-missing-recipients", {});
            return "Missing recipients";
        }
        return null;
    }, [communityRecipients]);
    return (
        <div className="field-community-recipient">
            {isEditMode ? (
                <CommunityRecipientsEditor
                    communityRecipients={communityRecipients}
                    onDoneEditing={() => setEditMode(false)}
                    onUpdateCommunityRecipients={onUpdateCommunityRecipients}
                />
            ) : (
                <CommunityRecipientsViewer
                    communityRecipients={communityRecipients}
                    onEditRecipients={() => setEditMode(true)}
                    errorMessage={checkValidation()}
                />
            )}
        </div>
    );
};

const CommunityRecipientsViewer = ({
    communityRecipients,
    onEditRecipients,
    errorMessage,
}: {
    communityRecipients: CommunityRecipient[];
    onEditRecipients: () => void;
    errorMessage: string | null;
}): JSX.Element => {
    const editRecipients = "Edit recipients";
    return (
        <div className="flex-column-align-end">
            <Shared.CustomLabel
                className="full-width community-recipient-control pl-2"
                errorMessage={errorMessage}>
                {COMMUNITIES_TO_EMAIL}
            </Shared.CustomLabel>
            <CommunitiesRenderer
                communityRecipients={communityRecipients}
                onClick={onEditRecipients}
            />
            <SecondaryButton onClick={onEditRecipients}>{editRecipients}</SecondaryButton>
        </div>
    );
};

const CommunitiesRenderer = ({
    communityRecipients,
    onClick,
}: {
    communityRecipients: CommunityRecipient[];
    onClick: () => void;
}): JSX.Element => {
    const visibleCommunities = useMemo(() => {
        return communityRecipients.filter(
            communityRecipient => communityRecipient.isSelected,
        );
    }, [communityRecipients]);

    return (
        <div
            className="communities-container community-recipient-control"
            onClick={onClick}>
            {visibleCommunities.map(communityRecipient => (
                <CommunityRenderer
                    key={communityRecipient.community.id}
                    communityRecipient={communityRecipient}
                />
            ))}
        </div>
    );
};

const CommunityRenderer = ({
    communityRecipient,
}: {
    communityRecipient: CommunityRecipient;
}): JSX.Element => {
    return (
        <div
            className={`community-tag${
                communityRecipient.recipients.length > 0 ? "" : " has-error"
            }`}>
            {communityRecipient.community.name}
        </div>
    );
};

const CommunityRecipientsEditor = ({
    communityRecipients,
    onDoneEditing,
    onUpdateCommunityRecipients,
}: {
    communityRecipients: CommunityRecipient[];
    onDoneEditing: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onUpdateCommunityRecipients: (newCommunityRecipients: CommunityRecipient[]) => any;
}): JSX.Element => {
    const onCommunityRecipientUpdated = (updatedRecip: CommunityRecipient) => {
        communityRecipients.some((cr, index) => {
            if (cr.community.id === updatedRecip.community.id) {
                communityRecipients[index] = updatedRecip;
                return true;
            }
            return false;
        });

        onUpdateCommunityRecipients(communityRecipients);
    };

    const buttonLabel = "Collapse recipients";
    return (
        <div className="edit-mode">
            <div className="section bold-label communities-to-email-label space-y-1">
                <label>{COMMUNITIES_TO_EMAIL}</label>
                <p className="text-sm text-gray-700">
                    Separate emails will be sent to each community individually
                </p>
            </div>
            {communityRecipients.map(communityRecipient => (
                <Fragment key={communityRecipient.community.id}>
                    <EditCommunityRecipient
                        communityRecipient={communityRecipient}
                        onUpdateCommunityRecipient={newValue =>
                            onCommunityRecipientUpdated(newValue)
                        }
                    />
                </Fragment>
            ))}
            <div className="section flex-column-align-end">
                <SecondaryButton
                    className="done-button"
                    title={buttonLabel}
                    onClick={onDoneEditing}>
                    {buttonLabel}
                </SecondaryButton>
            </div>
        </div>
    );
};

const EditCommunityRecipient = ({
    communityRecipient,
    onUpdateCommunityRecipient,
}: {
    communityRecipient: CommunityRecipient;
    onUpdateCommunityRecipient: (newCommunityRecipient: CommunityRecipient) => void;
}): JSX.Element => {
    const showNoRecipientsError =
        communityRecipient.isSelected && communityRecipient.recipients.length === 0;

    return (
        <div className="flex-row-baseline-no-bottom-margin section edit-community-recipients-control">
            <Checkbox
                checked={communityRecipient.isSelected}
                onChange={e => {
                    onUpdateCommunityRecipient({
                        ...communityRecipient,
                        isSelected: e.target.checked,
                    });
                }}
                label={communityRecipient.community.name}
            />
            <div
                className={`edit-community-recipient${
                    communityRecipient.isSelected ? " active" : "" + " flex-column"
                }`}>
                {showNoRecipientsError ? (
                    <InlineBanner type="error">No recipients</InlineBanner>
                ) : null}
                <RecipientsField
                    label=""
                    disabled={!communityRecipient.isSelected}
                    recipients={communityRecipient.recipients}
                    onChange={(recipients: IContact[]) => {
                        onUpdateCommunityRecipient({
                            ...communityRecipient,
                            recipients,
                        });
                    }}
                    suggestions={communityRecipient.contacts}
                />
            </div>
        </div>
    );
};
