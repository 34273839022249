import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import * as React from "react";
import Map, { NavigationControl, ScaleControl } from "react-map-gl";
import { NextgenMapMarker } from "../community-search/community-map/nextgen-marker";
import { locationFor } from "../community-search/listable-community-utility";
import { ListableCommunity } from "../community-search/props";

interface CommunityMapProps {
    community: ListableCommunity;
}

export const NextgenStaticMap: React.FC<CommunityMapProps> = props => {
    const { community } = props;

    const communityHasLocation = !!locationFor(community);

    if (!communityHasLocation) {
        return <span />;
    }

    return (
        <Map
            mapLib={maplibregl}
            attributionControl={false}
            initialViewState={{
                bounds: [
                    {
                        lng: parseFloat(community.lng),
                        lat: parseFloat(community.lat),
                    },
                    {
                        lng: parseFloat(community.lng),
                        lat: parseFloat(community.lat),
                    },
                ],
                fitBoundsOptions: { maxZoom: 13 },
            }}
            style={{ height: 200 }}
            mapStyle="https://api.maptiler.com/maps/8d80423a-4979-4e17-a487-ce521f75ff8e/style.json?key=Xs35dG5Gh6ueSw4mF06I">
            <NavigationControl position="top-left" />
            <NextgenMapMarker community={community} isHovered={false} />
            <ScaleControl />
        </Map>
    );
};
