import { CommunityId, ILocation, isValidLocation, makeLocation } from "@sp-crm/core";
import { GeoCenter, MapLoadingIndicator, useMapEvents } from "components/map/map-common";
import { defaultLinkStyle } from "components/ui/link";
import { SecondaryButton } from "components/ui/secondary-button";
import maplibregl, { LngLat, LngLatBounds } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import * as React from "react";
import Map, {
    FullscreenControl,
    GeolocateControl,
    LngLatBoundsLike,
    MapRef,
    NavigationControl,
    Popup,
    ScaleControl,
} from "react-map-gl";
import { Link } from "react-router-dom";
import { locationFor } from "../listable-community-utility";
import { ListableCommunity } from "../props";
import { NextgenMapMarker } from "./nextgen-marker";

interface NextgenMapProps {
    communities?: ListableCommunity[];
    geoReference?: ILocation;
    childControlGenerator?: (
        c: ListableCommunity,
        mapExperience?: boolean,
    ) => JSX.Element;
    highlightCommunityId?: CommunityId;
    onBoundsUpdated?: (northWest: ILocation, southEast: ILocation) => void;
    autoMoveMap: boolean;
    isLoading?: boolean;
}

const latLngToILocation = (latLng: LngLat | mapboxgl.LngLat): ILocation =>
    makeLocation(latLng.lat, latLng.lng);

export const NextgenMap: React.FC<NextgenMapProps> = props => {
    const {
        communities,
        highlightCommunityId,
        geoReference,
        childControlGenerator,
        onBoundsUpdated,
        autoMoveMap,
        isLoading,
    } = props;
    const wrapperDiv = React.useRef<HTMLDivElement>(null);
    const fixedMapDiv = React.useRef<HTMLDivElement>(null);
    const mapRef = React.useRef<MapRef>(null);
    const [showUpdateSearch, setShowUpdateSearch] = React.useState<boolean>(false);
    const [communityPopup, setCommunityPopup] = React.useState<ListableCommunity | null>(
        null,
    );
    const closePopup = React.useCallback(() => setCommunityPopup(null), []);
    const [_, setRedrawCounter] = React.useState<number>(0);
    const communitiesWithLocation = React.useMemo(
        () => (communities || []).filter(locationFor),
        [communities],
    );

    const mapBounds: LngLatBounds | null = React.useMemo(() => {
        if (communitiesWithLocation.length > 0) {
            const bounds = new LngLatBounds();
            communitiesWithLocation.forEach(c => {
                const location = locationFor(c);
                bounds.extend([location.loc[0], location.loc[1]]);
            });
            return bounds;
        }
        return null;
    }, [communitiesWithLocation]);

    const shouldProcessEvents = useMapEvents(
        autoMoveMap,
        wrapperDiv,
        fixedMapDiv,
        mapRef,
        mapBounds,
    );

    React.useEffect(() => {
        if (!communityPopup) {
            return;
        }
        if (!communitiesWithLocation.some(c => c.id === communityPopup.id)) {
            setCommunityPopup(null);
        }
    }, [communities, communityPopup, setCommunityPopup]); // eslint-disable-line react-hooks/exhaustive-deps

    const onMoveEnd = React.useCallback(() => {
        if (shouldProcessEvents() && onBoundsUpdated) {
            setShowUpdateSearch(true);
        }
        setRedrawCounter(c => c + 1);
    }, [shouldProcessEvents, onBoundsUpdated]);

    const doMapSearch = React.useCallback(() => {
        if (mapRef.current) {
            const bounds = mapRef.current.getMap().getBounds();
            onBoundsUpdated(
                latLngToILocation(bounds.getNorthWest()),
                latLngToILocation(bounds.getSouthEast()),
            );
            setShowUpdateSearch(false);
        }
    }, [mapRef, onBoundsUpdated]);

    const clusterMarkers: JSX.Element[] = [];
    communitiesWithLocation.forEach(c => {
        clusterMarkers.push(
            <NextgenMapMarker
                key={c.id}
                community={c}
                isHovered={c.id === highlightCommunityId}
                selectCommunity={setCommunityPopup}
            />,
        );
    });

    return (
        <div className="top-0 sticky min-h-screen" ref={wrapperDiv}>
            <div
                ref={fixedMapDiv}
                style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
                <Map
                    ref={mapRef}
                    onMoveEnd={onMoveEnd}
                    mapLib={maplibregl}
                    attributionControl={false}
                    initialViewState={{
                        bounds: mapBounds as unknown as LngLatBoundsLike,
                        fitBoundsOptions: { maxZoom: 10 },
                    }}
                    style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
                    mapStyle="https://api.maptiler.com/maps/8d80423a-4979-4e17-a487-ce521f75ff8e/style.json?key=Xs35dG5Gh6ueSw4mF06I">
                    <MapLoadingIndicator isLoading={isLoading} />
                    <GeolocateControl position="top-left" />
                    <FullscreenControl position="top-left" />
                    <NavigationControl position="top-left" />
                    {isValidLocation(geoReference) ? (
                        <GeoCenter location={geoReference} />
                    ) : null}
                    {clusterMarkers}
                    {communityPopup ? (
                        <MarkerPopup
                            onClose={closePopup}
                            community={communityPopup}
                            childControlGenerator={childControlGenerator}
                        />
                    ) : null}
                    <ScaleControl />
                </Map>
                <div className="top-0 right-0 absolute m-2">
                    {showUpdateSearch ? (
                        <SecondaryButton onClick={doMapSearch}>
                            Search this area
                        </SecondaryButton>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const lng = (loc: ILocation) => loc.loc[0];
const lat = (loc: ILocation) => loc.loc[1];

const MarkerPopup: React.FC<{
    onClose: () => void;
    community: ListableCommunity;
    childControlGenerator?: (
        c: ListableCommunity,
        mapExperience?: boolean,
    ) => JSX.Element;
}> = props => {
    const { community, childControlGenerator, onClose } = props;
    const location = locationFor(community);
    if (!location) {
        return null;
    }
    return (
        <Popup
            offset={30}
            longitude={lng(location)}
            latitude={lat(location)}
            closeButton={false}
            maxWidth="none"
            closeOnClick={true}
            onClose={onClose}
            anchor="bottom">
            <div>
                <Link to={`/communities/show/${community.id}`}>
                    <div className={`${defaultLinkStyle} text-lg`}>
                        {community.name ?? "(unnamed)"}
                    </div>
                </Link>
                {childControlGenerator ? (
                    <div>{childControlGenerator(community)}</div>
                ) : null}
            </div>
        </Popup>
    );
};
