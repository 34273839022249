import {
    CommunityId,
    ILocation,
    getDistanceLatLng,
    isValidLocation,
    makeLocation,
} from "@sp-crm/core";
import { ClientGraphqlError } from "backend/fetcher";
import { GeoCenter, MapLoadingIndicator, useMapEvents } from "components/map/map-common";
import { InlineBanner } from "components/ui/inline-banner";
import { defaultLinkStyle } from "components/ui/link";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { produce } from "immer";
import maplibregl, { LngLat, LngLatBounds } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import React from "react";
import Map, {
    FullscreenControl,
    GeolocateControl,
    LngLatBoundsLike,
    MapRef,
    NavigationControl,
    Popup,
    ScaleControl,
} from "react-map-gl";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import { communitySearchMapMoveEvent } from "store/actions/communities";
import { NO_NAME } from "util/text";
import {
    BoundsInput,
    CommunitySearchMapViewQuery,
    CommunitySearchRequest,
    useCommunitySearchMapViewQuery,
} from "../../../generated/graphql";
import { Error } from "../../error";
import { CommunitySearchResultsProps } from "../props";
import { CommunitySearchMapCluster, CommunitySearchMapMarker } from "./map-marker";
import { HoveredCommunity } from "./types";

const staleTime = 1000 * 60 * 10; // 10m

const locationToCoordinates = (location: ILocation): { lat: string; lng: string } => ({
    lng: location.loc[0].toString(),
    lat: location.loc[1].toString(),
});

const latLngToILocation = (latLng: LngLat | mapboxgl.LngLat): ILocation =>
    makeLocation(latLng.lat, latLng.lng);

const defaultZoomLevel = 5;

interface ComponentState {
    isFreeMapView: boolean;
    showUpdateSearch: boolean;
    isLoading: boolean;
    bounds: BoundsInput | null;
    communityPopupId: CommunityId | null;
    zoomLevel: number | null;
}

type Action =
    | { type: "endUserMapMove"; bounds: BoundsInput; zoomLevel: number }
    | { type: "endAutoMapMove" }
    | { type: "disableFreeMap" }
    | { type: "startExplicitBoundsSearch" }
    | { type: "startLoading" }
    | { type: "endLoading" }
    | { type: "clearPopup" }
    | { type: "setPopup"; communityId: CommunityId }
    | { type: "setZoom"; zoom: number };

const reducer: React.Reducer<ComponentState, Action> = (state, action): ComponentState =>
    produce(state, draft => {
        if (action.type === "endUserMapMove") {
            draft.isFreeMapView = true;
            draft.bounds = action.bounds;
            draft.showUpdateSearch = true;
            draft.zoomLevel = action.zoomLevel;
        } else if (action.type === "endAutoMapMove") {
            draft.isFreeMapView = false;
        } else if (action.type === "disableFreeMap") {
            draft.isFreeMapView = false;
        } else if (action.type === "startExplicitBoundsSearch") {
            draft.showUpdateSearch = false;
        } else if (action.type === "startLoading") {
            draft.isLoading = true;
        } else if (action.type === "endLoading") {
            draft.isLoading = false;
        } else if (action.type === "clearPopup") {
            draft.communityPopupId = null;
        } else if (action.type === "setPopup") {
            draft.communityPopupId = action.communityId;
        } else if (action.type === "setZoom") {
            if (!draft.zoomLevel) {
                draft.zoomLevel = action.zoom;
            }
        }
    });

const initialState: ComponentState = {
    isFreeMapView: false,
    showUpdateSearch: false,
    isLoading: false,
    bounds: null,
    communityPopupId: null,
    zoomLevel: null,
};

export const CommunitySearchResultsMap: React.FC<
    CommunitySearchResultsProps & {
        hoveredCommunity: null | HoveredCommunity;
        searchParams: Omit<CommunitySearchRequest, "questionIds">;
    }
> = props => {
    const { hoveredCommunity, searchParams } = props;

    const wrapperDiv = React.useRef<HTMLDivElement>(null);
    const fixedMapDiv = React.useRef<HTMLDivElement>(null);
    const mapRef = React.useRef<MapRef>(null);
    const globalDispatch = useDispatch();
    const windowSize = useWindowSize();

    const [state, localDispatch] = React.useReducer(reducer, initialState);

    const mapView = React.useMemo(() => {
        return {
            zoomLevel: state.zoomLevel,
            bounds: state.bounds,
            viewportHeight: windowSize.height * 0.7,
            viewportWidth: windowSize.width * 0.45,
        };
    }, [state.zoomLevel, state.bounds, windowSize]);
    const query = useCommunitySearchMapViewQuery(
        {
            searchParams,
            mapView,
        },
        {
            staleTime,
            keepPreviousData: true,
        },
    );
    React.useEffect(() => {
        if (query && query.data && !query.isError) {
            localDispatch({
                type: "setZoom",
                zoom: query.data.communitySearchMapView.recommendedZoom,
            });
        }
        if (query.isLoading) {
            localDispatch({ type: "startLoading" });
        } else {
            localDispatch({ type: "endLoading" });
        }
    }, [query, query.data, query.isLoading]);
    React.useEffect(() => {
        if (!searchParams.bounds && state.isFreeMapView) {
            localDispatch({ type: "disableFreeMap" });
        }
    }, [searchParams, searchParams.bounds]); // eslint-disable-line react-hooks/exhaustive-deps

    const mapBounds: LngLatBounds | null = React.useMemo(() => {
        if (
            !query ||
            !query.data ||
            !query.data.communitySearchMapView ||
            (query.data.communitySearchMapView.communityClusters.length === 0 &&
                query.data.communitySearchMapView.communityCoordinates.length === 0 &&
                !query.data.communitySearchMapView.coordinates)
        ) {
            return null;
        }
        const bounds = new LngLatBounds();
        query.data?.communitySearchMapView.communityClusters.forEach(c => {
            bounds.extend([c.lng, c.lat]);
        });
        query.data?.communitySearchMapView.communityCoordinates.forEach(c => {
            bounds.extend([c.lng, c.lat]);
        });
        if (query.data.communitySearchMapView.coordinates) {
            bounds.extend([
                parseFloat(query.data.communitySearchMapView.coordinates.lng),
                parseFloat(query.data.communitySearchMapView.coordinates.lat),
            ]);
        }
        return bounds;
    }, [query]);

    const shouldProcessEvents = useMapEvents(
        !state.isFreeMapView,
        wrapperDiv,
        fixedMapDiv,
        mapRef,
        mapBounds,
    );

    React.useEffect(() => {
        if (!state.communityPopupId || !query.data) {
            return;
        }
        if (
            !query.data?.communitySearchMapView?.communityCoordinates.some(
                c => c.id === state.communityPopupId,
            )
        ) {
            localDispatch({ type: "clearPopup" });
        }
    }, [query.data, state.communityPopupId]);

    const onBoundsUpdated = React.useCallback(
        (northWest: ILocation, southEast: ILocation) => {
            localDispatch({
                type: "endUserMapMove",
                zoomLevel: mapRef.current?.getMap().getZoom() ?? defaultZoomLevel,
                bounds: {
                    northWest: locationToCoordinates(northWest),
                    southEast: locationToCoordinates(southEast),
                },
            });

            globalDispatch(
                communitySearchMapMoveEvent(props.searchKey, northWest, southEast),
            );
        },
        [props.searchKey, globalDispatch],
    );

    const onMoveEnd = React.useCallback(
        (e: unknown) => {
            if (shouldProcessEvents()) {
                if (
                    Object.prototype.hasOwnProperty.call(e, "automaticOperationType") &&
                    (e as { automaticOperationType: string }).automaticOperationType ===
                        "fitBounds"
                ) {
                    localDispatch({
                        type: "endAutoMapMove",
                    });
                } else {
                    const bounds = mapRef.current?.getMap().getBounds();
                    localDispatch({
                        type: "endUserMapMove",
                        zoomLevel: mapRef.current?.getMap().getZoom() ?? defaultZoomLevel,
                        bounds: {
                            northWest: locationToCoordinates(
                                latLngToILocation(bounds.getNorthWest()),
                            ),
                            southEast: locationToCoordinates(
                                latLngToILocation(bounds.getSouthEast()),
                            ),
                        },
                    });
                }
            }
        },
        [shouldProcessEvents],
    );

    const doMapSearch = React.useCallback(() => {
        if (mapRef.current) {
            const bounds = mapRef.current.getMap().getBounds();
            onBoundsUpdated(
                latLngToILocation(bounds.getNorthWest()),
                latLngToILocation(bounds.getSouthEast()),
            );
            localDispatch({ type: "startExplicitBoundsSearch" });
        }
    }, [mapRef, onBoundsUpdated]);

    const flyTo = React.useCallback((lng: number, lat: number) => {
        if (mapRef.current) {
            mapRef.current.flyTo({
                center: [lng, lat],
                zoom: mapRef.current?.getMap().getZoom() + 2,
            });
        }
    }, []);

    const closePopup = React.useCallback(() => localDispatch({ type: "clearPopup" }), []);
    const setCommunityPopup = React.useCallback(
        (communityId: CommunityId) => localDispatch({ type: "setPopup", communityId }),
        [],
    );

    if (query.isLoading && !query.data) {
        return <Spinner />;
    }

    if (query.error) {
        const gqlError = query.error as ClientGraphqlError;
        if (
            typeof gqlError.hasErrorType === "function" &&
            gqlError.hasErrorType("INVALID_RECORD")
        ) {
            return (
                <InlineBanner type="warning">
                    The location data provided does not correspond to a real-world map
                    location. Please review your location search and try again.
                </InlineBanner>
            );
        }

        return (
            <Error componentName="NextgenFilteredCommunityList">
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction */}
                {(query.error as any).message}
            </Error>
        );
    }

    if (!query.data) {
        return <Spinner />;
    }

    const geoReference: ILocation | null = query.data.communitySearchMapView.coordinates
        ? {
              loc: [
                  parseFloat(query.data.communitySearchMapView.coordinates.lng),
                  parseFloat(query.data.communitySearchMapView.coordinates.lat),
              ],
          }
        : null;

    let actualMarkerHighlighted = false;
    const clusterMarkers: JSX.Element[] = [];
    query.data.communitySearchMapView.communityCoordinates.forEach(c => {
        const isHovered = c.id === hoveredCommunity?.communityId;
        if (isHovered) {
            actualMarkerHighlighted = true;
        }
        clusterMarkers.push(
            <CommunitySearchMapMarker
                key={c.id}
                community={c}
                isHovered={isHovered}
                selectCommunity={setCommunityPopup}
            />,
        );
    });

    const bounds = mapRef.current?.getMap().getBounds();
    const shouldHighlightCluster =
        !actualMarkerHighlighted &&
        bounds &&
        hoveredCommunity &&
        hoveredCommunity.location &&
        hoveredCommunity.location.lat > bounds.getSouth() &&
        hoveredCommunity.location.lat < bounds.getNorth() &&
        hoveredCommunity.location.lng > bounds.getWest() &&
        hoveredCommunity.location.lng < bounds.getEast() &&
        query.data.communitySearchMapView.communityClusters.length > 0;
    const highlightedCluster = shouldHighlightCluster
        ? query.data.communitySearchMapView.communityClusters.reduce((acc, cluster) => {
              const distanceToCluster = getDistanceLatLng(
                  hoveredCommunity.location,
                  cluster,
              );
              const distanceToClusterAcc = getDistanceLatLng(
                  hoveredCommunity.location,
                  acc,
              );
              return distanceToCluster < distanceToClusterAcc ? cluster : acc;
          })
        : null;
    query.data.communitySearchMapView.communityClusters.forEach(c => {
        clusterMarkers.push(
            <CommunitySearchMapCluster
                isHovered={highlightedCluster === c}
                flyTo={flyTo}
                key={`${c.lat}${c.lng}`}
                cluster={c}
            />,
        );
    });

    const communityPopupActual =
        query.data.communitySearchMapView.communityCoordinates.find(
            c => c.id === state.communityPopupId,
        );

    return (
        <div className="full-width community-map-anchor">
            <div className="top-0 sticky min-h-screen" ref={wrapperDiv}>
                <div
                    ref={fixedMapDiv}
                    style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}>
                    <Map
                        ref={mapRef}
                        onMoveEnd={onMoveEnd}
                        mapLib={maplibregl}
                        attributionControl={false}
                        initialViewState={{
                            bounds: mapBounds as unknown as LngLatBoundsLike,
                            fitBoundsOptions: { maxZoom: 10 },
                        }}
                        style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        }}
                        mapStyle="https://api.maptiler.com/maps/8d80423a-4979-4e17-a487-ce521f75ff8e/style.json?key=Xs35dG5Gh6ueSw4mF06I">
                        <MapLoadingIndicator isLoading={state.isLoading} />
                        <GeolocateControl position="top-left" />
                        <FullscreenControl position="top-left" />
                        <NavigationControl position="top-left" />
                        {isValidLocation(geoReference) ? (
                            <GeoCenter location={geoReference} />
                        ) : null}
                        {clusterMarkers}
                        {communityPopupActual ? (
                            <MarkerPopup
                                onClose={closePopup}
                                community={communityPopupActual}
                                childControlGenerator={
                                    props.communityCardChildControlGenerator
                                }
                            />
                        ) : null}
                        <ScaleControl />
                    </Map>
                    <div className="top-0 right-0 absolute m-2">
                        {state.showUpdateSearch ? (
                            <SecondaryButton onClick={doMapSearch}>
                                Search this area
                            </SecondaryButton>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

const MarkerPopup: React.FC<{
    onClose: () => void;
    community: CommunitySearchMapViewQuery["communitySearchMapView"]["communityCoordinates"][0];
    childControlGenerator?: (
        communityId: CommunityId,
        mapExperience?: boolean,
    ) => JSX.Element;
}> = props => {
    const { community, childControlGenerator, onClose } = props;
    if (!location) {
        return null;
    }
    return (
        <Popup
            offset={30}
            longitude={community.lng}
            latitude={community.lat}
            closeButton={false}
            maxWidth="none"
            closeOnClick={true}
            onClose={onClose}
            anchor="bottom">
            <div>
                <Link to={`/communities/show/${community.id}`}>
                    <div className={`${defaultLinkStyle} text-lg`}>
                        {community.name ?? NO_NAME}
                    </div>
                </Link>
                {childControlGenerator ? (
                    <div>{childControlGenerator(community.id)}</div>
                ) : null}
            </div>
        </Popup>
    );
};
