import {
    UserManagementGetUserQuery,
    UserManagementGetUsersQuery,
    UserType,
} from "generated/graphql";
import { queryClient } from "util/query-client";

export const statusForUser = (
    user:
        | UserManagementGetUsersQuery["userManagementGetUsers"][0]["user"]
        | UserManagementGetUserQuery["userManagementGetUser"]["user"],
): string => {
    if (user.hidden && user.disabled) {
        return "Deactivated (hidden)";
    }
    if (user.disabled) {
        return "Deactivated (visible)";
    }
    return "Active";
};

export const displayforUser = (
    user:
        | UserManagementGetUsersQuery["userManagementGetUsers"][0]["user"]
        | UserManagementGetUserQuery["userManagementGetUser"]["user"],
): string => {
    if (user.type === UserType.System) {
        return user.name;
    }

    return user.email;
};

export const invalidateUserList = () => {
    queryClient.invalidateQueries(["userManagementGetUsers"]);
};
