import * as Common from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { Checkbox } from "components/ui/checkbox";

import { AutosavingStateInput } from "components/ui/autosaving-state-input";
import * as React from "react";
import { Feature } from "../../feature";
import { Header } from "../../header";
import { LocaleContext } from "../../locale-provider/locale-provider";
import Shared from "../../shared";

interface Props {
    client: Common.IClient;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    onFieldChange: (fieldName: string, newValue: any) => void;
}

const shouldShow = (client: Common.IClient): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    if ((window as any).__placement_crm_show_poa) {
        return true;
    }
    if (!client) {
        return false;
    }
    if (client.hasPowerOfAttorney || client.powerOfAttorneySameAsBestContact) {
        return true;
    }
    if (client.powerOfAttorney) {
        let valueDefined = false;
        Object.keys(client.powerOfAttorney).forEach(k => {
            if (k === "_id" || k === "id") {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            const val = (client.powerOfAttorney as any)[k];
            if (
                (val !== null &&
                    val !== undefined &&
                    val !== "" &&
                    val !== false &&
                    !Array.isArray(val)) ||
                (Array.isArray(val) && val.length > 0)
            ) {
                valueDefined = true;
            }
        });
        return valueDefined;
    }
    return false;
};

export const LegacyPowerOfAttorney: React.FunctionComponent<Props> = props => {
    if (!shouldShow(props.client)) {
        return null;
    }
    let poaForm = <span />;
    if (
        props.client.hasPowerOfAttorney &&
        !props.client.powerOfAttorneySameAsBestContact
    ) {
        poaForm = (
            <LocaleContext.Consumer>
                {locale => (
                    <div id="client-poa-form">
                        <div className="adjacent-inputs">
                            <AutosavingInput
                                label="Name"
                                initial={props.client.powerOfAttorney.name}
                                onCommit={newVal =>
                                    props.onFieldChange("powerOfAttorney.name", newVal)
                                }
                            />
                            <AutosavingInput
                                label="Relationship"
                                initial={props.client.powerOfAttorney.relationship}
                                onCommit={newVal =>
                                    props.onFieldChange(
                                        "powerOfAttorney.relationship",
                                        newVal,
                                    )
                                }
                            />
                        </div>
                        <div className="adjacent-inputs">
                            <Shared.PhoneInput
                                label="Cell phone number"
                                initial={props.client.powerOfAttorney.cellPhone}
                                onCommit={newVal =>
                                    props.onFieldChange(
                                        "powerOfAttorney.cellPhone",
                                        newVal,
                                    )
                                }
                            />
                            <Shared.PhoneInput
                                label="Phone number (other)"
                                initial={props.client.powerOfAttorney.phone1}
                                onCommit={newVal =>
                                    props.onFieldChange("powerOfAttorney.phone1", newVal)
                                }
                            />
                        </div>
                        <AutosavingInput
                            label="Email"
                            initial={props.client.powerOfAttorney.email1}
                            onCommit={newVal =>
                                props.onFieldChange("powerOfAttorney.email1", newVal)
                            }
                        />
                        <Feature name="emailoptout">
                            <Checkbox
                                checked={props.client.powerOfAttorney.email1OptOut}
                                label="Email Opt-out (for bulk/newsletter emails)"
                                onChange={e =>
                                    props.onFieldChange(
                                        "powerOfAttorney.email1OptOut",
                                        e.target.checked,
                                    )
                                }
                            />
                        </Feature>
                        <AutosavingInput
                            label="Address"
                            initial={props.client.powerOfAttorney.address1}
                            onCommit={newVal =>
                                props.onFieldChange("powerOfAttorney.address1", newVal)
                            }
                        />
                        <div className="adjacent-inputs">
                            <AutosavingInput
                                label="City"
                                initial={props.client.powerOfAttorney.city}
                                onCommit={newVal =>
                                    props.onFieldChange("powerOfAttorney.city", newVal)
                                }
                            />
                            <AutosavingStateInput
                                label={locale.strings.state}
                                initial={props.client.powerOfAttorney.state}
                                onCommit={newVal =>
                                    props.onFieldChange("powerOfAttorney.state", newVal)
                                }
                            />
                            <AutosavingInput
                                label={locale.strings.zip}
                                initial={props.client.powerOfAttorney.zip}
                                onCommit={newVal =>
                                    props.onFieldChange("powerOfAttorney.zip", newVal)
                                }
                            />
                        </div>
                    </div>
                )}
            </LocaleContext.Consumer>
        );
    }

    let poaIsBestContact = <span />;
    if (props.client.hasPowerOfAttorney) {
        poaIsBestContact = (
            <div id="poa-same-as-best-contact" className="flex-column">
                <Checkbox
                    checked={props.client.powerOfAttorneySameAsBestContact}
                    label="Same As Best Contact Above?"
                    onChange={newVal =>
                        props.onFieldChange(
                            "powerOfAttorneySameAsBestContact",
                            newVal.target.checked,
                        )
                    }
                />
            </div>
        );
    }

    return (
        <div className="input-form-block best-contact-person-block">
            <Header iconName="35_PowerOfAttorney">Power of attorney</Header>
            <div className="flex-row">
                <div className="flex-column">
                    <Checkbox
                        label="Has Power Of Attorney?"
                        checked={props.client.hasPowerOfAttorney}
                        onChange={newVal =>
                            props.onFieldChange("hasPowerOfAttorney", newVal.target.value)
                        }
                    />
                </div>
                {poaIsBestContact}
            </div>
            {poaForm}
        </div>
    );
};
